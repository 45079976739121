import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const DocIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        height={size}
        width={size}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.98465 16.87H11.0153C12.635 16.87 13.4713 16.0187 13.4713 14.3915V7.55106C13.4713 6.49637 13.3357 6.01423 12.6802 5.34375L8.74023 1.34347C8.09988 0.695592 7.57254 0.544922 6.62332 0.544922H2.98465C1.37248 0.544922 0.528732 1.39621 0.528732 3.03097V14.3915C0.528732 16.0187 1.37248 16.87 2.98465 16.87ZM3.11272 15.3633C2.3895 15.3633 2.0279 14.9866 2.0279 14.2935V3.12137C2.0279 2.43583 2.3895 2.05162 3.12025 2.05162H6.31445V6.14983C6.31445 7.24219 6.84932 7.76953 7.93415 7.76953H11.9721V14.2935C11.9721 14.9866 11.6105 15.3633 10.8797 15.3633H3.11272ZM8.07728 6.45117C7.76088 6.45117 7.62527 6.31557 7.62527 6.0067V2.24749L11.7687 6.45117H8.07728ZM9.65178 9.7433H4.2126C3.92633 9.7433 3.72293 9.94671 3.72293 10.2104C3.72293 10.4816 3.92633 10.6925 4.2126 10.6925H9.65178C9.92299 10.6925 10.1264 10.4816 10.1264 10.2104C10.1264 9.94671 9.92299 9.7433 9.65178 9.7433ZM9.65178 12.2595H4.2126C3.92633 12.2595 3.72293 12.4704 3.72293 12.7416C3.72293 13.0053 3.92633 13.2087 4.2126 13.2087H9.65178C9.92299 13.2087 10.1264 13.0053 10.1264 12.7416C10.1264 12.4704 9.92299 12.2595 9.65178 12.2595Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default DocIcon;
