import { TableColumn } from '@app/components/ui/table/types/table.type';

export const userTitlesData: TableColumn[] = [
  { index: 'username', title: '.username', tag: 'username' },
  {
    index: 'install_time',
    title: '.editDate',
  },
  {
    index: 'role_id',
    title: '.userRole',
  },
  {
    index: 'status',
    title: '.userStatus',
  },
  {
    index: 'utils',
    title: '',
    tag: 'utils',
    divider: 'left',
    maxWidth: 100,
  },
];
