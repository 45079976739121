import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const TableIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 15">
        <path
          fill="currentColor"
          d="M2.723 14.553H15.27c1.583 0 2.403-.813 2.403-2.373V3.259c0-1.56-.82-2.373-2.402-2.373H2.723C1.141.886.321 1.69.321 3.259v8.92c0 1.56.82 2.374 2.402 2.374zm-.967-11.17c0-.71.374-1.069 1.055-1.069H8.29v2.68H1.756v-1.61zm13.426-1.069c.673 0 1.054.359 1.054 1.07v1.61h-6.54v-2.68h5.486zM1.756 9.067V6.372H8.29v2.695H1.756zm7.94 0V6.372h6.54v2.695h-6.54zm-6.885 4.05c-.681 0-1.055-.351-1.055-1.062v-1.611H8.29v2.673H2.81zm13.425-1.062c0 .71-.38 1.062-1.054 1.062H9.696v-2.673h6.54v1.611z"
        ></path>
      </svg>
    </div>
  );
};

export default TableIcon;
