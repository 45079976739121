import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import userApi from '@app/store/api/user.api';
import { Loading } from '@ui';
import { navigationRouteList } from '@app/utils/routes';
import { useAppSelector } from '@app/store';
import { NotFoundPage } from '@app/pages';
import { PermissionsList } from '@appTypes/user.type';

const RequiredUser = ({
  children,
  allowedPermission,
}: {
  allowedPermission?: PermissionsList;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');
  const user = useAppSelector((state) => state.userState.user);
  const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
    skip: !!user && !!accessToken,
    refetchOnMountOrArgChange: true,
  });
  const userPermissions = useAppSelector((state) => state.userState.user?.permissions);
  const loading = isLoading || isFetching;

  if (loading) {
    return <Loading />;
  }

  if (allowedPermission && userPermissions?.includes(allowedPermission)) return <NotFoundPage />;
  if (!user) navigate(navigationRouteList.login);
  return <>{children}</>;
};

export default RequiredUser;
