import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '@app/store/customFetchBase';
import { endpointUrl } from '@app/utils/endpoint-url';

import { FullOrganizations, Organization, OrganizationRequest } from '@appTypes/organization.type';

const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Organizations'],
  endpoints: (builder) => ({
    /* Получение организаци */
    getOrganization: builder.query<Organization, { organization_id: number }>({
      query(params) {
        return {
          url: endpointUrl.organization,
          params,
        };
      },
    }),
    /* Получение списка организаций */
    getFullOrganizations: builder.query<FullOrganizations, null>({
      query() {
        return {
          url: endpointUrl.fullOrganizations,
        };
      },
      // transformResponse: (baseQueryReturnValue: { organizations: Array<Organization> }) =>
      //   baseQueryReturnValue.organizations,
    }),
    getVendorsOrganizations: builder.query<{ organizations: Array<Organization> }, null>({
      query() {
        return {
          url: endpointUrl.vendorsOrganizations,
        };
      },
    }),
    getOperatorOrganizations: builder.query<Array<Organization>, void>({
      query() {
        return {
          url: endpointUrl.operatorOrganizations,
        };
      },
      transformResponse: (baseQueryReturnValue: { organizations: Array<Organization> }) =>
        baseQueryReturnValue.organizations,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ organization_id }) => ({
                type: 'Organizations' as const,
                organization_id,
              })),
              'Organizations',
            ]
          : ['Organizations'],
    }),
    /* Получение списка организаций, с учётом пагинации */
    getOrganizations: builder.query<
      { total: number; organizations: Array<Organization> },
      { sortDesc?: boolean; limit?: number; offset?: number }
    >({
      query(params) {
        return {
          url: endpointUrl.organizations,
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.organizations.map(({ organization_id }) => ({
                type: 'Organizations' as const,
                organization_id,
              })),
              'Organizations',
            ]
          : ['Organizations'],
    }),
    /* Добавление организации */
    addOrganization: builder.mutation<Organization, OrganizationRequest>({
      query(body) {
        return {
          url: endpointUrl.organization,
          body,
          method: 'POST',
        };
      },
      invalidatesTags: ['Organizations'],
    }),
    /* Редактирование данных организации */
    editOrganization: builder.mutation<
      Organization,
      { params: { organization_id: number }; body: OrganizationRequest }
    >({
      query({ params, body }) {
        return {
          url: endpointUrl.organization,
          body,
          params,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Organizations'],
    }),
    /* Удаление организации */
    removeOrganization: builder.mutation<Organization, { organization_id: number }>({
      query(params) {
        return {
          url: endpointUrl.organization,
          params,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Organizations'],
    }),
  }),
});
export default organizationsApi;
export const {
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useGetFullOrganizationsQuery,
  useAddOrganizationMutation,
  useRemoveOrganizationMutation,
  useEditOrganizationMutation,
  useLazyGetFullOrganizationsQuery,
  useLazyGetOrganizationQuery,
  useLazyGetOrganizationsQuery,
  useGetOperatorOrganizationsQuery,
  useGetVendorsOrganizationsQuery,
} = organizationsApi;
