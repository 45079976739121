import React from 'react';
import { Route } from 'react-router-dom';
import RequiredUser from '@app/hocs/require-user.hoc';
import {
  ListOfVehiclesPage,
  VehicleDataPage,
  VehicleItemOutlet,
  VehicleOutlet,
} from '@app/pages/vehicle-park';
import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs';
import { navigationRouteList } from '@app/utils/routes';
import { VehicleChartPage } from '@app/pages';

export const vehicleParkRoute = (
  <Route
    index
    element={
      <RequiredUser allowedPermission="MenuVisible01">
        <ListOfVehiclesPage />
      </RequiredUser>
    }
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRouteList.main} crumb="vehicles" />,
    }}
  />
);

export const VehicleListRouter = (
  <Route
    path={navigationRouteList.vehicle}
    element={
      <RequiredUser allowedPermission="MenuVisible01">
        <VehicleOutlet />
      </RequiredUser>
    }
  >
    <Route index element={<ListOfVehiclesPage />} />
    <Route path=":vehicleId" element={<VehicleItemOutlet />}>
      <Route index element={<VehicleChartPage />} />
      <Route path="info" element={<VehicleDataPage />} />
    </Route>
  </Route>
);
