import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const ClockIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 14 14"
      >
        <g clipPath="url(#clip0_7219_9642)">
          <path
            fill="currentColor"
            d="M7 14.126c3.862 0 7.062-3.192 7.062-7.062 0-3.862-3.2-7.061-7.069-7.061-3.862 0-7.055 3.2-7.055 7.061 0 3.87 3.2 7.062 7.062 7.062zm0-1.388a5.654 5.654 0 01-5.667-5.674 5.642 5.642 0 015.66-5.667 5.66 5.66 0 015.68 5.667A5.66 5.66 0 017 12.738zM3.541 7.912h3.452c.308 0 .547-.24.547-.54V2.888c0-.301-.24-.54-.547-.54-.3 0-.54.239-.54.54v3.944H3.541a.528.528 0 00-.54.54c0 .3.232.54.54.54z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default ClockIcon;
