import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const WrenchAndScrewdriver: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 22 22"
      >
        <path
          fill="currentColor"
          d="M1.974 20.067c1.564 1.547 3.682 1.538 5.185.018 1.354-1.362 1.74-3.032 3.946-5.942l3.534 3.516.114.774c.053.342.272.65.554.843l2.786 1.872c.316.211.633.211.861-.017l1.679-1.679c.22-.229.202-.554 0-.861l-1.855-2.778a1.27 1.27 0 00-.852-.553l-.765-.115-3.735-3.735a55.94 55.94 0 011.046-1.081c1.89.835 4.14.519 5.633-.984 1.644-1.635 1.899-4.237.616-6.293l-1.002 1.002v.008L18.304 5.46c-.22.228-.492.237-.721 0l-.984-.984c-.247-.247-.255-.519-.036-.739l1.407-1.406 1.01-1.002c-2.047-1.283-4.64-1.037-6.284.606-1.292 1.301-1.714 3.147-1.265 4.843L6.614 1.944c-1.3-1.3-2.698-1.31-3.972-.035l-1.24 1.248C.128 4.423.137 5.82 1.437 7.121l4.914 4.913c-2.004 1.3-3.288 1.75-4.395 2.839-1.52 1.512-1.53 3.621.018 5.194zM15.535 5.24l1.274 1.266c.66.659 1.503.677 2.127.053l1.187-1.196c.15 1.108-.193 2.215-.993 3.015-1.433 1.432-3.806 1.362-5.326-.14-1.512-1.512-1.574-3.903-.132-5.327.79-.791 1.898-1.134 2.997-.993l-1.187 1.178c-.65.65-.624 1.485.053 2.144zm-13.157.8c-.615-.615-.607-1.23-.009-1.837l1.318-1.336c.607-.606 1.23-.598 1.846.018l5.423 5.423c-1.318 1.256-2.452 2.188-3.437 2.909L2.378 6.04zm5.361 1.661c.15.167.422.15.563.009a.406.406 0 00.008-.58L5.111 3.94a.39.39 0 00-.553 0 .393.393 0 000 .562l3.181 3.2zM6.623 8.81a.396.396 0 00.571 0 .39.39 0 000-.554l-3.199-3.2c-.14-.149-.404-.149-.562 0-.159.159-.167.422-.01.572l3.2 3.182zm6.205.395c.158.15.317.299.484.422-5.775 5.941-5.177 7.427-7.181 9.44-.914.922-2.162.957-3.138-.01-.975-.993-.932-2.232-.017-3.146 2.012-2.004 3.489-1.406 9.43-7.18.132.166.273.325.422.474zm3.41 8.833l-.184-.984-4.043-4.043c.14-.176.29-.343.448-.528l4.087 4.087.984.167 1.617 2.145-.773.773-2.136-1.617zm-11.698.527a1.041 1.041 0 10-.008-2.082 1.041 1.041 0 00.008 2.082z"
        ></path>
      </svg>
    </div>
  );
};

export default WrenchAndScrewdriver;
