import { TableColumn } from '@app/components/ui/table/types/table.type';

export const titlesData: TableColumn[] = [
  // { index: 'name', title: '.name' },
  {
    index: 'serial_number',
    title: '.name',
  },
  {
    index: 'install_time',
    title: '.editDate',
  },
  {
    index: 'id_in_operator_db',
    title: '.idInOperatorDb',
  },
  {
    index: 'id_in_vendor_db',
    title: '.idInVendorDb',
  },
  {
    index: 'utils',
    title: '',
    tag: 'utils',
    divider: 'left',
    maxWidth: 100,
  },
];
