import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LogoIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 38 38"
      >
        <path
          fill="currentColor"
          d="M0 7a7 7 0 017-7h24a7 7 0 017 7v24a7 7 0 01-7 7H7a7 7 0 01-7-7V7z"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M21.75 32.5a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zM23 35.25a2.75 2.75 0 01-1.478-5.07l-2.08-6.93h-3.29l-.275 4.643a2.751 2.751 0 11-1.499-.072l.271-4.57h-2.673a1.25 1.25 0 00-1.049.57L9.07 26.687a2.75 2.75 0 11-1.258-.817l1.858-2.867a2.75 2.75 0 012.307-1.254h6.684l-4.9-3.5h-1.893a2.75 2.75 0 01-2.17-1.062L8.033 15.05a2.75 2.75 0 111.184-.921l1.664 2.14c.236.304.6.482.986.482h1.845l-.329-6.57a2.751 2.751 0 111.498-.074l.333 6.644h4.698l2.386-5.09a2.75 2.75 0 111.388.572L21.57 16.75h4.233c.365 0 .712-.16.95-.437l1.958-2.29a2.75 2.75 0 111.14.975l-1.959 2.29a2.75 2.75 0 01-2.09.962h-9.46l4.9 3.5h4.115c.848 0 1.65.392 2.17 1.062l2.442 3.138a2.75 2.75 0 11-1.184.921l-2.442-3.138a1.25 1.25 0 00-.986-.483h-4.348l1.95 6.5H23a2.75 2.75 0 110 5.5zm-8-3.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM5.75 28.5a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zM7 11.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm7-5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm8.75 3.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM31 11.25a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm0 18.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M21.75 32a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zM23 34.75a2.75 2.75 0 01-1.478-5.07l-2.08-6.93h-3.29l-.275 4.643a2.751 2.751 0 11-1.499-.072l.271-4.57h-2.673a1.25 1.25 0 00-1.049.57L9.07 26.187a2.75 2.75 0 11-1.258-.817l1.858-2.867a2.75 2.75 0 012.307-1.254h6.684l-4.9-3.5h-1.893a2.75 2.75 0 01-2.17-1.062L8.033 14.55a2.75 2.75 0 111.184-.921l1.664 2.14c.236.304.6.482.986.482h1.845l-.329-6.57a2.751 2.751 0 111.498-.074l.333 6.644h4.698l2.386-5.09a2.75 2.75 0 111.388.572L21.57 16.25h4.233c.365 0 .712-.16.95-.437l1.958-2.29a2.75 2.75 0 111.14.975l-1.959 2.29a2.75 2.75 0 01-2.09.962h-9.46l4.9 3.5h4.115c.848 0 1.65.392 2.17 1.062l2.442 3.138a2.75 2.75 0 11-1.184.921l-2.442-3.138a1.25 1.25 0 00-.986-.483h-4.348l1.95 6.5H23a2.75 2.75 0 110 5.5zm-8-3.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM5.75 28a1.25 1.25 0 102.5 0 1.25 1.25 0 00-2.5 0zM7 10.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm7-5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM22.75 9a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM31 10.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm0 18.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default LogoIcon;
