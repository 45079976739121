import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FolderConfigIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 23 19">
        <path
          fill="currentColor"
          d="M18.31 9.613h.713a.509.509 0 00.515-.415l.199-.822c.116-.033.249-.083.365-.141l.714.431c.224.133.457.133.656-.058l.49-.498c.174-.19.199-.431.05-.664l-.424-.722c.075-.116.1-.216.15-.332l.813-.2a.508.508 0 00.423-.514v-.705c0-.25-.174-.432-.423-.498l-.805-.2c-.058-.14-.108-.249-.15-.34l.448-.739a.524.524 0 00-.066-.655l-.506-.49c-.183-.183-.432-.224-.64-.092l-.73.457c-.133-.066-.249-.1-.365-.141l-.2-.83a.524.524 0 00-.514-.424h-.714a.526.526 0 00-.515.424l-.19.813c-.142.058-.258.092-.382.158l-.714-.457a.522.522 0 00-.648.092l-.49.49c-.174.182-.223.44-.09.655l.456.74c-.042.09-.091.215-.141.34l-.814.199c-.249.066-.415.257-.415.498v.705c0 .258.166.457.415.515l.822.2.141.331-.423.73c-.133.225-.116.474.058.656l.481.498c.183.191.44.191.656.058l.722-.431c.141.066.258.108.366.141l.19.822a.51.51 0 00.515.415zm-15.133 6.94h13.887c1.486 0 2.357-.864 2.357-2.574v-3.353a4.79 4.79 0 01-1.336 0v3.295c0 .855-.473 1.295-1.295 1.295H3.193c-.83 0-1.286-.44-1.286-1.303V7.255h11.745a4.934 4.934 0 01-.298-1.253H1.907V3.935c0-.822.431-1.245 1.228-1.245h1.752c.548 0 .88.141 1.303.498l.506.423c.54.449.98.606 1.802.606h4.922c.1-.473.216-.871.448-1.336H8.846c-.564 0-.905-.133-1.32-.49l-.506-.415c-.548-.465-.971-.614-1.793-.614H2.861c-1.452 0-2.29.83-2.29 2.498V13.98c0 1.718.87 2.573 2.606 2.573zm15.497-9.845c-.78 0-1.386-.615-1.386-1.387 0-.755.606-1.378 1.386-1.386.747-.017 1.378.63 1.378 1.386 0 .772-.63 1.387-1.378 1.387z"
        ></path>
      </svg>
    </div>
  );
};

export default FolderConfigIcon;
