import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const ChevronDownV2Icon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="currentColor"
          d="M13.002 4.446l-.194-.194a.328.328 0 00-.465 0L7 9.597 1.654 4.252a.328.328 0 00-.465 0l-.194.194a.328.328 0 000 .465l5.77 5.772a.328.328 0 00.465 0L13 4.911a.326.326 0 00.002-.465z"
        ></path>
      </svg>
    </div>
  );
};

export default ChevronDownV2Icon;
