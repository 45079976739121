import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const HomeIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 28 24">
        <path
          fill="currentColor"
          d="M6.362 23.74h15.195c1.595 0 2.527-.91 2.527-2.482V10.466l1.561 1.314c.225.19.483.359.797.359.528 0 .955-.326.955-.865a.918.918 0 00-.348-.763l-2.965-2.494V3.368c0-.494-.314-.809-.809-.809h-1.201c-.483 0-.809.315-.809.809v2.28L15.358.684c-.831-.708-1.864-.708-2.707 0L.95 10.511a.94.94 0 00-.36.73c0 .46.36.898.967.898.314 0 .572-.168.808-.36l1.46-1.235v10.714c0 1.572.932 2.482 2.538 2.482zm10.871-9.332c0-.517-.337-.854-.853-.854h-4.762c-.528 0-.865.337-.865.854v7.524H6.744c-.719 0-1.112-.404-1.112-1.134V9.028l7.974-6.682c.247-.225.55-.225.797 0l7.873 6.604v11.848c0 .73-.393 1.134-1.1 1.134h-3.943v-7.524z"
        ></path>
      </svg>
    </div>
  );
};

export default HomeIcon;
