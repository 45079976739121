import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const ErrorIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 700 700">
        <g fillRule="evenodd">
          <path
            fill="currentColor"
            d="M137.41 103.7c8.59 0 15.555 8.59 15.555 15.555v321.48c0 8.59-6.965 15.555-15.555 15.555s-15.555-8.59-15.555-15.555v-321.48c0-8.59 6.965-15.555 15.555-15.555zM562.59 103.7c8.59 0 15.555 8.59 15.555 15.555v321.48c0 8.59-6.965 15.555-15.555 15.555s-15.555-8.59-15.555-15.555v-321.48c0-8.59 6.965-15.555 15.555-15.555zM241.01 361.1h217.98c8.535 0 15.453 8.535 15.453 15.453 0 8.535-6.918 15.453-15.453 15.453H241.01c-8.535 0-15.453-8.535-15.453-15.453 0-8.535 6.918-15.453 15.453-15.453z"
          ></path>
          <path
            fill="currentColor"
            d="M137.41 425.18h425.18c8.59 0 15.555 8.59 15.555 15.555 0 8.59-6.965 15.555-15.555 15.555H137.41c-8.59 0-15.555-8.59-15.555-15.555 0-8.59 6.965-15.555 15.555-15.555zM137.41 103.91h425.18c8.59 0 15.555 8.59 15.555 15.555 0 8.59-6.965 15.555-15.555 15.555H137.41c-8.59 0-15.555-8.59-15.555-15.555 0-8.59 6.965-15.555 15.555-15.555zM137.41 155.55h425.18c8.59 0 15.555 8.59 15.555 15.555 0 8.59-6.965 15.555-15.555 15.555H137.41c-8.59 0-15.555-8.59-15.555-15.555 0-8.59 6.965-15.555 15.555-15.555zM482.14 315.7c-6.074 6.074-17.074 4.926-22 0l-65.996-65.996c-6.074-6.074-6.074-15.926 0-22s17.074-4.926 22 0L482.14 293.7c6.074 6.074 6.074 15.926 0 22z"
          ></path>
          <path
            fill="currentColor"
            d="M482.14 227.71c6.074 6.074 4.926 17.074 0 22l-65.996 65.996c-6.074 6.074-15.926 6.074-22 0s-4.926-17.074 0-22l65.996-65.996c6.074-6.074 15.926-6.074 22 0zM295.48 315.7c-6.074 6.074-17.074 4.926-22 0l-65.996-65.996c-6.074-6.074-6.074-15.926 0-22s17.074-4.926 22 0L295.48 293.7c6.074 6.074 6.074 15.926 0 22z"
          ></path>
          <path
            fill="currentColor"
            d="M295.48 227.71c6.074 6.074 4.926 17.074 0 22l-65.996 65.996c-6.074 6.074-15.926 6.074-22 0s-4.926-17.074 0-22l65.996-65.996c6.074-6.074 15.926-6.074 22 0z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default ErrorIcon;
