import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const ProfileIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 13 14">
        <path
          fill="currentColor"
          d="M6.507 7.223c1.647 0 2.987-1.456 2.987-3.247C9.494 2.219 8.161.81 6.507.81c-1.648 0-3.001 1.421-2.994 3.178.007 1.784 1.34 3.234 2.994 3.234zm0-1.183c-.95 0-1.75-.882-1.757-2.05 0-1.136.8-1.997 1.757-1.997.957 0 1.75.848 1.75 1.983 0 1.169-.8 2.064-1.75 2.064zM2.24 13.58h8.525c1.175 0 1.743-.37 1.743-1.169 0-1.873-2.338-4.389-6.009-4.389-3.67 0-6.009 2.516-6.009 4.39 0 .799.568 1.168 1.75 1.168zm-.232-1.182c-.157 0-.219-.048-.219-.178 0-1.046 1.689-3.015 4.71-3.015s4.717 1.969 4.717 3.015c0 .13-.069.178-.226.178H2.01z"
        ></path>
      </svg>
    </div>
  );
};

export default ProfileIcon;
