import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const CheckIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 17 17">
        <path
          fill="currentColor"
          d="M6.57 16.577c.492 0 .863-.204 1.132-.603l8.72-13.6c.196-.297.27-.557.27-.808 0-.64-.445-1.085-1.095-1.085-.454 0-.723.158-1.002.594L6.533 13.877 2.377 8.543c-.27-.362-.547-.51-.956-.51C.772 8.033.3 8.497.3 9.137c0 .278.102.547.334.835l4.805 6.011c.325.4.668.594 1.132.594z"
        ></path>
      </svg>
    </div>
  );
};

export default CheckIcon;
