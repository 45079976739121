import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const MenuSmallIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        fill="none"
        viewBox="0 0 22 20"
      >
        <path
          fill="currentColor"
          d="M3.013 6.829l1.652-.971a.56.56 0 000-.955l-1.652-.996c-.44-.257-.896-.091-.896.415v2.1c0 .49.456.664.896.407zm4.98-.59h10.659c.49 0 .88-.39.88-.88s-.39-.88-.88-.88H7.994a.87.87 0 00-.88.88c0 .49.382.88.88.88zm-4.98 5.736l1.652-.971c.357-.216.357-.747 0-.955l-1.652-.996c-.44-.265-.896-.091-.896.407v2.108c0 .49.456.656.896.407zm4.98-.58h10.659a.87.87 0 00.88-.88c0-.49-.39-.88-.88-.88H7.994c-.49 0-.88.39-.88.88 0 .489.382.88.88.88zm-4.98 5.752l1.652-.972c.357-.215.357-.747 0-.954l-1.652-.996c-.44-.266-.896-.1-.896.407v2.108c0 .49.456.656.896.407zm4.98-.598h10.659a.87.87 0 00.88-.88c0-.49-.39-.872-.88-.872H7.994c-.49 0-.88.382-.88.872s.382.88.88.88z"
        ></path>
      </svg>
    </div>
  );
};

export default MenuSmallIcon;
