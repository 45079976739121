import React, { Dispatch, FC, SetStateAction } from 'react';
import cn from 'classnames';

import { Box, Checkbox } from '@ui';
import { FilterPlotData } from '@appTypes/search-data.type';

import GraphLegend from './graph-legend';

type InfoGraphParamsBlockProps = {
  onSelect: Dispatch<SetStateAction<Array<string>>>;
  defaultSelect: Array<string>;
  graphDataInfo: FilterPlotData['items'];
  currentActiveIndex: string;
};
const InfoGraphParamsBlock: FC<InfoGraphParamsBlockProps> = (props) => {
  const { onSelect, graphDataInfo, currentActiveIndex } = props;
  function toggleSelectedGraphSeries(nameOfSeries: string) {
    onSelect((prevState) =>
      prevState.includes(nameOfSeries)
        ? prevState.filter((series) => series !== nameOfSeries)
        : [...prevState, nameOfSeries],
    );
  }

  const renderModelList = (
    <div>
      <div className="flex flex-col first:mt-0 mt-[15px]">
        <div className="flex flex-wrap w-full items-center">
          {graphDataInfo
            ?.filter((field) => field.is_plot)
            .map((field, fieldIndex) => {
              return (
                <div
                  key={fieldIndex}
                  className={cn(field.data.length < 2 ? 'opacity-50' : 'opacity-100', 'basis-1/2')}
                >
                  <label className="flex w-full items-center gap-[5px] cursor-pointer hover:opacity-90">
                    <Checkbox
                      value={1}
                      customColor={field.color}
                      checked={false}
                      disabled={field.data.length < 2}
                      onChange={() => toggleSelectedGraphSeries(String(field.key))}
                    />
                    <div className="h-full flex justify-center items-center">
                      <GraphLegend color={field.color} />
                    </div>
                    <div className="font-bold">
                      {field.data.length > 1
                        ? Number(field.data[Number(currentActiveIndex)]).toFixed(2)
                        : '-'}
                    </div>
                    <span className="mt-[2px]">{field.name || field.key}</span>
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
  return <Box className="p-[30px] text-[14px] font-normal text-1color">{renderModelList}</Box>;
};

export default InfoGraphParamsBlock;
