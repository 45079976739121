import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CircleButton, HelpModal, Icon, Modal, PageHeader, Paginator, Table } from '@ui';

import { SettingsUserForm } from '@app/pages/settings/settings-user/block/settings-user-form/settings-user-form';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { toast } from 'react-toastify';
import { User } from '@appTypes/user.type';
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetUsersQuery,
  useLazyGetUserSettingsQuery,
} from '@app/store/api/user.api';
import { userTitlesData } from '@app/pages/settings/settings-user/data';
import moment from 'moment/moment';
import { useGetOrganizationsQuery } from '@app/store/api/organizations.api';
import { useGetRolesQuery } from '@app/store/api/roles.api';

const SettingsUserPage = () => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLInputElement>(null);
  // useState
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [removableUser, setRemovableUser] = useState<User>();
  const [userPagination, changeUserPagination] = useState({
    sortDesc: false,
    limit: 10,
    offset: 0,
  });
  const [removableModal, changeRemovableModal] = useState(false);
  const [modalOpenState, changeModalState] = useState(false);
  const [editMode, changeEditMode] = useState(false);
  const LOCALE_TABLE_TITLE = 'pages.settingsUser.table.title';
  // api
  const [removeUserTrigger, { isSuccess: isSuccessRemoveUser, isError: isErrorRemoveUser }] =
    useDeleteUserMutation();
  const [getUser] = useLazyGetUserSettingsQuery();
  const [addUser] = useAddUserMutation();
  const [editUser] = useEditUserMutation();
  const { data: usersData, isLoading } = useGetUsersQuery(userPagination);
  const { data: organizations } = useGetOrganizationsQuery({});
  const { data: rolesList } = useGetRolesQuery();
  //data

  const sourceUserData = usersData
    ? usersData.users.map((user) => ({
        ...user,
        utils: (
          <div className="flex w-full items-center gap-[10px]">
            <div>
              <CircleButton
                icon="SettingIcon"
                onClick={() => onUtilClick({ action: 'edit', user })}
              />
            </div>
            <div>
              <CircleButton
                icon="TrashIcon"
                onClick={() => onUtilClick({ action: 'delete', user })}
              />
            </div>
          </div>
        ),
        install_time: moment(user.install_time).format('yyyy.DD.MM hh:mm:ss'),
        role_id: rolesList?.roles.find((item) => item.role_id === user.role_id)?.name || 'N/A',
      }))
    : [];

  //handlers
  function handleSubmitForm(data) {
    editMode
      ? removableUser?.user_id &&
        editUser({ body: data, params: { user_id: removableUser?.user_id } })
      : addUser(data)
          // todo transate toast
          .unwrap()
          .catch(
            (error) => error.status && toast.error('Данный логин уже существует в базе данных'),
          );
    changeModalState((prev) => !prev);
  }
  function onUtilClick({ user, action }: { user: User; action: 'delete' | 'edit' }) {
    setRemovableUser(user);
    if (action === 'delete') {
      changeRemovableModal(true);
    } else {
      changeEditMode(true);
      getUser({ user_id: user.user_id }).then((data) => {
        setCurrentUser(data.data);
        changeModalState(true);
      });
    }
  }
  function changeUserPaginationByClick(paginationData: PaginationResponse) {
    changeUserPagination({ ...paginationData, sortDesc: false });
  }
  function removeUserHandler() {
    if (removableUser) {
      removeUserTrigger({ user_id: removableUser?.user_id });
      if (isSuccessRemoveUser) {
        toast(`${removableUser.username} ${t('pages.settingsUser.delete.delete_successes')}`, {
          type: 'success',
        });
        setRemovableUser(undefined);
      }
      if (isErrorRemoveUser) {
        toast(`${t('pages.settingsUser.delete.delete_error')} ${removableUser.username}`, {
          type: 'error',
        });
      }
    }
  }
  const modalLabel = (
    <div className="flex w-full gap-[20px] flex-col items-center justify-center mb-[25px]">
      <div
        className={
          'w-[57px] h-[57px] rounded-full bg-action/[0.1] flex items-center justify-center'
        }
      >
        <Icon className={'text-action'} size={29} name={'UserIcon'} />
      </div>
      <div className="w-full text-center">
        {t('pages.settingsUser.form.edit_title')}
        <HelpModal helperText={'help'} />
      </div>
    </div>
  );
  return (
    <div>
      <PageHeader icon={'Person'} label={t('pages.settingsUser.pageHeader')}>
        <div>
          <Button
            icon="CirclePlusIcon"
            onClick={() => {
              changeEditMode(false);
              changeModalState(true);
            }}
            label={t('pages.settingsUser.button.addUser')}
          />
        </div>
      </PageHeader>
      <div>
        <Table
          dataSource={sourceUserData}
          columns={userTitlesData.map((item) => ({
            ...item,
            title: item.title && t(LOCALE_TABLE_TITLE + item.title),
          }))}
          isPending={isLoading}
        />
        {!!usersData?.users?.length && (
          <Paginator
            containerClassName={'mt-[20px]'}
            page={userPagination.offset + 1}
            count={usersData.total}
            limit={userPagination.limit}
            onChange={changeUserPaginationByClick}
          />
        )}
        <Modal
          isAutoClosable={false}
          onSuccess={() => submitRef.current?.click()}
          variant={'mediumW650'}
          show={modalOpenState}
          onClose={changeModalState.bind(null, false)}
          label={modalLabel}
        >
          {modalOpenState && (
            <SettingsUserForm
              rolesList={rolesList}
              handleSubmitForm={handleSubmitForm}
              submitRef={submitRef}
              userData={currentUser}
              editMode={editMode}
              organizations={organizations?.organizations}
            />
          )}
        </Modal>
        <Modal
          onRemove={removeUserHandler}
          onClose={changeRemovableModal.bind(null, false)}
          show={removableModal}
          variant="removal"
          withoutFooter
          label={t('pages.settingsUser.delete.delete_user1')}
        >
          {t('pages.settingsUser.delete.delete_user2')} {removableUser?.username}?
        </Modal>
      </div>
    </div>
  );
};

export default SettingsUserPage;
