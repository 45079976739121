import login from './login.json';
import dataSearch from './dataSearch.json';
import settingsUser from './sesttingsUser.json';
import settingsEquipment from './settingsEquipment.json';
import settingsOrganizations from './settingsOrganizations.json';
import vehiclePark from './vehiclePark.json';
import messagesList from './messagesList.json';
import informationSettings from './informationSettings.json';
import settingsPermissions from './settingsPermissions.json';
import settingsVehicle from './settingsVehicle.json';
import vehicleDataPage from './vehicleDataPage.json';
import newPasswordForm from './newPasswordForm.json';
import accessForm from './accessForm.json';
import layout from './layout.json';
import notFound from './notFound.json';

export default {
  dataSearch,
  informationSettings,
  login,
  messagesList,
  settingsUser,
  settingsEquipment,
  settingsOrganizations,
  settingsVehicle,
  vehiclePark,
  settingsPermissions,
  vehicleDataPage,
  newPasswordForm,
  accessForm,
  layout,
  notFound,
};
