import React, { FC } from 'react';

import logo from '@app/assets/images/logo.svg';

const FullScreenLoader: FC<{ text?: string }> = (props) => {
  const { text } = props;
  return (
    <div>
      <div className="absolute inset-0 z-99 w-full inline-flex items-center justify-center bg-white backdrop-blur-sm bg-opacity-20">
        <div className="inline-flex flex-col items-center justify-center animate-bounce">
          <img src={logo} alt="" className="animate-pulse" />
          {text && (
            <div className="text-[12px] mt-[25px] opacity-40 pulse text-speech_analytics">
              {text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
