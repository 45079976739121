import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const MetricIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 27 20"
      >
        <path
          fill="currentColor"
          d="M20.071 18.45h1.968c1.477 0 2.316-.796 2.316-2.199V4.788c0-1.403-.839-2.2-2.316-2.2H20.07c-1.469 0-2.307.797-2.307 2.2V16.25c0 1.403.838 2.2 2.307 2.2zm.3-1.643c-.59 0-.84-.24-.84-.813V5.054c0-.582.25-.823.84-.823h1.385c.59 0 .83.241.83.822v10.94c0 .573-.24.814-.83.814H20.37zm-8.119 1.644h1.967c1.47 0 2.316-.797 2.316-2.2V7.203c0-1.403-.847-2.2-2.316-2.2h-1.967c-1.478 0-2.308.797-2.308 2.208v9.04c0 1.403.83 2.2 2.308 2.2zm.29-1.644c-.589 0-.83-.24-.83-.813V7.469c0-.573.241-.822.83-.822h1.387c.59 0 .838.249.838.822v8.525c0 .572-.249.813-.838.813h-1.386zm-8.118 1.644h1.968c1.477 0 2.316-.797 2.316-2.2V9.635c0-1.403-.839-2.2-2.316-2.2H4.424c-1.469 0-2.307.797-2.307 2.209v6.607c0 1.403.838 2.2 2.307 2.2zm.3-1.644c-.59 0-.84-.24-.84-.813V9.9c0-.581.25-.822.84-.822h1.385c.59 0 .83.24.83.822v6.093c0 .572-.24.813-.838.813H4.723z"
        ></path>
      </svg>
    </div>
  );
};

export default MetricIcon;
