import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { Layout } from '@app/pages';
import { navigationRouteList } from '@app/utils/routes';

import ErrorBoundaryPage from '@app/pages/systems/error-boundary.page';

import NotFoundPage from '../pages/systems/not-found.page';

import { dataRoute } from './data.route';
import { settingsRoute } from './settings.route';
import { VehicleListRouter, vehicleParkRoute } from './vehicle_park.route';
import { authRoute } from './auth.route';
import { emergencyInformationRoute } from './emergency-information.route';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={navigationRouteList.main}
      element={<Layout />}
      errorElement={<ErrorBoundaryPage />}
    >
      {/* VEHICLE PARK */}
      {vehicleParkRoute}
      {/* VEHICLE ROUTE */}
      {VehicleListRouter}
      {/*LOGIN ROUTES */}
      {authRoute}
      {/* EMERGENCY INFO */}
      {emergencyInformationRoute}
      {/* DATA PAGES */}
      {dataRoute}
      {/* SETTINGS */}
      {settingsRoute}
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

export default router;
