import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { CircleButton } from '@ui';
import { APP_NAME } from '@app/utils/constants';

import { ArrowLeftIcon } from '../icons/icons-list';
import { IconNameType } from '../icons/icon-name.type';
import Icon from '../icons';

import HelpText from './help-text';

type PageHeaderProps = {
  icon?: IconNameType;
  label: string | ReactNode;
  /* Название страницы html meta head title */
  pageTitle?: string;
  after?: IconNameType;
  afterAction?: () => void;
  popLink?: string;
  popLinkVoid?: () => void;
  children?: ReactNode;
  helpText?: string;
  className?: string;
};
const PageHeader: FC<PageHeaderProps> = (props) => {
  const {
    pageTitle,
    helpText,
    className,
    icon,
    children,
    popLinkVoid,
    label,
    after,
    popLink,
    afterAction,
  } = props;
  const circleButtonClassName = cn(
    'w-[32px]',
    'h-[32px]',
    'bg-action',
    'text-white',
    'inline-flex',
    'justify-center',
    'transition',
    'hover:opacity-80',
    'items-center',
    'rounded-full',
    'mr-[16px]',
  );

  const renderIconPageHeader = icon && (
    <Icon name={icon} size={26} className="text-action mr-[16px]" />
  );

  const renderAfterAction = after && (
    <div className="ml-[5px] mt-[5px]">
      <CircleButton
        icon={after}
        size={22}
        className="text-action"
        onClick={afterAction?.bind(null)}
      />
    </div>
  );

  const renderBackButton = popLink && (
    <div className="ml-[-10px]">
      <Link to={popLink}>
        <button className={circleButtonClassName}>
          <ArrowLeftIcon size={19} className="text-white" />
        </button>
      </Link>
    </div>
  );
  const renderBackButtonWithVoid = popLinkVoid && (
    <div className="ml-[-10px]">
      <button onClick={popLinkVoid} className={circleButtonClassName}>
        <ArrowLeftIcon size={19} className="text-white" />
      </button>
    </div>
  );

  return (
    <div
      className={cn(
        popLink ? 'pt-[32px] pb-[25px]' : 'pt-[22px] pb-[20px]',
        'inline-flex w-full items-center justify-between pl-[10px]  min-h-[85px]',
        className,
      )}
    >
      <Helmet>
        <title>
          {pageTitle || label} | {APP_NAME}
        </title>
      </Helmet>
      <div className="inline-flex h-full items-center">
        {renderBackButton}
        {renderBackButtonWithVoid}
        {renderIconPageHeader}
        <div className="font-[700] relative text-[24px] text-1color flex items-center z-[1]">
          {label}
          <HelpText text={helpText} />
        </div>
        {renderAfterAction}
      </div>
      <div className="h-full inline-flex items-center">{children}</div>
    </div>
  );
};

export default PageHeader;
