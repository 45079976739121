import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const RecognitionIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 27 15">
        <path
          fill="currentColor"
          d="M8.021 14.626H18.92c1.626 0 2.483-.85 2.483-2.454V3.273c0-1.604-.857-2.46-2.483-2.46H8.02c-1.618 0-2.482.856-2.482 2.46v8.9c0 1.603.864 2.453 2.482 2.453zM9.333 13V2.446h8.283V13H9.333zm-2.44-3.054V5.493a.547.547 0 011.091 0v4.453c0 .3-.248.55-.548.55a.547.547 0 01-.542-.55zm12.07 0V5.493a.547.547 0 011.091 0v4.453c.001.3-.248.55-.548.55a.547.547 0 01-.542-.55zm-17.145.205L3.964 8.4c.44-.367.417-.945 0-1.304L1.818 5.31c-.564-.47-1.245-.184-1.245.542v3.764c0 .71.71.974 1.245.535zm23.364 0c.535.432 1.245.176 1.245-.535V5.852c0-.726-.68-1.004-1.245-.542l-2.197 1.787c-.425.351-.447.944 0 1.303l2.197 1.75z"
        ></path>
      </svg>
    </div>
  );
};

export default RecognitionIcon;
