import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loading, PageHeader, Select } from '@ui';
import { useTranslation } from 'react-i18next';

import { useGetEquipmentModelBulkQuery } from '@app/store/api/models.api';

import { OptionItem } from '@app/components/ui/select/types/select.type';

import {
  useAddInformationMessageSettingsMutation,
  useLazyGetMessageSettingsQuery,
} from '@app/store/api/message.api';

import { useAppSelector } from '@app/store';

import { InfoSettingsEmailsModal } from '@app/pages/emergency-information/information-settings/blocks/info-settings-emails-modal';

import { toast } from 'react-toastify';

import AllowComponentPermission from '@app/hocs/allow-component-permission';

import InformationTableBlock from './blocks/information-table.block';
type CustomFieldsType = {
  field: string;
  name: string;
  minimal_parameter_value?: number | boolean;
  maximal_parameter_value?: number | boolean;
};
export type CustomFormResponseType = {
  output_error: boolean;
  output_warning: boolean;
};
const InformationSettingsPage = () => {
  const { t } = useTranslation();
  const [filteringFieldsFromEquipmentModel, changeFilteringFieldsFromEquipmentModel] = useState<
    CustomFieldsType[]
  >([]);
  const [showEmailsModal, changeShowEmailsModal] = useState(false);
  const [selectedEquipment, changeSelectedEquipment] = useState<number>();
  const [filterParametersValues, changeFilterParametersValues] = useState<{
    [x: string]: (number | boolean)[];
  }>();
  //api
  const { data: equipmentsData } = useGetEquipmentModelBulkQuery();
  const [getMessageSettings, { currentData: currentMessagesSettings, isLoading }] =
    useLazyGetMessageSettingsQuery();
  const [createMessagesSettings] = useAddInformationMessageSettingsMutation();
  const { user } = useAppSelector((state) => state.userState);
  //handlers
  const getOptionsForSelectFromEquipmentModels = useCallback((): OptionItem[] => {
    return (
      equipmentsData?.models.map((equipment) => ({ title: equipment.name, value: equipment.id })) ||
      []
    );
  }, [equipmentsData?.models]);
  const onChangeSelectValue = useCallback(
    (value: number) => {
      changeSelectedEquipment(value);
      const currentEquipmentModal = equipmentsData?.models.find(
        (equipment) => equipment.id === value,
      );
      user &&
        getMessageSettings({
          model_id: value,
          parser: Number(currentEquipmentModal?.parser_identification),
          organization_id: user?.operator_id,
        }).then((data) => {
          const systemArray: CustomFieldsType[] = [];
          const currentTable = currentEquipmentModal?.clickhouse_structure.tables || [];
          for (const clickHouseTable of currentTable) {
            clickHouseTable.fields.map((field) => {
              if (field.is_plot === 'True') {
                systemArray.push({
                  name: field.description || field.name,
                  field: field.name,
                  minimal_parameter_value:
                    data?.data?.setting_restrictions?.[field.name]?.[0] || false,

                  maximal_parameter_value:
                    data?.data?.setting_restrictions?.[field.name]?.[1] || false,
                });
              }
            });
          }
          const uniqSystemArray = systemArray.filter((obj, index) => {
            return index === systemArray.findIndex((o) => obj.field === o.field);
          });
          changeFilteringFieldsFromEquipmentModel(uniqSystemArray);
          changeFilterParametersValues(
            uniqSystemArray.reduce(
              (a, filteredField) => ({
                ...a,
                [filteredField.field]: [
                  filteredField.minimal_parameter_value,
                  filteredField.maximal_parameter_value,
                ],
              }),
              {},
            ),
          );
        });
    },
    [equipmentsData?.models, getMessageSettings, user],
  );

  function handleOnSaveSettings(data: CustomFormResponseType) {
    filterParametersValues &&
      currentMessagesSettings &&
      selectedEquipment &&
      createMessagesSettings({
        body: { ...data, setting_restrictions: filterParametersValues },
        params: {
          model_id: selectedEquipment,
          organization_id: currentMessagesSettings.organization_id,
          parser: currentMessagesSettings.parser,
        },
      })
        .unwrap()
        .then(() => {
          toast.info(t('pages.informationSettings.info_after_save_settings'));
        });
  }
  useEffect(() => {
    if (!getOptionsForSelectFromEquipmentModels().length || selectedEquipment) return;
    onChangeSelectValue(getOptionsForSelectFromEquipmentModels()[0].value as number);
  }, [getOptionsForSelectFromEquipmentModels, onChangeSelectValue, selectedEquipment]);
  if (isLoading) return <Loading />;
  return (
    <div>
      <PageHeader
        label={t('pages.informationSettings.title')}
        helpText={t('pages.informationSettings.help')}
      >
        <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
          <Button
            fill="outlined"
            onClick={() => changeShowEmailsModal(true)}
            label={t('pages.informationSettings.button.edit_subscribers')}
          />
        </AllowComponentPermission>
      </PageHeader>
      <div className="w-full flex justify-between">
        <div></div>
        <div className="inline-flex basis-1/2 items-center justify-between">
          <div className="text-1color text-[13px] flex-1 font-medium w-[287px]">
            {t('pages.informationSettings.select.select_model_subscribe')}
          </div>
          <div className="max-w-[257px] w-full">
            <Select
              defaultValue={selectedEquipment}
              onChange={(value) => onChangeSelectValue(value as number)}
              options={getOptionsForSelectFromEquipmentModels()}
            />
          </div>
        </div>
      </div>
      <div className="mt-[30px]">
        <InformationTableBlock
          filterParametersValues={filterParametersValues}
          changeFilterParametersValues={changeFilterParametersValues}
          currentMessagesSettings={currentMessagesSettings}
          handleOnSaveSettings={handleOnSaveSettings}
          tableData={filteringFieldsFromEquipmentModel}
        />
      </div>
      <InfoSettingsEmailsModal show={showEmailsModal} onClose={changeShowEmailsModal} />
    </div>
  );
};

export default InformationSettingsPage;
