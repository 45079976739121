import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const RefreshIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="currentColor"
          d="M4.66064 2.86523C4.25049 3.23877 4.25781 3.75146 4.55811 4.05908C4.86572 4.38135 5.34912 4.43262 5.78857 4.04443C6.85059 2.9458 8.34473 2.25732 10.0073 2.25732C13.0249 2.25732 15.4639 4.46191 15.8887 7.26709H14.7681C14.1821 7.26709 14.0137 7.72852 14.3652 8.21191L16.1963 10.7681C16.4746 11.1636 16.9214 11.1636 17.207 10.7681L19.0381 8.21924C19.3896 7.72852 19.2358 7.26709 18.6353 7.26709H17.5952C17.1411 3.56104 13.9111 0.580078 10.0073 0.580078C7.90527 0.580078 5.99365 1.47363 4.66064 2.86523ZM0.95459 8.21191C0.603027 8.70264 0.76416 9.16406 1.35742 9.16406H2.41211C2.86621 12.8701 6.09619 15.8584 10.0073 15.8584C12.1094 15.8584 14.021 14.9575 15.354 13.5659C15.7568 13.1851 15.7495 12.665 15.4565 12.3647C15.1489 12.0352 14.6655 11.9985 14.2261 12.3794C13.1714 13.4854 11.6699 14.1738 10.0073 14.1738C6.98242 14.1738 4.54346 11.9692 4.11865 9.16406H5.22461C5.81055 9.16406 5.979 8.70996 5.62744 8.21924L3.80371 5.67041C3.51807 5.2749 3.07129 5.2749 2.78564 5.67041L0.95459 8.21191Z"
        />
      </svg>
    </div>
  );
};

export default RefreshIcon;
