import React, { FC, useEffect, useState } from 'react';
import { Box, Checkbox, Skeleton } from '@ui';
import { UserIcon } from '@icons';
import cn from 'classnames';
import { useGetOrganizationPermissionsQuery } from '@app/store/api/permission.api';
import { useTranslation } from 'react-i18next';
import { SelectedOrganizationPermission } from '@app/pages/settings/settings-permissions/types';

type UserPermissionBlockType = {
  organizationId: number | null;
  changeSelectedPermissionList: React.Dispatch<
    React.SetStateAction<SelectedOrganizationPermission>
  >;
  updatedPermissionSelectedList: SelectedOrganizationPermission;
};

const UserPermissionBlock: FC<UserPermissionBlockType> = (props) => {
  const { organizationId, updatedPermissionSelectedList, changeSelectedPermissionList } = props;
  const { t } = useTranslation();
  const [activeUserToggleId, changeActiveUserToggleId] = useState<string | null>(null);

  const { data: permissionsList, isFetching } = useGetOrganizationPermissionsQuery(
    { organization_id: Number(organizationId) },
    {
      skip: organizationId === null,
    },
  );
  function changeSelectedOrganization(userId: string, organizationId: number) {
    let destructState = updatedPermissionSelectedList;
    if (destructState[userId]?.includes(organizationId)) {
      destructState = {
        ...destructState,
        [userId]: destructState[userId].filter((organization) => organization !== organizationId),
      };
    } else {
      destructState[userId] = Array.isArray(destructState[userId])
        ? [...destructState[userId], organizationId]
        : [organizationId];
    }
    changeSelectedPermissionList({ ...destructState });
  }
  useEffect(() => {
    const selectedOrganizations = permissionsList
      ? permissionsList.users.reduce<SelectedOrganizationPermission>(
          (prevUser, { id: userId, organizations }) => {
            prevUser[userId] = [];
            for (const { id, permission } of organizations) {
              permission && prevUser[userId].push(id);
            }
            return prevUser;
          },
          {},
        )
      : {};
    changeSelectedPermissionList(selectedOrganizations);
  }, [changeSelectedPermissionList, permissionsList]);

  const renderUserPermissionList = !isFetching ? (
    permissionsList &&
    permissionsList.users.map(({ id: userId, username, organizations }) => (
      <li key={userId}>
        <Box className="shadow-medium rounded-[10px] p-[11px]">
          <div
            className="flex items-center cursor-pointer hover:opacity-100 opacity-80 transition"
            onClick={() =>
              changeActiveUserToggleId((prevState) => (prevState === userId ? null : userId))
            }
          >
            <UserIcon size={16} className="text-3color" />
            <div className="mx-[10px]">{username}</div>
            <div className="rounded-full bg-primary p-[3px] text-white text-[10px] flex items-center justify-center">
              {organizations.length}
            </div>
          </div>
          <ul
            className={cn(
              'flex flex-col gap-[5px] ml-[10px] mt-[10px] h-[400px] overflow-y-scroll text-[11px]',
              activeUserToggleId === userId ? 'show' : 'hidden',
            )}
          >
            <h2>{t('pages.settingsPermissions.menu_organizations')}</h2>
            {organizations.map(({ id, organization_name, permission }) => {
              return (
                <li key={id} className="flex gap-[10px]">
                  <Checkbox
                    value={permission.toString()}
                    defaultChecked={permission}
                    checked={Boolean(updatedPermissionSelectedList[userId]?.includes(id))}
                    onChange={() => changeSelectedOrganization(userId, id)}
                  />
                  {organization_name}
                </li>
              );
            })}
          </ul>
        </Box>
      </li>
    ))
  ) : (
    <div className="flex flex-col gap-[11px]">
      <Skeleton count={4} height={40} round="small" />
    </div>
  );
  return (
    <div className={cn(permissionsList ? 'show' : 'hidden', 'flex flex-col gap-[10px]')}>
      {renderUserPermissionList}
    </div>
  );
};

export default UserPermissionBlock;
