import React, { FC, ReactNode } from 'react';
// import { HomeIcon, ChevronRightIcon } from '@icons';
import { Link, useMatches } from 'react-router-dom';
// import { navigationRoutes } from '@app/utils/navigation-routes';
import { Params } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@app/components/ui/icons/icons-list';
import { useTranslation } from 'react-i18next';
import { UseGetMainPageUrl } from '@app/hooks/use-get-main-page-url';
// import useTranslation from '@app/hooks/use-translation';

type MatchHandleProps = { crumb: (data: unknown) => JSX.Element };
type MatchesProps = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: MatchHandleProps;
}[];

export const BreadcrumbsItem: FC<{
  path: string;
  crumb: string | ReactNode;
  unTranslate?: boolean;
  withOutChevron?: boolean;
}> = ({ path, crumb, withOutChevron, unTranslate }) => {
  const breadcrumbsItemClassName =
    'inline-flex items-center h-full text-[14px] font-[500px] text-2color hover:opacity-80 transition';
  const { t } = useTranslation();
  return (
    <li>
      <Link to={path} className={breadcrumbsItemClassName}>
        {!withOutChevron && <ChevronRightIcon size={9} className="mx-[5px]" />}
        <div>
          {unTranslate ? crumb : crumb ? t(`components.breadcrumbs.${crumb.toString()}`) : ''}
        </div>
      </Link>
    </li>
  );
};

const Breadcrumbs = () => {
  const matches = useMatches() as MatchesProps;
  const crumbs = matches
    .filter((match) => {
      const handle = match.handle as MatchHandleProps;
      return Boolean(handle?.crumb);
    })
    .map((match) => match?.handle.crumb(match?.data));
  const renderBreadcrumbsList = crumbs.map((crumb, index) => <div key={index}>{crumb}</div>);
  const mainPage = UseGetMainPageUrl();

  return (
    <ul className="inline-flex ml-[45px]">
      <BreadcrumbsItem
        key={0}
        path={mainPage}
        crumb={<HomeIcon size={16} className="text-3color" />}
        withOutChevron
        unTranslate
      />
      {renderBreadcrumbsList}
    </ul>
  );
};

export default Breadcrumbs;
