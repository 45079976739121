import React, { FC, useCallback, useEffect, useState } from 'react';
import { Input, Select } from '@ui';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Equipment, EquipmentCreateFormResponse } from '@appTypes/equipment.type';
import { VehicleItem } from '@appTypes/vehicle.type';
import { Organization } from '@appTypes/organization.type';
import { useGetVehicleModelQuery } from '@app/store/api/models.api';
import AllowComponentPermission from '@app/hocs/allow-component-permission';
type VehicleEquipmentFormPropsType = {
  handleSubmitForm(data: EquipmentCreateFormResponse): void;
  submitRef: React.RefObject<HTMLInputElement>;
  vehicleList: Array<VehicleItem> | undefined;
  organizationsList: Organization[] | undefined;
  equipmentData: Equipment | undefined;
  editMode: boolean;
};
export const VehicleEquipmentForm: FC<VehicleEquipmentFormPropsType> = (props) => {
  const { submitRef, handleSubmitForm, vehicleList, organizationsList, editMode, equipmentData } =
    props;
  const localFieldErrPath = 'common.fieldRequiredError';
  const localFieldMinLengthErrPath = 'common.fieldMinLengthError';
  const { t } = useTranslation();
  // classNames
  const fieldBlockClassName = cn('flex', 'items-center', 'justify-between');
  const fieldClassName = cn('min-w-[280px]');
  const fieldLabelClassName = cn('text-1color', 'text-[14px]', 'font-[500]', 'leading-[15px]');
  //useState
  const [vehicleModel, changeVehicleModel] = useState<number>();
  //api
  const { data: equipmentModels } = useGetVehicleModelQuery(vehicleModel as number, {
    skip: !vehicleModel,
  });
  const defValue: EquipmentCreateFormResponse | undefined = editMode ? equipmentData : undefined;

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
    register,
    watch,
  } = useForm<EquipmentCreateFormResponse>({ defaultValues: defValue });
  // handlers
  function onSubmit(data) {
    handleSubmitForm(data);
  }
  const changeCurrentVehicleModel = useCallback(
    (id: number) => {
      const vehicleModel = vehicleList?.find((vehicle) => vehicle.vehicle_id === id)?.vehicle_model;
      changeVehicleModel(vehicleModel);
    },
    [vehicleList],
  );
  function getEquipmentModelsOptionsList() {
    return (
      equipmentModels?.equipments.map((equipment) => ({
        title: equipment.type,
        value: equipment.model,
      })) || []
    );
  }
  useEffect(() => {
    if (!editMode) return;
    changeCurrentVehicleModel(watch('vehicle_id'));
  }, [changeCurrentVehicleModel, editMode, watch]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col w-full h-full gap-[20px] pb-[15px]'}>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.vehicle')}
          </span>
          <Controller
            render={() => (
              <div className={fieldClassName}>
                <Select
                  defaultValue={watch('vehicle_id')}
                  onChange={(value) => {
                    setValue('vehicle_id', Number(value));
                    clearErrors('vehicle_id');
                    changeCurrentVehicleModel(Number(value));
                  }}
                  error={errors.vehicle_id?.message}
                  options={
                    vehicleList?.map((item) => ({
                      title: `${item.vehicle_id} (${item.licence_plate})`,
                      value: item.vehicle_id,
                    })) || []
                  }
                />
              </div>
            )}
            name={'vehicle_id'}
            control={control}
            rules={{ validate: (value) => !!value || t(localFieldErrPath) }}
          />
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.equipment_model')}
          </span>
          <Controller
            render={() => (
              <div className={fieldClassName}>
                <Select
                  disable={!watch('vehicle_id')}
                  defaultValue={watch('equipment_model_id')}
                  onChange={(value) => {
                    setValue('equipment_model_id', Number(value));
                    clearErrors('equipment_model_id');
                  }}
                  error={errors.equipment_model_id?.message}
                  options={getEquipmentModelsOptionsList()}
                />
              </div>
            )}
            name={'equipment_model_id'}
            control={control}
            rules={{ validate: (value) => !!value || t(localFieldErrPath) }}
          />
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.serial_number')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('serial_number', {
                required: { value: true, message: t(localFieldErrPath) },
              })}
              error={errors.serial_number?.message}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.description')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('description', {
                required: { value: true, message: t(localFieldErrPath) },
              })}
              error={errors.description?.message}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.id_in_operator_db')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('id_in_operator_db', {
                required: { value: true, message: t(localFieldErrPath) },
              })}
              error={errors.id_in_operator_db?.message}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.id_in_vendor_db')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('id_in_vendor_db', {
                required: { value: true, message: t(localFieldErrPath) },
              })}
              error={errors.id_in_vendor_db?.message}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.vendor')}
          </span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <div className={fieldClassName}>
                  <Select
                    dropTop
                    defaultValue={watch('vendor_id')}
                    onChange={(value) => {
                      setValue('vendor_id', value as number);
                      clearErrors('vendor_id');
                    }}
                    options={
                      organizationsList?.map((item) => ({
                        title: item.name,
                        value: item.organization_id,
                      })) || []
                    }
                    error={errors.vendor_id?.message}
                  />
                </div>
              )}
              name={'vendor_id'}
              control={control}
              rules={{ validate: (value) => !!value || t(localFieldErrPath) }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsEquipment.form.fields.can')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('can_id', {
                required: { value: true, message: t(localFieldErrPath) },
                minLength: { value: 1, message: t(localFieldMinLengthErrPath, { length: 1 }) },
              })}
              maxLength={12}
              error={errors.can_id?.message}
            />
          </div>
        </div>
      </div>
      <AllowComponentPermission permissionKey={'NoChanges07'} type={'disabled'}>
        <input ref={submitRef} type="submit" className={'hidden'} />
      </AllowComponentPermission>
    </form>
  );
};
