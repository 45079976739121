import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const SummSilentDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 58 57">
        <circle cx="29" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          fillRule="evenodd"
          d="M32.188 21.38a3.188 3.188 0 00-5.704-1.956l-3.316 4.264h-4.793a3.187 3.187 0 00-3.188 3.187v5.313a3.187 3.187 0 003.188 3.187h4.83l3.396 3.881a3.187 3.187 0 005.587-2.1V21.382zm2.51 11.577l.033.033a4.783 4.783 0 008.081-3.459 4.783 4.783 0 00-4.78-4.781 4.783 4.783 0 00-4.782 4.781c0 1.343.555 2.557 1.447 3.426zm5.833-4.326a2.655 2.655 0 01-3.4 3.4l3.4-3.4zm-1.46-1.545l-3.485 3.484a2.657 2.657 0 013.484-3.484z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default SummSilentDuration;
