import React from 'react';
import ReactDOM from 'react-dom/client';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import './index.css';
import ru from 'date-fns/locale/ru';
import './i18n';

import App from './App';
registerLocale('ru', ru);
setDefaultLocale('ru');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
