import { createApi } from '@reduxjs/toolkit/query/react';

import { endpointUrl } from '@app/utils/endpoint-url';

import {
  EmailMessage,
  EquipmentMessageSettings,
  MessageHistory,
  MessageHistoryResponse,
  MessageSettingsCRUDResponse,
  MessageSettingsResponse,
  MessageSubscribeAddResponse,
} from '@appTypes/message.type';

import { CommonPagination } from '@appTypes/common-pagination.type';

import customFetchBase from '../customFetchBase';

const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: customFetchBase,
  tagTypes: ['Message', 'Settings', 'SubscribeSettings'],
  endpoints: (builder) => ({
    // Добавление/Редактирование/Удаление списка рассылки информационных сообщений
    addInfoMessageSubscribe: builder.mutation<EmailMessage, MessageSubscribeAddResponse>({
      query(body) {
        return {
          url: endpointUrl.messageSubscribe,
          body,
          method: 'POST',
        };
      },
      invalidatesTags: ['SubscribeSettings'],
    }),
    // Получение списка рассылки информационных сообщений
    getInfoMessageSubscribe: builder.query<EmailMessage, { organization_id: number }>({
      query(params) {
        return {
          url: endpointUrl.messageSubscribe,
          params,
        };
      },
      providesTags: ['SubscribeSettings'],
    }),
    // Добавление/Редактирование/Удаление настроек настроек информационных сообщений
    addInformationMessageSettings: builder.mutation<
      EquipmentMessageSettings,
      MessageSettingsCRUDResponse
    >({
      query({ body, params }) {
        return {
          url: endpointUrl.messageSettings,
          method: 'POST',
          body,
          params,
        };
      },
      invalidatesTags: ['Settings'],
    }),
    // Получение настроек настроек информационных сообщений
    getMessageSettings: builder.query<EquipmentMessageSettings, MessageSettingsResponse>({
      query(params) {
        return {
          url: endpointUrl.messageSettings,
          params,
        };
      },
      providesTags: ['Settings'],
    }),
    getMessages: builder.query<
      MessageHistoryResponse,
      {
        body: Pick<MessageHistory, 'gt' | 'lt'> | undefined;
        params: CommonPagination & { vehicle_id: number };
      }
    >({
      query({ body, params }) {
        return {
          url: endpointUrl.messageHistory,
          body: { gt: body?.gt, lt: body?.lt },
          params,
          method: 'POST',
        };
      },
    }),
  }),
});

export default messageApi;
export const {
  useGetMessagesQuery,
  useGetInfoMessageSubscribeQuery,
  useGetMessageSettingsQuery,
  useAddInformationMessageSettingsMutation,
  useAddInfoMessageSubscribeMutation,
  useLazyGetMessageSettingsQuery,
  useLazyGetInfoMessageSubscribeQuery,
  useLazyGetMessagesQuery,
} = messageApi;
