import cn from 'classnames';

import { OptionsPosition, SelectVariant } from '../types/select.type';

export const selectWrapperClassName = ({
  variant,
  disable,
  error,
}: {
  variant: SelectVariant;
  disable: boolean;
  error: boolean;
}) =>
  cn(
    variant === 'small' && 'h-[26px] text-[12px]',
    variant === 'medium' && 'h-[34px] text-[15px]',
    error ? 'border-basic_red' : 'border-b_dark',
    'rounded-[10px]',
    'border-[1px]',
    'bg-white',
    'font-[400]',
    'text-2color',
    'relative',
    'rounded-[10px]',
    'select-none',
    'w-full',
    'cursor-pointer',
    disable && 'opacity-40 cursor-not-allowed',
  );

export const selectOptionsWrapper = ({
  position,
  dropTop,
}: {
  dropTop: boolean;
  position: OptionsPosition;
}) =>
  cn(
    'bg-white',
    'border-[1px]',
    'border-b_dark',
    dropTop ? 'bottom-[36px]' : 'top-[36px]',
    'w-full',
    // 'max-w-[400px]',
    'z-[99999]',
    'absolute',
    position === 'right' && '-right-1.5 mr-[10px]',
    position === 'left' && '-left-1.5  ml-[10px]',
    'justify-start',
    'rounded-[10px]',
    'inline-flex',
    'flex-col',
    'shadow-medium',
  );

export const optionValueClassName = ({ variant }: { variant: SelectVariant }) =>
  cn(
    variant === 'small' && 'ml-[8px] mr-[13px]',
    variant === 'medium' && 'mx-[13px]',
    'overflow-hidden',
    'flex-1',
    'truncate',
    'text-[15px]',
    'text-2color',
    'font-[400]',
    'leading-[16px]',
  );

export const optionItemClassName = ({
  active,
  disabled,
  nullable = false,
}: {
  nullable: boolean;
  active: boolean;
  disabled: boolean;
}) =>
  cn(
    nullable && 'hidden',
    disabled ? 'cursor-default' : 'cursor-pointer',
    'flex',
    'rounded-[10px]',
    'text-[15px]',
    'px-[20px]',
    'py-[7px]',
    'mb-[4px]',
    'truncate',
    'h-full',
    'w-[calc(100%-14px)]',
    'transition',
    'items-center',
    active && 'bg-pastel_action',
    !active && !disabled && 'hover:bg-b_light',
  );
