import { FilterRangeType, Filter, Range, FilterItem } from '@appTypes/search-data.type';
import moment from 'moment/moment';
import { OptionItem } from '@app/components/ui/select/types/select.type';

export const emptyFilter: FilterItem = {
  name: '',
  value: [''],
  id: '',
  condition: '=',
};
export const defaultFilterForm: Omit<
  Record<FilterRangeType & 'c', Filter & Range>,
  'cd' | 'cm' | 'cw'
> = {
  l: {
    range: {
      type: 'l',
      parameters: [
        {
          value: '',
        },
      ],
    },
    filter: [emptyFilter],
  },
  r: {
    range: {
      type: 'r',
      parameters: [
        {
          value: `${moment(moment().toDate()).format('yyyy-MM-DD')} 00:00`,
          condition: '>',
        },
        {
          value: `${moment(moment().toDate()).format('yyyy-MM-DD')} 00:00`,
          condition: '<',
        },
      ],
    },
    filter: [emptyFilter],
  },
  n: {
    range: {
      type: 'n',
      parameters: [],
    },
    filter: [emptyFilter],
  },
  c: {
    range: {
      type: 'cd',
      parameters: [],
    },
    filter: [emptyFilter],
  },
};
export const conditions = [
  {
    id: '>',
    name: '>',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '<',
    name: '<',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '>=',
    name: '>=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '<=',
    name: '<=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '=',
    name: '=',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
  },
  {
    id: '!=',
    name: '≠',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
  },
  // {
  //   id: 'exists',
  //   name: 'Существует',
  //   values: ['num', 'str'],
  //   type_meta: ['record', 'base'],
  // },
];
export const metaDateTimeCalendar: OptionItem[] = [
  {
    title: 'Текущий день',
    value: 'cd',
  },
  {
    title: 'Текущая неделя',
    value: 'cw',
  },
  {
    title: 'Текущий месяц',
    value: 'cm',
  },
];

export const metaDateTime = [
  {
    name: 'Без указания диапазона дат',
    id: 'n',
  },
  {
    name: 'Дата и время : Диапазон',
    id: 'r',
  },
  {
    name: 'Дата и время : N дней',
    id: 'l',
  },
  {
    name: 'Дата и время : Календарная привязка',
    id: 'c',
  },
];
