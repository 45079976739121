import { OrganizationTypes, PermissionsList } from '@appTypes/user.type';

export const UseGetFormattedPermissions = ({
  permissions,
  organization_type,
}: {
  permissions: PermissionsList[];
  organization_type: OrganizationTypes;
}): PermissionsList[] => {
  const permissionsData: Record<OrganizationTypes, string[]> = {
    operator: ['MenuVisible01', 'VehicleAccesse02'],
    vendor: ['MenuVisible01', 'VehicleAccesse02'],
    vendor_equipment: [''],
  };
  return permissions.filter(
    (permission) => !permissionsData[organization_type].includes(permission),
  );
};
