import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const AlarmIcon: FC<IconType> = (props) => {
  const { className, size } = props;
  console.log(size);
  return (
    <div className={classNames(className)}>
      <svg width={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon" clipPath="url(#clip0_1345_1386)">
          <path
            id="Vector"
            d="M6.75805 4.92832H7.64591C7.73094 4.92832 7.79847 4.99466 7.79597 5.07522L7.60839 9.71921C7.60589 9.79503 7.53836 9.85663 7.45833 9.85663H6.94562C6.86559 9.85663 6.79806 9.7974 6.79556 9.71921L6.60799 5.07522C6.60548 4.99466 6.67301 4.92832 6.75805 4.92832ZM7.20323 10.3305C6.81557 10.3305 6.50294 10.6267 6.50294 10.9939C6.50294 11.3612 6.81557 11.6574 7.20323 11.6574C7.59088 11.6574 7.90351 11.3612 7.90351 10.9939C7.90351 10.6267 7.59088 10.3305 7.20323 10.3305ZM14.2436 11.5626L8.24365 1.70596C7.78346 0.947756 6.62549 0.947756 6.1628 1.70596L0.16287 11.5626C-0.297317 12.3184 0.277916 13.2685 1.20329 13.2685H13.2057C14.126 13.2685 14.7063 12.3208 14.2436 11.5626ZM13.2057 12.5103H1.20079C0.893166 12.5103 0.700588 12.1952 0.85315 11.9417L6.85559 2.08506C7.00815 1.8339 7.3958 1.83154 7.54837 2.08506L13.5508 11.9417C13.7059 12.1928 13.5133 12.5103 13.2057 12.5103Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1345_1386">
            <rect width="14.4058" height="14.4058" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AlarmIcon;
