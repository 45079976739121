import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const TodoIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 16 14">
        <path
          fill="currentColor"
          d="M3.519 6.554c1.523 0 2.793-1.27 2.793-2.793 0-1.536-1.27-2.806-2.793-2.806-1.537 0-2.8 1.27-2.8 2.806 0 1.523 1.27 2.793 2.8 2.793zm-.337-1.295c-.14 0-.241-.07-.336-.184l-.711-.85a.373.373 0 01-.095-.267c0-.197.152-.35.342-.35.127 0 .216.051.299.165l.488.61L4.3 2.567c.083-.127.19-.19.318-.19.19 0 .349.146.349.324a.451.451 0 01-.083.266L3.531 5.068a.412.412 0 01-.349.19zm5.142-.94h6.386a.56.56 0 00.564-.558.56.56 0 00-.564-.559H8.323a.55.55 0 00-.553.559.55.55 0 00.553.558zm-4.805 9.573c1.523 0 2.793-1.27 2.793-2.8 0-1.536-1.27-2.799-2.793-2.799-1.537 0-2.8 1.27-2.8 2.8 0 1.53 1.27 2.799 2.8 2.799zm0-1.08c-.94 0-1.72-.787-1.72-1.72 0-.933.786-1.726 1.72-1.726.926 0 1.72.793 1.72 1.726 0 .933-.794 1.72-1.72 1.72zm4.805-1.148h6.386a.56.56 0 00.564-.559.56.56 0 00-.564-.559H8.323a.55.55 0 00-.553.559.55.55 0 00.553.559z"
        ></path>
      </svg>
    </div>
  );
};

export default TodoIcon;
