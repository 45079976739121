import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '@app/store/customFetchBase';
import { endpointUrl } from '@app/utils/endpoint-url';

import {
  FilterCheckResponse,
  FilterCreateResponse,
  FilterCsvData,
  FilterDataCreateResponse,
  FilterID,
  FilterPlotCreateResponse,
  FilterPlotData,
} from '@appTypes/search-data.type';

const searchDataApi = createApi({
  reducerPath: 'searchData',
  baseQuery: customFetchBase,
  tagTypes: ['SEARCH_DATA', 'PLOT_DATA', 'CSV_DATA'],
  endpoints: (builder) => ({
    resetFilter: builder.query<{ filter_id: string }, void>({
      query: () => ({
        url: endpointUrl.searchDataReset,
      }),
    }),
    downloadFile: builder.query<string, { file: string }>({
      query: (params) => ({
        url: endpointUrl.searchFile,
        params,
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
    createFilterWithEquipment: builder.mutation<FilterID, FilterPlotCreateResponse>({
      query: (body) => ({
        url: endpointUrl.searchData,
        method: 'POST',
        body,
      }),
    }),
    createFilter: builder.mutation<FilterID, FilterCreateResponse>({
      query: (body) => ({
        url: endpointUrl.searchData,
        method: 'POST',
        body,
      }),
    }),
    filterCheck: builder.query<FilterCheckResponse, null>({
      query: () => ({
        url: endpointUrl.searchDataCheck,
      }),
    }),
    filterCreateData: builder.mutation<FilterID, FilterDataCreateResponse>({
      query: (params) => ({
        url: endpointUrl.searchDataCsv,
        method: 'POST',
        params,
      }),
    }),
    filterPlotData: builder.query<FilterPlotData, null>({
      query: () => ({
        url: endpointUrl.searchDataPlot,
      }),
    }),
    filterCsvData: builder.query<FilterCsvData, null>({
      query: () => ({
        url: endpointUrl.searchDataCsv,
      }),
    }),
  }),
});

export default searchDataApi;
export const {
  useCreateFilterMutation,
  useFilterCreateDataMutation,
  useFilterCsvDataQuery,
  useLazyFilterCsvDataQuery,
  useFilterPlotDataQuery,
  useLazyFilterPlotDataQuery,
  useFilterCheckQuery,
  useLazyFilterCheckQuery,
  useCreateFilterWithEquipmentMutation,
  useLazyDownloadFileQuery,
  useLazyResetFilterQuery,
} = searchDataApi;
