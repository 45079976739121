import React, { FC } from 'react';
import classnames from 'classnames';
import { UserIcon } from '@app/components/ui/icons/icons-list';

type AvatarSizes = 16 | 18 | 20 | 24 | 28 | 30;
type AvatarProps = {
  url?: string | null;
  alt?: string;
  size?: AvatarSizes;
  classname?: string;
};

const Avatar: FC<AvatarProps> = (props) => {
  const { size = 20, url, alt, classname } = props;
  const avatarClassName = classnames(
    'inline-flex',
    'overflow-hidden',
    'relative',
    'justify-center',
    'items-center',
    'bg-action',
    'rounded-full',
  );
  const avatarSizeStyle = { width: `${size}px`, height: `${size}px` };

  const renderImage = url ? (
    <img src={url} alt={alt} className="w-full h-full rounded-full" style={avatarSizeStyle} />
  ) : (
    <UserIcon className="text-white" size={size / 2} />
  );

  return (
    <div className={classnames(avatarClassName, classname)} style={avatarSizeStyle}>
      {renderImage}
    </div>
  );
};

export default Avatar;
