import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Organization,
  OrganizationRequest,
  VendorsTypes,
} from '@app/store/interfaces/organization.type';
import moment from 'moment';
type OrganizationFormPropsType = {
  editMode?: boolean;
  handleSubmitForm(data: OrganizationRequest): void;
  formRef: React.RefObject<HTMLInputElement>;
  singleOrganizationData: Organization | undefined;
};
export const OrganizationForm: FC<OrganizationFormPropsType> = (props) => {
  const { editMode = false, formRef, handleSubmitForm, singleOrganizationData } = props;
  const { t } = useTranslation();
  const localFieldErrPath = 'common.fieldRequiredError';
  const defValue = editMode ? singleOrganizationData : undefined;

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<OrganizationRequest>({
    defaultValues: defValue,
  });
  const labelClassName = cn(
    'font-[500]',
    'text-[14px]',
    'leading-[110%]',
    'tracking-tight',
    'text-1color',
  );
  function onSubmit(data: OrganizationRequest) {
    handleSubmitForm(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col items-center gap-[20px]'}>
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.name')}
          </span>
          <div className={'w-[280px]'}>
            <Input
              {...register('name', {
                required: {
                  value: true,
                  message: t(localFieldErrPath),
                },
                maxLength: { value: 64, message: t('common.fieldMaxLengthError', { length: 64 }) },
              })}
              error={errors.name?.message}
            />
          </div>
        </div>
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.contact_person')}
          </span>
          <div className={'w-[280px]'}>
            <Input
              {...register('contact_full_name', {
                required: {
                  value: true,
                  message: t(localFieldErrPath),
                },
                maxLength: {
                  value: 128,
                  message: t('common.fieldMaxLengthError', { length: 128 }),
                },
              })}
              error={errors.contact_full_name?.message}
            />
          </div>
        </div>
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.email')}
          </span>
          <div className={'w-[280px]'}>
            <Input {...register('email')} />
          </div>
        </div>
        {editMode && (
          <div className={'flex items-center w-full justify-between'}>
            <span className={labelClassName}>
              {t('pages.settingsOrganizations.form.fields.install_time')}
            </span>
            <div className={'w-[280px]'}>
              <span>
                {moment(singleOrganizationData?.install_time).format('yyyy.MM.DD HH:MM:SS')}
              </span>
            </div>
          </div>
        )}
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.phone')}
          </span>
          <div className={'w-[280px]'}>
            <Controller
              render={({ field: { value } }) => (
                <Input
                  {...register('phone_number')}
                  defaultValue={value}
                  mask="+7(999)999-99-99"
                  error={errors.phone_number?.message}
                />
              )}
              name={'phone_number'}
              control={control}
              rules={{
                validate: (value) =>
                  value?.length != 0
                    ? (value?.replace(/[_\-()+]/gm, '').length as number) >= 11 ||
                      t('common.fieldPhoneError')
                    : true,
              }}
            />
          </div>
        </div>
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.vendor')}
          </span>
          <div className={'w-[280px]'}>
            <Select
              dropTop
              defaultValue={watch('vendor')}
              options={[
                { value: 'vendor', title: t('common.vendors.vendor') },
                { value: 'vendor_equipment', title: t('common.vendors.vendor_equipment') },
                { value: 'operator', title: t('common.vendors.operator') },
              ]}
              onChange={(value) => setValue('vendor', value as VendorsTypes)}
            />
          </div>
        </div>
        <div className={'flex items-center w-full justify-between'}>
          <span className={labelClassName}>
            {t('pages.settingsOrganizations.form.fields.status')}
          </span>
          <div className={'w-[280px]'}>
            <Controller
              render={() => (
                <Select
                  dropTop
                  defaultValue={watch('status')}
                  options={[
                    {
                      value: 'active',
                      title: t('pages.settingsOrganizations.form.fields.active'),
                      className: 'text-basic_green',
                    },
                    {
                      value: 'disabled',
                      title: t('pages.settingsOrganizations.form.fields.not_active'),
                      className: 'text-basic_red',
                    },
                  ]}
                  onChange={(value) => {
                    setValue('status', value as string);
                    clearErrors('status');
                  }}
                  error={errors.status?.message}
                />
              )}
              name={'status'}
              control={control}
              rules={{
                validate: (value) => !!value || t(localFieldErrPath),
              }}
            />
          </div>
        </div>
      </div>
      <input type="submit" className={'hidden'} ref={formRef} />
    </form>
  );
};
