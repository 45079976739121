import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const Minusicon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 17"
      >
        <path
          fill="currentColor"
          d="M1.937 3.82V3.08c0-.663.348-.998.984-.998h.882V.75h-.964C1.362.75.597 1.502.597 2.965v.854h1.34zM7.89.75H4.8v1.333h3.09V.75zM.597 8.898v1.033c0 1.463.765 2.221 2.242 2.221H4v1.033c0 1.456.759 2.214 2.242 2.214h3.541v-1.34H6.318c-.635 0-.977-.328-.977-.99v-6.74c0-.664.342-.992.977-.992h6.761c.629 0 .984.328.984.991v3.8l1.34 1.348V6.212c0-1.456-.765-2.215-2.242-2.215H12V2.965c0-1.456-.766-2.215-2.242-2.215h-.868v1.333h.793c.628 0 .984.335.984.998v.916H6.243c-1.483 0-2.242.759-2.242 2.215v4.6h-1.08c-.636 0-.984-.328-.984-.99v-.924H.597zm1.34-.998V4.817H.597V7.9h1.34zm8.565 6.262c-.007.3.335.397.533.205l.978-.998 1.114 2.762c.061.15.212.24.383.178l.677-.274c.164-.068.198-.246.136-.39l-1.182-2.72 1.408-.041c.287-.007.43-.294.219-.513l-3.706-3.787c-.198-.198-.485-.089-.492.191l-.068 5.387z"
        ></path>
      </svg>
    </div>
  );
};

export default Minusicon;
