import { toast } from 'react-toastify';
import { createApi } from '@reduxjs/toolkit/query/react';

import customFetchBase from '@app/store/customFetchBase';
import {
  AuthLoginRequest,
  AuthLoginResponse,
  ChangePasswordResponse,
  ForgetPasswordRequest,
} from '@app/store/interfaces/auth.type';
import { endpointUrl } from '@app/utils/endpoint-url';
import authStorage from '@app/utils/auth';
import { setUserError } from '@app/store/features/user.slice';

import userApi from './user.api';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    // Запрос на смену пароля
    changePassword: builder.mutation<null, ChangePasswordResponse>({
      query(params) {
        return {
          url: endpointUrl.updatePassword,
          method: 'POST',
          params,
          headers: {},
        };
      },
    }),
    // Запрос на валидацию токена смены пароля
    tokenValidate: builder.mutation<{ status: number }, { token: string }>({
      query(params) {
        return {
          url: endpointUrl.validateToken,
          method: 'POST',
          params,
          headers: {},
        };
      },
    }),
    // Запрос на отправку письма для смены пароля
    forgetPassword: builder.mutation<{ status: number }, ForgetPasswordRequest>({
      query: (params) => ({
        url: endpointUrl.forgetPassword,
        method: 'POST',
        params,
      }),
    }),
    loginUser: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query(formDataFields) {
        const formData = new FormData();
        Object.entries(formDataFields).forEach(([field, value]) => {
          formData.set(field, value);
        });
        return {
          url: endpointUrl.authToken,
          body: formData,
          method: 'POST',
          formData: true,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log('Received data:', data);
          if (data.detail === 'OK') {
            await authStorage.signIn({ access: data.access_token, refresh: data.refresh_token });
            await dispatch(userApi.endpoints.getMe.initiate(null));
          } else {
            console.log('test');
            await authStorage.logOut();
            await dispatch(setUserError(data.detail));
            toast(data.detail, { type: 'error' });
          }
        } catch (errorData) {
          console.log('Error received:', errorData);
          console.log(errorData.error, 'error data');
          await dispatch(setUserError(errorData.error.data.detail));
          await authStorage.logOut();
        }
      },
    }),
  }),
});
export default authApi;
export const {
  useLoginUserMutation,
  useForgetPasswordMutation,
  useTokenValidateMutation,
  useChangePasswordMutation,
} = authApi;
