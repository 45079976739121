import React from 'react';

import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import router from '@app/routes';
import store from '@app/store';
import AuthProvider from '@app/hocs/auth-provider';

function App() {
  // TODO change language handler
  // const { i18n } = useTranslation();
  //
  // const changeLanguage = (lng) => {
  //     i18n.changeLanguage(lng);
  // };
  return (
    <Provider store={store}>
      <AuthProvider>
        <RouterProvider router={router} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          limit={5}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </AuthProvider>
    </Provider>
  );
}

export default App;
