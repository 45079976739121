import cn from 'classnames';

export const collapsedButtonClassName = (isCollapsed: boolean) =>
  cn(isCollapsed && ' ml-[24px] mb-[21px]');

export const collapseBarClassName = cn(
  'w-[22px]',
  'cursor-pointer',
  'hover:opacity-80',
  'h-[22px]',
  'mb-[9px]',
  'mr-[9px]',
  'rounded-[4px]',
  'bg-bg_4',
  'inline-flex',
  'items-center',
  'justify-center',
);

export const menuBarClassName = ({ isCollapsed }: { isCollapsed: boolean }) =>
  cn(
    isCollapsed ? 'w-[64px]' : 'w-[343px]',
    'h-[calc(100vh-67px)]',
    'bg-white',
    'ease-in-out',
    'z-30',
    'duration-75',
    'overflow-y-auto',
  );

export const menuBarItemClassName = ({
  isActive,
  isCollapsed,
}: {
  isActive: boolean;
  isCollapsed: boolean;
}) =>
  cn(
    'py-2',
    'blocks',
    'my-1',
    'px-[px]',
    'ease-in-out',
    'group',
    'inline-flex',
    'items-center',
    'w-full',
    isCollapsed
      ? cn(
          isActive ? 'bg-basic_app_bg isActiveBar' : 'isNoActiveBar',
          'mx-[9px]',
          '!w-[44px]',
          'h-[44px]',
          'mb-[12px]',
          'justify-center',
          'rounded-[10px]',
        )
      : 'px-[40px] isNoActiveBar',
    isActive ? 'bg-action/[0.05] !text-action' : 'hover:bg-action/[0.05] !text-0color',
  );

export const menuBarItemTextClassName = cn(
  'ease-in-out',
  'ml-[18px]',
  'group-hover:text-primary',
  'text-[17px]',
  'font-[500]',
  'leading-[110%]',
  'tracking-tight',
);
