import React, { FC, useEffect, useRef } from 'react';
import { FilterPlotData } from '@appTypes/search-data.type';
import ApexCharts from 'apexcharts';
import { Box } from '@ui';

type ChartBlockType = {
  chartData: FilterPlotData;
  height?: number;
  currentShowSeries: Array<string>;
  onChangeCurrentIndex: (index: string) => void;
  currentValueIndex: string;
};
const DEFAULT_CHART_HEIGHT = 300;
const ChartBlock: FC<ChartBlockType> = (props) => {
  const { chartData, onChangeCurrentIndex, currentValueIndex, currentShowSeries } = props;
  const apexChartRef = useRef<ApexCharts | null>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  const plotColors = chartData?.items
    .filter((series) => series.data.length > 0 && series.is_plot)
    .map((chartInfo) => chartInfo.color);
  console.log('xasis', chartData.xaxis, [...chartData.xaxis].sort());
  useEffect(() => {
    const updateSeries: FilterPlotData['items'] = [];
    apexChartRef.current &&
      chartData?.items?.forEach((chartSeries) => {
        if (currentShowSeries?.includes(chartSeries.key)) {
          updateSeries.push({
            ...chartSeries,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: chartSeries.data.map((series) => parseFloat(series.toString()).toFixed(2)),
          });
        }
      });
    apexChartRef.current?.updateSeries(updateSeries as unknown as ApexAxisChartSeries);
  }, [chartData, currentShowSeries]);
  useEffect(() => {
    if (chartRef.current && chartData && apexChartRef.current === null) {
      apexChartRef.current = new ApexCharts(chartRef.current, {
        chart: {
          type: 'line',
          colors: plotColors,
          height: DEFAULT_CHART_HEIGHT,
          events: {
            mouseMove: function (_: never, __: never, config: { dataPointIndex: string | number }) {
              if (currentValueIndex !== config.dataPointIndex && config.dataPointIndex > -1) {
                onChangeCurrentIndex(String(config.dataPointIndex));
              }
            },
          },
        },
        series: [],
        xaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [...chartData.xaxis].sort(),
        },
        tooltip: {
          shared: true,
          x: {
            show: true,
          },
          items: {
            display: 'flex',
          },
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        legend: {
          show: false,
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
      });
      if (apexChartRef?.current) {
        apexChartRef.current?.render();
      }
    }
  }, [chartData, currentValueIndex, onChangeCurrentIndex, plotColors]);

  return (
    <div>
      <Box className="rounded-t-[0px]  py-[10px] ">
        <div ref={chartRef} className="grid gap-1 grid-cols-3" />
      </Box>
    </div>
  );
};

export default ChartBlock;
