const theme = {
  '0color': '#1A2128',
  '1color': '#2E3842',
  '2color': '#3D4858',
  '3color': '#5D697E',
  '4color': '#A6ABBA',
  action: '#F04C23',
  basic_app_bg: '#F5F5F7',
  bg_1: '#FBFCFE',
  bg_3: '#F0F1F2',
  bg_4: '#E3E6ED',
  bg5_modal: 'rgba(46, 56, 66, 0.5)',
  b_light: '#F1F2F3',
  b_medium: '#E5E8EB',
  b_dark: '#DCE0E5',
  basic_green: '#3BA86D',
  basic_red: '#E92E5D',
  basic_orange: '#FFA12E',
  transparent: 'transparent',
  white: 'white',
  gray: '#F1F1F1',
  pastel_action: '#E6F1FF',
  black: '#55565A',
  dividerColor: '#E0E0E0',
  orange_pal: '#FFEDD6',
  orange_border: '#FFDCA8',
  red_pal: '#FDD8D8',
  red_border: '#FCBFBF',
  red_donut: '#FC7070',
  green_donut: '#43FC25',
  gray_donut: '#F5F5F7',
  burgundy_donut: '#B53232',
};

export default theme;
