import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FolderIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 22 17">
        <path
          fill="currentColor"
          d="M3.81 16.767h14.582c1.696 0 2.68-.985 2.68-2.866V4.857c0-1.88-.993-2.865-2.891-2.865h-8.148c-.633 0-1.01-.14-1.503-.545l-.492-.404C7.414.533 6.957.366 6.034.366H3.547C1.895.366.919 1.333.919 3.188V13.9c0 1.89.993 2.866 2.892 2.866zM2.669 3.337c0-.8.448-1.222 1.23-1.222h1.67c.624 0 .994.15 1.495.554l.492.413c.615.492 1.09.668 2.012.668h8.5c.808 0 1.256.422 1.256 1.266v.518H2.668V3.337zm1.257 11.68c-.809 0-1.257-.421-1.257-1.274v-6.67h16.655v6.679c0 .844-.448 1.266-1.257 1.266H3.925z"
        ></path>
      </svg>
    </div>
  );
};

export default FolderIcon;
