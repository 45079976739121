import React, { FC } from 'react';

import { IconType } from '../icon.type';

const GridIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 16">
        <rect width="3" height="3.086" y="0.286" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" y="6.457" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" y="12.628" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="6" y="0.286" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="6" y="6.457" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="6" y="12.628" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="12" y="0.286" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="12" y="6.457" fill="currentColor" rx="1"></rect>
        <rect width="3" height="3.086" x="12" y="12.628" fill="currentColor" rx="1"></rect>
      </svg>
    </div>
  );
};

export default GridIcon;
