import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const DownloadArrowIcon: FC<IconType> = (props) => {
  const { size, className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg width={size} viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M21.7812 14.5L12.5312 23.7812C12.2375 24.075 11.7625 24.075 11.4688 23.7812L2.21875 14.5C1.925 14.2063 1.925 13.7312 2.21875 13.4375L2.6625 12.9937C2.95625 12.7 3.43125 12.7 3.725 12.9937L10.9375 20.2437V0.75C10.9375 0.3375 11.275 0 11.6875 0H12.3125C12.725 0 13.0625 0.3375 13.0625 0.75V20.2437L20.275 13C20.5688 12.7063 21.0437 12.7063 21.3375 13L21.7812 13.4438C22.075 13.7313 22.075 14.2063 21.7812 14.5ZM24 27.25V26.75C24 26.3375 23.6625 26 23.25 26H0.75C0.3375 26 0 26.3375 0 26.75V27.25C0 27.6625 0.3375 28 0.75 28H23.25C23.6625 28 24 27.6625 24 27.25Z"
        />
      </svg>
    </div>
  );
};

export default DownloadArrowIcon;
