import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const MagnifierTextSearchIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="currentColor"
          d="M7.532 14.033a6.873 6.873 0 003.797-1.142l3.85 3.858c.255.246.58.37.931.37.73 0 1.266-.572 1.266-1.293 0-.334-.114-.659-.36-.905l-3.824-3.84a6.86 6.86 0 001.257-3.965C14.45 3.311 11.338.2 7.532.2 3.735.2.615 3.311.615 7.116c0 3.806 3.112 6.917 6.917 6.917zm0-1.845c-2.786 0-5.071-2.286-5.071-5.072s2.285-5.071 5.071-5.071c2.786 0 5.072 2.285 5.072 5.071 0 2.786-2.286 5.072-5.072 5.072zM4.94 6.606h5.23c.36 0 .641-.298.641-.65a.634.634 0 00-.641-.642h-5.23a.621.621 0 00-.632.642c0 .352.272.65.632.65zm0 2.312H8.71c.352 0 .642-.29.642-.642 0-.36-.282-.65-.642-.65H4.94c-.37 0-.633.29-.633.65 0 .352.272.642.632.642z"
        ></path>
      </svg>
    </div>
  );
};

export default MagnifierTextSearchIcon;
