import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const CircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width={size}
        height={size}
        enableBackground="new 0 0 451.827 451.827"
        version="1.1"
        viewBox="0 0 451.827 451.827"
        xmlSpace="preserve"
      >
        <path
          fill="currentColor"
          d="M225.922 0C101.351 0 .004 101.347.004 225.917s101.347 225.909 225.917 225.909c124.554 0 225.901-101.347 225.901-225.909C451.823 101.347 350.476 0 225.922 0z"
        ></path>
      </svg>
    </div>
  );
};

export default CircleIcon;
