import { useAppSelector } from '@app/store';
import { navigationRouteList } from '@app/utils/routes';

export const UseGetMainPageUrl = (): string => {
  const userPermissions = useAppSelector((state) => state.userState.user?.permissions);
  if (userPermissions && userPermissions.includes('MenuVisible01'))
    return navigationRouteList.dataSearch;

  return navigationRouteList.main;
};
