import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const Minusicon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height="3"
        fill="none"
        viewBox="0 0 13 3"
      >
        <path
          fill="currentColor"
          d="M1.241 2.664h10.51c.498 0 .93-.425.93-.945 0-.512-.431-.945-.93-.945H1.242c-.483 0-.93.433-.93.945 0 .52.447.945.93.945z"
        ></path>
      </svg>
    </div>
  );
};

export default Minusicon;
