import { createApi } from '@reduxjs/toolkit/query/react';

import { endpointUrl } from '@app/utils/endpoint-url';

import {
  EquipmentModel,
  EquipmentModelBulk,
  ModelsRequestType,
  VehicleModel,
  VehicleModelsList,
} from '@appTypes/models.type';

import customFetchBase from '../customFetchBase';

const modelsApi = createApi({
  reducerPath: 'modelsApi',
  baseQuery: customFetchBase,
  tagTypes: ['models', 'equipment_model'],
  endpoints: (builder) => ({
    // Получение списка моделей ТС
    getVehicleModels: builder.query<VehicleModelsList, void>({
      query() {
        return {
          url: endpointUrl.vehicleModels,
        };
      },
    }),
    // Получение модели ТС
    getVehicleModel: builder.query<VehicleModel, number>({
      query(model_id) {
        return {
          url: endpointUrl.vehicleModel,
          params: { model_id: model_id },
        };
      },
    }),
    // Получение модели оборудования
    getEquipmentModel: builder.query<EquipmentModel, ModelsRequestType>({
      query(params) {
        return {
          url: endpointUrl.vehicleEquipmentModel,
          params,
        };
      },
    }),
    // Получение списка моделей оборудования
    getEquipmentModelBulk: builder.query<EquipmentModelBulk, void>({
      query: () => endpointUrl.vehicleEquipmentModelBulk,
      providesTags: ['equipment_model'],
    }),
  }),
});

export default modelsApi;
export const {
  useGetVehicleModelsQuery,
  useGetEquipmentModelQuery,
  useGetVehicleModelQuery,
  useGetEquipmentModelBulkQuery,
} = modelsApi;
