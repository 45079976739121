import React, { FC, ReactNode } from 'react';

type LayoutContainerProps = {
  children: ReactNode;
};

const LayoutContainerBlock: FC<LayoutContainerProps> = (props) => {
  const { children } = props;
  return (
    <div className="w-full overflow-hidden overflow-y-auto inline-flex justify-center">
      <div className="h-[calc(100vh-107px)] w-full max-w-[1900px] px-[42px]">{children}</div>
    </div>
  );
};

export default LayoutContainerBlock;
