import React, { FC, Fragment, useEffect } from 'react';
import { Input } from '@ui';
import { FieldValues, useFieldArray, UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nestedFieldsBlockClassName } from '@app/pages/datas/blocks/find-data-form/styles';

type NestedFieldsProps = {
  defaultValue: string;
  nestIndex: number;
  appendFilter: UseFieldArrayAppend<FieldValues, 'filter'>;
  fieldsLength: number;
  onChangeIterFields: () => void;
};
const NestedField: FC<NestedFieldsProps> = ({
  nestIndex,
  appendFilter,
  fieldsLength,
  onChangeIterFields,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `filter[${nestIndex}].value`,
  });
  useEffect(() => {
    fields.length === 0 && append('');
  }, [append, fields]);
  function onInputChange(event, index) {
    const value = event.target.value;
    if (!watch(`filter[${nestIndex}].value[${index}]`) && value && fieldsLength === nestIndex + 1) {
      appendFilter('');
    }
    setValue(`filter[${nestIndex}].value[${index}]`, value);
  }
  function onAddNewField(key: number, fields: Record<'id', string>[]) {
    key !== fields.length - 1 ? remove(key) : append('');
    onChangeIterFields();
  }

  return (
    <div className={'flex flex-col items-center '}>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            {k !== 0 && (
              <div className={nestedFieldsBlockClassName}>{t('components.findData.filter.or')}</div>
            )}
            <div className="w-full transition-all">
              <Input
                defaultValue={defaultValue}
                onChange={(value) => onInputChange(value, k)}
                // disabled={!watch().filter[nestIndex].id}
                iconClassName={'!text-action cursor-pointer'}
                onIconClick={() => onAddNewField(k, fields)}
                icon={k !== fields.length - 1 ? 'MinusIcon' : 'CirclePlusIcon'}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default NestedField;
