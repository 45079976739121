import React, { FC } from 'react';

import { IconType } from '../icon.type';

const Edit3Icon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 13 14">
        <path
          fill="currentColor"
          d="M11.627 3.682l.828-.82c.41-.41.432-.872.05-1.253l-.307-.315c-.38-.38-.842-.344-1.252.059l-.835.82 1.516 1.509zM2.464 12.83l8.416-8.416-1.502-1.501L.963 11.32l-.725 1.787c-.095.249.16.505.395.417l1.831-.695z"
        ></path>
      </svg>
    </div>
  );
};

export default Edit3Icon;
