import React, { Dispatch, FC, SetStateAction } from 'react';
import cn from 'classnames';
import { Box, CircleButton, Empty, Skeleton } from '@ui';
import { useGetVendorsOrganizationsQuery } from '@app/store/api/organizations.api';

type OrganizationDropdownBlockType = {
  activeOrganizationId: number | null;
  onChangeActiveOrganization: Dispatch<SetStateAction<number | null>>;
};
const OrganizationDropdownBlock: FC<OrganizationDropdownBlockType> = (props) => {
  const { onChangeActiveOrganization, activeOrganizationId } = props;
  const { data: organizationList, isFetching } = useGetVendorsOrganizationsQuery(null);
  const renderOrganizationList = organizationList ? (
    organizationList?.organizations.map(({ organization_id, name }) => (
      <Box
        key={organization_id}
        className={cn(
          'cursor-pointer p-[10px] hover:ring-1 ring-basic_orange transition',
          activeOrganizationId === organization_id && 'ring-1',
        )}
      >
        <div className="flex justify-between">
          <div
            className="font-bold text-0color block w-full"
            onClick={() => onChangeActiveOrganization(organization_id)}
          >
            {name}
          </div>
          <div>
            <CircleButton
              icon="ChevronRightIcon"
              size={12}
              onClick={() => onChangeActiveOrganization(organization_id)}
              className="bg-action text-white rounded-full p-[4px] "
            />
          </div>
        </div>
      </Box>
    ))
  ) : isFetching ? (
    <div className="flex flex-col gap-[11px]">
      <Skeleton count={4} height={40} round="small" />
    </div>
  ) : (
    <Empty />
  );

  return <>{renderOrganizationList}</>;
};

export default OrganizationDropdownBlock;
