import React, { FC } from 'react';
import ApexCharts from 'react-apexcharts';
import theme from '@app/themes/default';

interface ChartSegments {
  redMin: number;
  greenOrBurgundy: number;
  white: number;
  redMax: number;
}

type HalfDonutBar2PropsType = {
  MaxAvailable: number;
  MaxScale: number;
  MinAvailable: number;
  MinScale: number;
  current: number;
};

const HalfDonutBar: FC<HalfDonutBar2PropsType> = (props) => {
  const { MaxAvailable, MaxScale, MinAvailable, MinScale, current } = props;

  function calculateSemiDonutSegments(): ChartSegments {
    if (current > MaxScale) {
      return {
        redMin: 0,
        greenOrBurgundy: 100,
        white: 0,
        redMax: 0,
      };
    }
    const totalUnits = MaxScale - MinScale;

    const redMin = MinAvailable - MinScale;
    const redMax = MaxScale - MaxAvailable;

    let greenOrBurgundy = 0;
    let white = totalUnits - redMin - redMax;

    switch (true) {
      case current <= MinAvailable: {
        const burgundyFromMin = current - MinScale;

        if (burgundyFromMin > -1) {
          greenOrBurgundy += burgundyFromMin;
          white -= burgundyFromMin;
        }
        break;
      }
      case current >= MaxAvailable: {
        const burgundyFromMax = current - MaxAvailable;

        if (burgundyFromMax > -1) {
          greenOrBurgundy += burgundyFromMax;
          white -= burgundyFromMax;
        }
        break;
      }
      default: {
        const greenSegment = current - MinAvailable;
        greenOrBurgundy += greenSegment;
        white -= greenSegment;
        break;
      }
    }

    return {
      redMin: (redMin / totalUnits) * 100,
      greenOrBurgundy: (greenOrBurgundy / totalUnits) * 100,
      white: (white / totalUnits) * 100,
      redMax: (redMax / totalUnits) * 100,
    };
  }

  const segments = calculateSemiDonutSegments();

  const series = [segments.redMin, segments.greenOrBurgundy, segments.white, segments.redMax];
  const colors = [
    theme.red_donut,
    current > MaxAvailable ? theme.burgundy_donut : theme.green_donut,
    theme.gray_donut,
    theme.red_donut,
  ];

  const pieSettongs = {
    offsetY: 20,
    startAngle: -90,
    endAngle: 90,
    hole: 0.65,
  };

  const options = {
    chart: {
      type: 'donut',
      sparkline: {
        enabled: true,
      },
    },
    colors: colors,
    plotOptions: {
      pie: {
        offsetY: pieSettongs.offsetY,
        startAngle: pieSettongs.startAngle,
        endAngle: pieSettongs.endAngle,
        hole: pieSettongs.hole,
        expandOnClick: false,
        donut: {
          size: '75',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="relative w-[140px] h-[50px] top-[-18px] mr-[100px]">
      <ApexCharts options={options as any} series={series} type="donut" />
    </div>
  );
};

export default HalfDonutBar;
