import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { InputProps } from '@app/components/ui/input/types/input.type';
import { Icon } from '@ui';
import InputMask, { ReactInputMask } from 'react-input-mask';

const Input = forwardRef((props: InputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
  const {
    mask,
    label,
    placeholder,
    name,
    type = 'text',
    defaultValue,
    disabled = false,
    error,
    icon,
    datePickerMode = false,
    inputHeight,
    onChange,
    onPaste,
    // onKeyDown,
    iconClassName,
    onIconClick,
    ...remainingProps
  } = props;

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    !datePickerMode && onChange?.(event);
  }
  function handleOnPaste(event: React.ClipboardEvent<HTMLInputElement>) {
    onPaste?.(event);
  }

  const inputClassName = ({ error, disabled }: { error: boolean; disabled: boolean }) =>
    cn(
      'border-[1px]',
      disabled ? '!text-2color !bg-b_light' : 'text-0color',
      error ? 'border-basic_red' : 'border-b_dark',
      'text-sm',
      'relative',
      'bg-white',
      'rounded-defaultR',
      'flex',
      'items-center',
      'blocks',
      'w-full',
      'h-[34px]',
    );

  const inputProps = {
    type,
    name,
    placeholder,
    className:
      'border-[0px] bg-transparent w-full h-[32px] px-[12px] text-[15px] font-[400] leading-[18px] text-2color outline-none outline-0 !ring-action focus:ring-[1px] rounded-defaultR',
    onChange: handleOnChange,
    onPaste: handleOnPaste,
    disabled,
    // onKeyDown: (event) => onKeyDown?.(event),
  };

  return (
    <div className="w-full relative">
      {label && (
        <label className="block leading-[110%] ml-[6px] mb-[2px] text-black text-[15px] font-[500] ">
          {label}
        </label>
      )}
      <div
        style={{ height: `${inputHeight && inputHeight}px` }}
        className={inputClassName({ error: !!error, disabled })}
      >
        {mask ? (
          <InputMask
            onClick={(e) => e.stopPropagation()}
            mask={mask}
            ref={forwardedRef as ForwardedRef<ReactInputMask>}
            value={defaultValue}
            {...inputProps}
            {...remainingProps}
          />
        ) : (
          <input
            style={{ height: `${inputHeight && inputHeight - 2}px` }}
            onClick={(e) => e.stopPropagation()}
            ref={forwardedRef}
            value={defaultValue}
            {...inputProps}
            {...remainingProps}
          />
        )}

        {icon && (
          <div className="absolute right-[10px] bg-transparent flex items-center">
            <Icon className={iconClassName} onClick={onIconClick} name={icon} size={14} />
          </div>
        )}
      </div>
      {!!error && true && (
        <div className="mt-[1px] absolute text-sm text-basic_red dark:text-red-500">{error}</div>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
