import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LoadingIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: 'auto', background: 'none' }}
        width={size}
        height={size}
        display="block"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="23" r="13" fill="currentColor">
          <animate
            attributeName="cy"
            calcMode="spline"
            dur="1s"
            keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            values="23;77;23"
          ></animate>
        </circle>
      </svg>
    </div>
  );
};

export default LoadingIcon;
