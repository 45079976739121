import React, { FC, useEffect } from 'react';
import { Button, CircleButton, Icon, Input, Loading, Modal } from '@ui';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  useAddInfoMessageSubscribeMutation,
  useGetInfoMessageSubscribeQuery,
} from '@app/store/api/message.api';
import { useAppSelector } from '@app/store';
type InfoSettingsEmailsModalPropsType = {
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};
type FormValues = {
  emails: {
    // name: string;
    email: string;
  }[];
};
export const InfoSettingsEmailsModal: FC<InfoSettingsEmailsModalPropsType> = (props) => {
  const { t } = useTranslation();
  const { show, onClose } = props;
  //api
  const { user } = useAppSelector((state) => state.userState);
  const [createNewEmailsList] = useAddInfoMessageSubscribeMutation();
  const { data: messagesData, isLoading } = useGetInfoMessageSubscribeQuery(
    {
      organization_id: user?.operator_id as number,
    },
    { skip: !user },
  );
  const { register, control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: messagesData?.message.emails.length
      ? { emails: messagesData?.message?.emails?.map((item) => ({ email: item })) || [] }
      : { emails: [{ email: '' }] },
  });
  useEffect(() => {
    messagesData?.message?.emails &&
      reset({ emails: messagesData?.message?.emails?.map((item) => ({ email: item })) });
  }, [messagesData?.message.emails, reset]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });
  function onSubmit(data: FormValues) {
    const emails = data?.emails?.map((item) => item.email) || [];
    user &&
      createNewEmailsList({
        organization_id: user.operator_id,
        list_email: { emails: emails },
      });
    onClose(false);
  }
  const modalLabel = (
    <div className="flex w-full gap-[20px] flex-col items-center justify-center mb-[25px]">
      <div
        className={
          'w-[57px] h-[57px] rounded-full bg-action/[0.1] flex items-center justify-center'
        }
      >
        <Icon className={'!text-action'} size={29} name={'EnvelopIcon'} />
      </div>
      <div className="w-full text-center">{t('pages.informationSettings.emails_modal.title')}</div>
    </div>
  );
  if (isLoading) return <Loading />;
  return (
    <Modal
      withoutFooter
      label={modalLabel}
      variant={'mediumW650'}
      show={show}
      onClose={onClose.bind(null, false)}
    >
      {show && (
        <form onSubmit={handleSubmit(onSubmit)} className={'pb-[20px]'}>
          <div className={'bg-basic_app_bg rounded-defaultR px-[15px] relative pb-[40px]'}>
            <div className={'flex items-center gap-[190px] py-[20px] pl-[25px]'}>
              {/*<div className={'text-[12px]'}>Имя</div>*/}
              <div className={'text-[12px]'}>
                {t('pages.informationSettings.emails_modal.email')}
              </div>
            </div>
            <div className={'flex flex-col gap-[4px]'}>
              {fields.map((_, index) => (
                <div
                  key={index}
                  className={
                    'flex items-center gap-[15px] bg-white py-[12px] px-[20px] rounded-defaultR'
                  }
                >
                  {/*<Input {...register(`list_email.${index}.name`)} />*/}
                  <Input {...register(`emails.${index}.email`)} type={'email'} />
                  <CircleButton
                    onClick={() => remove(index)}
                    icon={'TrashIcon'}
                    className={'!text-basic_red'}
                  />
                </div>
              ))}
            </div>

            <div className={'absolute bottom-[2px]'}>
              <Button
                onClick={() => append({ email: '' })}
                label={t('pages.informationSettings.emails_modal.add_user_button')}
                icon={'PlayCircleIcon'}
                fill={'linked'}
              />
            </div>
          </div>
          <div className={'pt-[20px]  flex items-center justify-center'}>
            <div className={'w-[250px]'}>
              <Button
                type={'submit'}
                label={t('pages.informationSettings.emails_modal.save_button')}
              />
            </div>
          </div>
        </form>
      )}
    </Modal>
  );
};
