import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { CustomDatePicker, Input, Button } from '@ui';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';
import { useDebouncedCallback } from '@app/hooks/use-debounce';
import { MessageHistory } from '@appTypes/message.type';

type MessagesFilterFormPropsType = {
  handleChangeFilter(data: Pick<MessageHistory, 'vehicle_name_part' | 'gt' | 'lt' | 'code'>): void;
};

export const MessagesFilterForm: FC<MessagesFilterFormPropsType> = (props) => {
  const { handleChangeFilter } = props;
  const submitRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { handleSubmit, setValue, register } = useForm({
    defaultValues: {
      gt: moment().format('DD.MM.YYYY'),
      lt: moment().format('DD.MM.YYYY'),
      gtTime: '00:00',
      ltTime: '23:59',
    },
  });

  const fieldsDescriptionClassName = cn(
    'whitespace-nowrap',
    'font-[500]',
    'text-[15px]',
    'leading-[16.4px]',
    'text-1color',
  );

  function onSubmit(data) {
    const { gt, lt, gtTime, ltTime } = data;
    console.log(lt);
    console.log(gt);
    const formattedGT = moment(gt, 'DD.MM.YYYY').format('YYYY-MM-DD');
    const formattedLT = moment(lt, 'DD.MM.YYYY').format('YYYY-MM-DD');

    handleChangeFilter({
      gt: `${formattedGT} ${gtTime}`,
      lt: `${formattedLT} ${ltTime}`,
    });
  }

  const debChange = useDebouncedCallback((e) => onSubmit(e), 500);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(debChange)}>
      <div className="w-full h-[75px] rounded-[10px] border-solid border-[1px] border-b_dark flex justify-center">
        <div className="flex items-center px-[20px] w-[570px] gap-[10px] ">
          <span className={fieldsDescriptionClassName}>
            {t('pages.messagesList.form.fromField')}
          </span>
          <div className="flex items-center gap-[2px]">
            <CustomDatePicker
              onChange={(date) => {
                setValue('gt', date);
              }}
              format={'DD.MM.YYYY'}
              isFull
            />
            <div className="w-[130px]">
              <Input {...register('gtTime')} icon={'ClockIcon'} type={'text'} mask={'99:99'} />
            </div>
          </div>
          <span className={fieldsDescriptionClassName}>
            {t('pages.messagesList.form.beforeField')}
          </span>
          <div className="flex items-center gap-[2px]">
            <CustomDatePicker
              onChange={(date) => {
                setValue('lt', date);
              }}
              format={'DD.MM.YYYY'}
              isFull
            />
            <div className="w-[130px]">
              <Input {...register('ltTime')} icon={'ClockIcon'} type={'text'} mask={'99:99'} />
            </div>
          </div>
        </div>
        <div className="flex w-[320px] items-center justify-center px-[15px] gap-[10px]">
          <Button type={'submit'} label={t('pages.vehiclePark.filter.confirm')} />
        </div>
      </div>
      <input ref={submitRef} type="submit" className={'hidden'} />
    </form>
  );
};
