import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const RecordChannelIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 28 27">
        <path
          fill="currentColor"
          d="M10.388 26.199c.623 0 1.099-.489 1.099-1.075V1.26c0-.586-.476-1.074-1.099-1.074-.61 0-1.062.488-1.062 1.074v23.865c0 .585.452 1.074 1.062 1.074zm7.337-10.193a2.408 2.408 0 012.16-.134V3.958c0-.599-.476-1.1-1.086-1.1-.61 0-1.074.501-1.074 1.1v12.048zM6.189 21.45c.61 0 1.086-.488 1.086-1.074V6.008c0-.598-.476-1.086-1.086-1.086a1.07 1.07 0 00-1.074 1.086v14.368c0 .586.464 1.074 1.074 1.074zm8.41-1.587c.611 0 1.087-.476 1.087-1.062V7.583c0-.598-.476-1.086-1.086-1.086a1.07 1.07 0 00-1.075 1.086v11.218c0 .586.464 1.062 1.075 1.062zM1.99 16.531c.611 0 1.087-.488 1.087-1.087V10.94c0-.598-.476-1.099-1.086-1.099-.61 0-1.074.5-1.074 1.099v4.504a1.07 1.07 0 001.074 1.087zm21.058 4.822c1.196 0 2.05-.94 2.05-2.161v-4.81c0-1.233-.854-2.16-2.05-2.16-1.209 0-2.063.927-2.063 2.16v4.81c0 1.233.854 2.16 2.063 2.16zm-3.052 5.053H26.1a.68.68 0 00.708-.708c0-.415-.293-.72-.708-.72h-2.356v-1.123c2.429-.293 4.101-2.112 4.101-4.602v-1.11c0-.367-.33-.684-.696-.684-.39 0-.72.317-.72.683v1.11c0 1.93-1.416 3.309-3.381 3.309-1.978 0-3.394-1.38-3.394-3.308v-1.11c0-.367-.33-.684-.708-.684-.378 0-.708.317-.708.683v1.11c0 2.491 1.685 4.322 4.114 4.603v1.123h-2.356a.7.7 0 00-.72.72c0 .415.305.708.72.708z"
        ></path>
      </svg>
    </div>
  );
};

export default RecordChannelIcon;
