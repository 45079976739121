import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Input, Select } from '@ui';
import { CreateUserRequest, User } from '@appTypes/user.type';
import { useTranslation } from 'react-i18next';
import { OptionItem } from '@app/components/ui/select/types/select.type';
import { Organization } from '@appTypes/organization.type';

import { UserRoles } from '@appTypes/role.type';

import {
  fieldBlockClassName,
  fieldClassName,
  fieldLabelClassName,
} from './settings-user-form.styles';

type SettingsUserFormPropsType = {
  handleSubmitForm(data: CreateUserRequest): void;
  submitRef: React.RefObject<HTMLInputElement>;
  userData: User | undefined;
  editMode: boolean;
  organizations: Organization[] | undefined;
  rolesList: UserRoles | undefined;
};
export const SettingsUserForm: FC<SettingsUserFormPropsType> = (props) => {
  const { handleSubmitForm, submitRef, editMode, userData, organizations, rolesList } = props;
  const localFieldErrPath = 'common.fieldRequiredError';
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<CreateUserRequest>({
    defaultValues: editMode ? userData : undefined,
  });

  const renderRolesOptions: OptionItem[] =
    rolesList?.roles?.map(({ role_id, name }) => ({ title: name, value: role_id })) || [];
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={'flex flex-col w-full h-full gap-[20px] pb-[15px]'}>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.username')}</span>
          <div className={fieldClassName}>
            <Input
              {...register('username', {
                required: { value: true, message: t(localFieldErrPath) },
              })}
              error={errors.username?.message}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.email')}</span>
          <div className={fieldClassName}>
            <Input {...register('email')} type={'email'} />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.phone')}</span>
          <div className={fieldClassName}>
            <Controller
              render={({ field: { value } }) => (
                <Input
                  {...register('phone_number', {
                    required: { value: true, message: t(localFieldErrPath) },
                  })}
                  defaultValue={value}
                  mask="+7(999)999-99-99"
                  error={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              control={control}
              rules={{
                validate: (value) =>
                  value?.length != 0
                    ? (value?.replace(/[_\-()+]/gm, '').length as number) >= 11 ||
                      t('common.fieldPhoneError')
                    : true,
              }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.status')}</span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <Select
                  defaultValue={watch('status')}
                  options={[
                    {
                      value: 'active',
                      title: t('pages.settingsUser.status.active'),
                      className: 'text-basic_green',
                    },
                    {
                      value: 'disabled',
                      title: t('pages.settingsUser.status.un_active'),
                      className: 'text-basic_red',
                    },
                  ]}
                  onChange={(value) => {
                    setValue('status', value as string);
                    clearErrors('status');
                  }}
                  error={errors.status?.message}
                />
              )}
              name={'status'}
              control={control}
              rules={{
                validate: (value) => !!value || t(localFieldErrPath),
              }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsUser.form.label.organization')}
          </span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <Select
                  dropHeight={100}
                  defaultValue={watch('operator_id')}
                  options={
                    organizations?.map((item) => ({
                      title: item.name,
                      value: item.organization_id,
                    })) || []
                  }
                  onChange={(value) => {
                    setValue('operator_id', value as number);
                    clearErrors('operator_id');
                  }}
                  error={errors.operator_id?.message}
                />
              )}
              name={'operator_id'}
              control={control}
              rules={{
                validate: (value) => !!value || t(localFieldErrPath),
              }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.role')}</span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <Select
                  dropTop
                  disable={!renderRolesOptions.length}
                  defaultValue={watch('role_id')}
                  options={renderRolesOptions}
                  onChange={(value) => {
                    setValue('role_id', value as string);
                    clearErrors('role_id');
                  }}
                  error={errors.role_id?.message}
                />
              )}
              name={'role_id'}
              control={control}
              rules={{
                validate: (value) => !!value || t(localFieldErrPath),
              }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsUser.form.label.login')}</span>
          <div className={fieldClassName}>
            <Input
              {...register('login', {
                required: { value: !editMode, message: t(localFieldErrPath) },
              })}
              error={errors.login?.message}
              disabled={editMode}
            />
          </div>
        </div>
        {!editMode && (
          <div className={fieldBlockClassName}>
            <span className={fieldLabelClassName}>
              {t('pages.settingsUser.form.label.password')}
            </span>
            <div className={fieldClassName}>
              <Input
                {...register('password', {
                  required: { value: true, message: t(localFieldErrPath) },
                })}
                error={errors.password?.message}
              />
            </div>
          </div>
        )}
      </div>
      <input type="submit" ref={submitRef} className={'hidden'} />
    </form>
  );
};
