import React, { useRef, useState } from 'react';
import {
  Button,
  CircleButton,
  HelpModal,
  Icon,
  Loading,
  Modal,
  PageHeader,
  Paginator,
  Table,
} from '@ui';
import { useTranslation } from 'react-i18next';

import { Organization, OrganizationRequest } from '@app/store/interfaces/organization.type';

import {
  useAddOrganizationMutation,
  useEditOrganizationMutation,
  useGetOrganizationsQuery,
  useLazyGetOrganizationQuery,
  useRemoveOrganizationMutation,
} from '@app/store/api/organizations.api';
import { titlesData } from '@app/pages/settings/settings-organizations/data';

import { PaginationResponse } from '@app/components/ui/paginator/paginator';

import moment from 'moment';

import { OrganizationForm } from './blocks/organization-form';
const SettingsOrganizationsPage = () => {
  const LOCALE_TABLE_TITLE = 'pages.settingsOrganizations.table.title';
  const { t } = useTranslation();
  const [organizationPagination, changeOrganizationPagination] = useState({
    sortDesc: false,
    limit: 10,
    offset: 0,
  });
  //useState
  const [singleOrganizationData, changeSingleOrganizationData] = useState<Organization>();
  const [editMode, changeEditMode] = useState<boolean>(false);
  const [removalModal, changeRemovalModalState] = useState<boolean>(false);
  const [removeEditItemId, changeRemoveEditItemId] = useState<number>();
  const [organizationCardModalState, changeOrganizationCardModalState] = useState<boolean>(false);
  //api
  const { data: organizationsList, isLoading } = useGetOrganizationsQuery(organizationPagination);
  const [addOrganization] = useAddOrganizationMutation();
  const [getOrganization] = useLazyGetOrganizationQuery();
  const [removeOrganization] = useRemoveOrganizationMutation();
  const [editOrganization] = useEditOrganizationMutation();
  const submitRef = useRef<HTMLInputElement>(null);
  // handlers
  function handleSubmitForm(data: OrganizationRequest) {
    editMode
      ? removeEditItemId &&
        editOrganization({ body: data, params: { organization_id: removeEditItemId } })
      : addOrganization(data);
    changeOrganizationCardModalState((prev) => !prev);
  }
  function changeOrgPaginationByClick(paginationData: PaginationResponse) {
    changeOrganizationPagination({ ...paginationData, sortDesc: false });
  }
  function onUtilClick({ id, action }: { id: number; action: 'delete' | 'edit' }) {
    changeRemoveEditItemId(id);
    if (action === 'delete') {
      changeRemovalModalState(true);
    } else {
      changeEditMode(true);
      getOrganization({ organization_id: id }).then((data) => {
        changeSingleOrganizationData(data.data);
        changeOrganizationCardModalState(true);
      });
    }
  }
  function removeOrganizationHandler() {
    if (!removeEditItemId) return;
    removeOrganization({ organization_id: removeEditItemId });
  }
  const modalLabel = (
    <div className="flex w-full gap-[20px] flex-col items-center justify-center mb-[25px]">
      <div
        className={
          'w-[57px] h-[57px] rounded-full bg-action/[0.1] flex items-center justify-center'
        }
      >
        <Icon className={'text-action'} size={29} name={'ExclamationMark'} />
      </div>
      <div className="w-full text-center">
        {t('pages.settingsOrganizations.form.title')}
        <HelpModal helperText={'help'} />
      </div>
    </div>
  );
  const organizationsData = organizationsList
    ? organizationsList.organizations.map((org) => ({
        ...org,
        utils: (
          <div className="flex w-full items-center gap-[10px]">
            <div>
              <CircleButton
                icon="SettingIcon"
                onClick={() => onUtilClick({ id: org.organization_id, action: 'edit' })}
              />
            </div>
            <div>
              <CircleButton
                icon="TrashIcon"
                onClick={() => onUtilClick({ id: org.organization_id, action: 'delete' })}
              />
            </div>
          </div>
        ),
        install_time: moment(org.install_time).format('yyyy.DD.MM hh:mm:ss'),
      }))
    : [];
  if (isLoading) return <Loading />;
  return (
    <div>
      <PageHeader
        icon={'ExclamationMark'}
        label={t('pages.settingsOrganizations.pageHeader.title')}
      >
        <Button
          onClick={() => {
            changeEditMode(false);
            changeOrganizationCardModalState(true);
          }}
          icon={'CirclePlusIcon'}
          label={t('pages.settingsOrganizations.pageHeader.buttonLabel')}
        />
      </PageHeader>
      <Table
        dataSource={organizationsData}
        columns={titlesData.map((item) => ({
          ...item,
          title: item.title && t(LOCALE_TABLE_TITLE + item.title),
        }))}
      />
      {!!organizationsData.length && (
        <Paginator
          containerClassName={'mt-[20px]'}
          page={organizationPagination.offset + 1}
          limit={organizationPagination.limit}
          count={organizationsList?.total || 20}
          onChange={changeOrgPaginationByClick}
        />
      )}

      <Modal
        isAutoClosable={false}
        variant={'mediumW650'}
        show={organizationCardModalState}
        onClose={changeOrganizationCardModalState.bind(null, false)}
        onSuccess={() => {
          submitRef.current?.click();
        }}
        label={modalLabel}
      >
        {organizationCardModalState && (
          <OrganizationForm
            editMode={editMode}
            singleOrganizationData={singleOrganizationData}
            formRef={submitRef}
            handleSubmitForm={handleSubmitForm}
          />
        )}
      </Modal>
      <Modal
        onClose={changeRemovalModalState.bind(null, false)}
        show={removalModal}
        variant={'removal'}
        label={'remove'}
        onRemove={() => removeOrganizationHandler()}
      >
        <div>text</div>
      </Modal>
    </div>
  );
};

export default SettingsOrganizationsPage;
