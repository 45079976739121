import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRouteList } from '@app/utils/routes';
import RequiredUser from '@app/hocs/require-user.hoc';
import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs';
import DataSearchPage from '@app/pages/datas';

export const dataRoute = (
  <Route path={navigationRouteList.dataSearch}>
    <Route
      index
      element={
        <RequiredUser>
          <DataSearchPage />
        </RequiredUser>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={navigationRouteList.dataSearch} crumb="data_search" />,
      }}
    />
  </Route>
);
