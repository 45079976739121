import React, { FC, useMemo } from 'react';
import { Box } from '@ui';
import { FilterPlotData } from '@appTypes/search-data.type';

type CurrentGraphParamsBlockProps = {
  params: FilterPlotData['items'];
  currentActiveIndex: string;
};

const CurrentGraphParamsBlock: FC<CurrentGraphParamsBlockProps> = (props) => {
  const { params } = props;

  const renderConstantFields = useMemo(() => {
    {
      // const constantFields = params
      //   .filter((chart) => !chart.is_plot)
      //   .reduce<{ [key: string]: string }>((lastList, currentItem) => {
      //     lastList[String(currentItem.name || currentItem.key)] = String(
      //       Number(currentItem?.data[0]) || '-',
      //     );
      //     return lastList;
      //   }, {});
      return (
        <div className="inline-flex w-full flex-wrap">
          {params
            .filter((fields) => !fields.is_plot)
            .map((fieldValue, index) => (
              <div key={index} className="inline-flex basis-1/2 w-full text-4color justify-between">
                <span>{fieldValue.name || fieldValue.key}</span>
                <span className="font-[500] mr-[15px] text-0color">
                  {fieldValue.data[0] || '-'}
                </span>
              </div>
            ))}
        </div>
      );
    }
  }, [params]);

  return (
    <Box className="p-[30px] text-[14px] mb-[10px] flex font-normal text-3color flex-wrap">
      {renderConstantFields}
    </Box>
  );
};

export default CurrentGraphParamsBlock;
