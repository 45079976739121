import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const VolumeMinIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 15 14"
      >
        <path
          fill="currentColor"
          d="M2.402 9.832h2.073a.22.22 0 01.161.059l3.223 2.878c.373.344.674.498 1.055.498a.908.908 0 00.937-.937V1.146c0-.542-.395-.982-.952-.982-.374 0-.63.169-1.04.535L4.636 3.563a.22.22 0 01-.16.058H2.401c-1.076 0-1.618.564-1.618 1.7v2.812c0 1.135.542 1.699 1.618 1.699zm.125-1.289c-.25 0-.366-.117-.366-.366v-2.9c0-.25.117-.367.366-.367h2.285a.7.7 0 00.52-.19l2.93-2.674c.036-.029.073-.058.117-.058.059 0 .102.044.102.11v9.243c0 .066-.043.11-.095.11-.044 0-.08-.022-.124-.059l-2.93-2.659a.72.72 0 00-.52-.19H2.527zm9.814 1.428a.684.684 0 00.938-.19c.593-.791.937-1.905.937-3.062 0-1.157-.344-2.27-.937-3.069a.668.668 0 00-.938-.175c-.351.227-.417.659-.139 1.069.41.579.637 1.355.637 2.175 0 .813-.234 1.59-.637 2.176-.27.41-.212.834.14 1.076z"
        ></path>
      </svg>
    </div>
  );
};

export default VolumeMinIcon;
