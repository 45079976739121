import { createApi } from '@reduxjs/toolkit/query/react';

import { endpointUrl } from '@app/utils/endpoint-url';

import { UserRoles } from '@appTypes/role.type';

import customFetchBase from '../customFetchBase';

const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: customFetchBase,
  tagTypes: ['Roles'],
  endpoints: (builder) => ({
    getRoles: builder.query<UserRoles, void>({
      query() {
        return {
          url: endpointUrl.roles,
        };
      },
    }),
  }),
});
export default rolesApi;
export const { useGetRolesQuery, useLazyGetRolesQuery } = rolesApi;
