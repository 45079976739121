import React, { FC } from 'react';

import { IconType } from '../icon.type';

const Edit2Icon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg width={size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M10.8802 8.55013L11.401 8.0293C11.5 7.93034 11.6667 8.00065 11.6667 8.13867V12.4173C11.6667 13.1074 11.1068 13.6673 10.4167 13.6673H1.25C0.559896 13.6673 0 13.1074 0 12.4173V3.25065C0 2.56055 0.559896 2.00065 1.25 2.00065H8.86198C9 2.00065 9.07031 2.16992 8.97135 2.26628L8.45052 2.78711C8.42188 2.81576 8.38021 2.83398 8.34115 2.83398H1.25C1.02083 2.83398 0.833333 3.02148 0.833333 3.25065V12.4173C0.833333 12.6465 1.02083 12.834 1.25 12.834H10.4167C10.6458 12.834 10.8333 12.6465 10.8333 12.4173V8.65951C10.8333 8.61784 10.849 8.57878 10.8802 8.55013ZM14.6797 3.57096L6.54167 11.709L3.9401 11.998C3.59115 12.0371 3.29688 11.7428 3.33594 11.3939L3.625 8.79232L11.763 0.654297C12.1901 0.227214 12.8828 0.227214 13.3099 0.654297L14.6797 2.02409C15.1068 2.45117 15.1068 3.14388 14.6797 3.57096ZM12.2422 4.83138L10.5052 3.0944L4.42188 9.17513L4.20573 11.1309L6.16146 10.9147L12.2422 4.83138ZM14.0911 2.61263L12.7214 1.24284C12.6224 1.14388 12.4557 1.13867 12.3542 1.24284L11.0938 2.50326L12.8307 4.24023L14.0911 2.97982C14.1927 2.88086 14.1927 2.71419 14.0911 2.61263Z"
        />
      </svg>
    </div>
  );
};

export default Edit2Icon;
