import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const HeadphoneIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 16">
        <path
          fill="currentColor"
          d="M.425 8.332c0 2.071.601 4.361 1.613 6.132.191.335.554.437.896.266.335.493.909.69 1.558.5.82-.253 1.19-.964.944-1.778l-.985-3.247c-.246-.807-.943-1.176-1.77-.936-.349.102-.616.293-.786.546-.069-.499-.11-.998-.11-1.483 0-3.74 2.283-6.228 5.708-6.228 3.425 0 5.715 2.489 5.715 6.228 0 .485-.034.984-.11 1.483-.17-.246-.437-.437-.792-.546-.82-.24-1.518.13-1.764.936l-.984 3.247c-.253.82.123 1.525.936 1.777.656.192 1.23-.006 1.559-.492.348.164.71.062.902-.273 1.012-1.77 1.62-4.06 1.62-6.132 0-4.56-2.823-7.588-7.082-7.588C3.241.744.425 3.772.425 8.332z"
        ></path>
      </svg>
    </div>
  );
};

export default HeadphoneIcon;
