import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FolderIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 54 46">
        <g clipPath="url(#clip0_4866_80665)">
          <path
            fill="currentColor"
            style={{ filter: 'brightness(0.9)' }}
            d="M27 3.732V3A2.152 2.152 0 0024.848.849H2.413A2.152 2.152 0 00.262 3v38.798h53.476V5.883a2.152 2.152 0 00-2.152-2.151H27z"
          ></path>
          <path fill="#fff" d="M51.864 6.833H2.636v35.359h49.228V6.832z"></path>
          <path
            fill="currentColor"
            d="M26.237 11.253l-.794 2.2H2.413a2.152 2.152 0 00-2.151 2.152V43c0 1.188.963 2.152 2.151 2.152h49.173A2.152 2.152 0 0053.738 43V11.984a2.152 2.152 0 00-2.152-2.151H28.261c-.906-.001-1.716.567-2.024 1.42z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_4866_80665">
            <path fill="#fff" d="M0 0H54V46H0z"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FolderIcon;
