import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const Person: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 26 18"
      >
        <path
          fill="currentColor"
          d="M17.588 8.742c2.127 0 3.85-1.872 3.85-4.175 0-2.267-1.714-4.078-3.85-4.078-2.11 0-3.85 1.837-3.85 4.096.01 2.294 1.732 4.157 3.85 4.157zM7.4 8.944c1.855 0 3.349-1.643 3.349-3.673 0-1.978-1.494-3.578-3.349-3.578-1.837 0-3.357 1.626-3.348 3.586 0 2.03 1.503 3.665 3.348 3.665zm10.187-1.74c-1.213 0-2.241-1.142-2.241-2.628 0-1.432 1.02-2.549 2.24-2.549 1.231 0 2.242 1.099 2.242 2.54 0 1.477-1.02 2.637-2.241 2.637zM7.4 7.424c-.993 0-1.837-.95-1.837-2.145 0-1.142.835-2.074 1.837-2.074 1.02 0 1.846.914 1.846 2.066 0 1.204-.844 2.153-1.846 2.153zm-4.834 9.598h6.83c-.484-.282-.81-.923-.748-1.503H2.49c-.168 0-.238-.08-.238-.229 0-2.013 2.355-3.929 5.142-3.929.984 0 1.968.246 2.724.668.299-.43.668-.8 1.152-1.116-1.117-.686-2.505-1.055-3.876-1.055-3.718 0-6.724 2.655-6.724 5.573 0 1.054.633 1.59 1.898 1.59zm9.545 0h10.952c1.52 0 2.258-.484 2.258-1.521 0-2.417-3.023-5.634-7.734-5.634s-7.734 3.217-7.734 5.634c0 1.037.738 1.52 2.258 1.52zm-.28-1.539c-.203 0-.282-.07-.282-.228 0-1.345 2.17-3.85 6.038-3.85 3.867 0 6.038 2.505 6.038 3.85 0 .158-.08.228-.29.228H11.83z"
        ></path>
      </svg>
    </div>
  );
};

export default Person;
