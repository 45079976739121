import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const EnvelopIcon: FC<IconType> = (props) => {
  const { className, size } = props;
  console.log(size);
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 20 17">
        <path
          fill="currentColor"
          d="M3.013 16.389h14.176c1.697 0 2.681-.985 2.681-2.866V3.812c0-1.881-.993-2.866-2.892-2.866H2.803c-1.697 0-2.68.976-2.68 2.866v9.711c0 1.881.992 2.866 2.89 2.866zM9.007 8.97L2.608 2.643c.123-.027.264-.044.413-.044H16.97c.158 0 .299.017.43.052l-6.39 6.32c-.368.36-.676.518-1.001.518-.325 0-.633-.167-1.002-.518zM1.765 3.935l4.78 4.702-4.78 4.728v-9.43zm11.707 4.702l4.755-4.676v9.387l-4.755-4.711zm-10.45 6.09c-.159 0-.308-.017-.44-.043L7.618 9.69l.475.475c.641.624 1.257.888 1.916.888.65 0 1.274-.264 1.907-.888l.483-.475 5.028 4.984c-.14.035-.29.052-.457.052H3.02z"
        ></path>
      </svg>
    </div>
  );
};

export default EnvelopIcon;
