import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LogOutIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 17">
        <path
          fill="currentColor"
          d="M2.743 10.137v4.32c0 1.19.807 1.962 2.03 1.962h7.69c1.225 0 2.031-.773 2.031-1.962V2.665c0-1.183-.806-1.962-2.03-1.962h-7.69c-1.224 0-2.03.78-2.03 1.962v4.327h1.339V2.884c0-.533.314-.841.875-.841h7.321c.56 0 .875.308.875.84v11.355c0 .534-.314.841-.875.841H4.958c-.56 0-.875-.308-.875-.84v-4.102h-1.34zM1.328 9.132h4.498l1.32-.069-1.354 1.19-.37.383a.624.624 0 00-.17.424c0 .32.232.546.547.546.157 0 .3-.061.41-.17l2.42-2.407c.143-.15.225-.314.225-.471 0-.158-.082-.329-.225-.472L6.209 5.68a.574.574 0 00-.41-.171.528.528 0 00-.547.547c0 .164.068.314.17.423l.37.383 1.354 1.19-1.32-.069H1.328a.56.56 0 00-.574.575.56.56 0 00.574.574z"
        ></path>
      </svg>
    </div>
  );
};

export default LogOutIcon;
