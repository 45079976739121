import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select, Textarea } from '@ui';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { VehicleCreateResponse, VehicleItem } from '@appTypes/vehicle.type';
import { Organization } from '@appTypes/organization.type';
import moment from 'moment/moment';
import { VehicleModelsList } from '@appTypes/models.type';
import AllowComponentPermission from '@app/hocs/allow-component-permission';
type SettingsVehicleFormPropsType = {
  handleSubmitForm(data: VehicleCreateResponse): void;
  submitRef: React.RefObject<HTMLInputElement>;
  vehicleData: VehicleItem | undefined;
  editMode: boolean;
  operatorsList: Organization[] | undefined;
  vehicleModels: VehicleModelsList | undefined;
};
export const SettingsVehicleForm: FC<SettingsVehicleFormPropsType> = (props) => {
  const { t } = useTranslation();
  const { submitRef, handleSubmitForm, vehicleModels, vehicleData, editMode, operatorsList } =
    props;
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm<VehicleCreateResponse>({ defaultValues: editMode ? vehicleData : undefined });
  const fieldBlockClassName = cn('flex', 'items-center', 'justify-between');
  const fieldClassName = cn('w-[280px]');
  const fieldLabelClassName = cn('text-1color', 'text-[14px]', 'font-[500]', 'leading-[15px]');
  function onSubmit(data) {
    handleSubmitForm(data);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-[20px] pb-[10px]'}>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.canId')}
          </span>
          <div className={fieldClassName}>
            <Input
              {...register('can_id', {
                required: {
                  value: true,
                  message: t('common.fieldRequiredError'),
                },
                minLength: { value: 1, message: t('common.fieldMinLengthError', { length: 1 }) },
              })}
              error={errors.can_id?.message}
              maxLength={12}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>{t('pages.settingsVehicle.form.fields.vin')}</span>
          <div className={fieldClassName}>
            <Input {...register('vin')} />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.VehicleIDInOperatorsDatabase')}
          </span>
          <div className={fieldClassName}>
            <Input {...register('id_in_operator_db')} />
          </div>
        </div>
        {editMode && (
          <div className={fieldBlockClassName}>
            <span className={fieldLabelClassName}>
              {t('pages.settingsVehicle.form.fields.install_time')}
            </span>
            <div className={'w-[280px]'}>
              <span>{moment(vehicleData?.install_time).format('yyyy.MM.DD HH:MM:SS')}</span>
            </div>
          </div>
        )}
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.vehicleModel')}
          </span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <Select
                  error={errors.vehicle_model?.message}
                  options={
                    vehicleModels?.map((model) => ({
                      title: model.model_name,
                      value: model.vehicle_model_id,
                    })) || []
                  }
                  defaultValue={watch('vehicle_model')}
                  onChange={(value) => {
                    setValue('vehicle_model', Number(value));
                    clearErrors('vehicle_model');
                  }}
                />
              )}
              name={'vehicle_model'}
              control={control}
              rules={{
                validate: (value) => !!value || t('common.fieldRequiredError'),
              }}
            />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.licensePlate')}
          </span>
          <div className={fieldClassName}>
            <Input {...register('licence_plate')} />
          </div>
        </div>
        <div className={fieldBlockClassName}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.operator')}
          </span>
          <div className={fieldClassName}>
            <Controller
              render={() => (
                <Select
                  dropTop
                  error={errors.operator_id?.message}
                  options={
                    operatorsList?.map((item) => ({
                      title: item.name,
                      value: item.organization_id,
                    })) || []
                  }
                  defaultValue={watch('operator_id')}
                  onChange={(value) => {
                    setValue('operator_id', Number(value));
                    clearErrors('operator_id');
                  }}
                />
              )}
              name={'operator_id'}
              control={control}
              rules={{
                validate: (value) => !!value || t('common.fieldRequiredError'),
              }}
            />
          </div>
        </div>
        <div className={cn(fieldBlockClassName, '!items-start')}>
          <span className={fieldLabelClassName}>
            {t('pages.settingsVehicle.form.fields.vehicleSpecifications')}
          </span>
          <div className={fieldClassName}>
            <Textarea {...register('technical_specifications')} height={84} />
          </div>
        </div>
      </div>
      <AllowComponentPermission permissionKey={'NoChanges06'} type={'disabled'}>
        <input ref={submitRef} type="submit" className={'hidden'} />
      </AllowComponentPermission>
    </form>
  );
};
