import { navigationRouteList } from '@app/utils/routes';
import { MenuBarList } from '@app/pages/layout/blocks/menu-bar/types/types';

export const menuBarData: Array<MenuBarList> = [
  {
    id: 1,
    title: 'transport_park',
    titleTag: 'MenuVisible01',
    menuItems: [
      {
        id: 1,
        name: 'list_of_vehicles',
        icon: 'SearchTextSquare',
        route: navigationRouteList.main,
        routeEnd: false,
        tag: 'MenuVisible01',
      },
    ],
  },
  {
    id: 2,
    title: 'data_search_menu',
    menuItems: [
      {
        id: 2,
        name: 'data_search',
        icon: 'MagnifierTextSearch',
        route: navigationRouteList.dataSearch,
      },
    ],
  },
  {
    id: 3,
    title: 'emergency_information',
    menuItems: [
      {
        id: 3,
        name: 'list_of_messages',
        icon: 'TextSquare',
        route: navigationRouteList.emergencyInformation,
        routeEnd: true,
      },
      {
        id: 4,
        name: 'information-settings',
        icon: 'ExclamationMark',
        route: navigationRouteList.informingSettings,
      },
    ],
  },
  {
    id: 4,
    title: 'settings_title',
    menuItems: [
      {
        id: 5,
        routeEnd: true,
        name: 'organizations',
        icon: 'ExclamationMark',
        route: navigationRouteList.settings,
        tag: 'MenuVisible08',
      },
      {
        id: 6,
        name: 'users',
        icon: 'Person',
        route: navigationRouteList.settingsUsers,
        tag: 'MenuVisible05',
      },
      {
        id: 7,
        name: 'vehicles',
        icon: 'CableCar',
        route: navigationRouteList.settingsVehicles,
      },
      {
        id: 8,
        name: 'vehicle_equipment',
        icon: 'WrenchAndScrewdriver',
        route: navigationRouteList.settingsEquipment,
      },
      {
        id: 9,
        name: 'permission_settings',
        icon: 'LockIcon',
        route: navigationRouteList.settingsPermission,
        tag: 'MenuVisible06',
      },
    ],
  },
];
