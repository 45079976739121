import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { CheckboxProps } from '@app/components/ui/checkbox/types/checkbox.type';

const Checkbox: FC<CheckboxProps> = (props) => {
  const { customColor, checked, className, defaultChecked, disabled, onChange, value } = props;
  const [checkboxValue, changeValue] = useState<boolean>();
  useEffect(() => {
    changeValue(checked);
  }, [checked]);
  const CheckboxStyles = cn(
    'text-action',
    'relative',
    'focus:outline-none focus:ring-offset-0 focus:ring-transparent focus:ring-inset',
    'w-[16px] h-[16px]',
    'rounded-[4px]',
    'cursor-pointer',
  );
  function handlerOnCheck(event) {
    changeValue(event.target.checked);
    onChange?.(event.target.checked);
  }

  return (
    <div className={className}>
      <input
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={handlerOnCheck}
        value={value}
        checked={checkboxValue}
        className={CheckboxStyles}
        style={customColor ? { color: customColor } : {}}
        type="checkbox"
      />
    </div>
  );
};

export default Checkbox;
