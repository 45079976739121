import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const TriangleRightIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 13 14"
      >
        <path
          fill="currentColor"
          d="M1.596 13.774c.323 0 .608-.11.975-.322l8.693-5.039c.652-.373.945-.688.945-1.194 0-.498-.293-.813-.945-1.194L2.571.986C2.204.774 1.919.664 1.596.664.96.664.483 1.154.483 1.938V12.5c0 .791.476 1.274 1.113 1.274z"
        ></path>
      </svg>
    </div>
  );
};

export default TriangleRightIcon;
