import React, { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
type TextareaPropsType = {
  error?: string;
  disabled?: boolean;
  name?: string;
  cols?: number;
  rows?: number;
  height?: number;
  defaultValue?: string;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
};
const Textarea = forwardRef((props: TextareaPropsType, ref) => {
  const {
    error,
    disabled = false,
    height,
    defaultValue,
    cols = 30,
    rows = 10,
    name,
    onChange,
  } = props;
  const [value, changeValue] = useState<string | number>('');
  function onChangeHandler(event) {
    const { value } = event.target;
    changeValue(value);
    onChange?.(event);
  }
  const inputClassName = ({ error, disabled }: { error: boolean; disabled: boolean }) =>
    cn(
      'border-[1px]',
      disabled ? '!text-2color !bg-b_light' : 'text-0color',
      error ? 'border-basic_red' : 'border-b_dark',
      'text-sm',
      'relative',
      'bg-white',
      'rounded-defaultR',
      'flex',
      'items-center',
      'blocks',
      'w-full',
      'h-full',
      'focus:border-[1px] focus:border-action focus:ring-0',
    );
  useEffect(() => {
    if (!defaultValue) return;
    changeValue(defaultValue);
  }, [defaultValue]);
  return (
    <textarea
      ref={ref as ForwardedRef<HTMLTextAreaElement>}
      style={{ height: `${height && height}px` }}
      className={inputClassName({ error: !!error, disabled })}
      name={name}
      cols={cols}
      rows={rows}
      value={value}
      onChange={onChangeHandler}
    ></textarea>
  );
});
Textarea.displayName = 'Textarea';
export default Textarea;
