import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const CalendarIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 19 16">
        <path
          fill="currentColor"
          d="M3.167 14.465h7.625a4.477 4.477 0 01-.476-1.18H3.058c-.652 0-1.011-.344-1.011-1.025V5.354c0-.674.359-1.026 1.01-1.026h10.21c.652 0 1.019.352 1.019 1.026v2.08a5.74 5.74 0 01.6-.037c.19 0 .388.015.579.037V3.25c0-1.508-.762-2.27-2.293-2.27H3.167c-1.53 0-2.3.754-2.3 2.27v8.943c0 1.516.77 2.27 2.3 2.27zm3.567-7.507h.433c.256 0 .344-.074.344-.33v-.432c0-.257-.088-.337-.345-.337h-.432c-.256 0-.344.08-.344.337v.432c0 .256.088.33.344.33zm2.44 0h.424c.256 0 .344-.074.344-.33v-.432c0-.257-.088-.337-.344-.337h-.425c-.263 0-.344.08-.344.337v.432c0 .256.08.33.344.33zm2.431 0h.432c.256 0 .337-.074.337-.33v-.432c0-.257-.08-.337-.337-.337h-.432c-.256 0-.344.08-.344.337v.432c0 .256.088.33.344.33zM4.303 9.353h.432c.256 0 .337-.074.337-.33V8.59c0-.257-.08-.33-.337-.33h-.432c-.257 0-.345.073-.345.33v.432c0 .256.088.33.345.33zm2.431 0h.433c.256 0 .344-.074.344-.33V8.59c0-.257-.088-.33-.345-.33h-.432c-.256 0-.344.073-.344.33v.432c0 .256.088.33.344.33zm2.44 0h.424c.256 0 .344-.074.344-.33V8.59c0-.257-.088-.33-.344-.33h-.425c-.263 0-.344.073-.344.33v.432c0 .256.08.33.344.33zm5.712 6.489c2.014 0 3.72-1.685 3.72-3.72 0-2.044-1.684-3.722-3.72-3.722-2.043 0-3.72 1.685-3.72 3.721a3.73 3.73 0 003.72 3.72zm-1.736-3.15a.458.458 0 01-.461-.454c0-.241.212-.447.461-.447h1.319V9.953c0-.242.205-.44.454-.44.249 0 .454.198.454.44v2.285a.447.447 0 01-.454.454H13.15zm-8.847-.937h.432c.256 0 .337-.08.337-.337v-.432c0-.257-.08-.33-.337-.33h-.432c-.257 0-.345.073-.345.33v.432c0 .256.088.337.345.337zm2.431 0h.433c.256 0 .344-.08.344-.337v-.432c0-.257-.088-.33-.345-.33h-.432c-.256 0-.344.073-.344.33v.432c0 .256.088.337.344.337zm2.44 0h.424c.256 0 .344-.08.344-.337v-.432c0-.257-.088-.33-.344-.33h-.425c-.263 0-.344.073-.344.33v.432c0 .256.08.337.344.337z"
        ></path>
      </svg>
    </div>
  );
};

export default CalendarIcon;
