import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { VehicleFilterBlock } from '@app/pages/vehicle-park/vehicle-chart/blocks/vehicle-filter';
import { FilterPlotData, FilterRangeItem } from '@appTypes/search-data.type';
import { useGetEquipmentModelBulkQuery } from '@app/store/api/models.api';
import {
  formDefaults,
  PlotRangeItem,
} from '@app/pages/vehicle-park/vehicle-chart/blocks/vehicle-filter/data';
import {
  useCreateFilterWithEquipmentMutation,
  useFilterCreateDataMutation,
  useLazyFilterCheckQuery,
  useLazyFilterPlotDataQuery,
} from '@app/store/api/search-data.api';
import ChartBlock from '@app/pages/vehicle-park/vehicle-chart/blocks/chart.block';

import { useGetEquipmentByVehicleQuery } from '@app/store/api/equipment.api';

import { CurrentGraphParamsBlock, InfoGraphParamsBlock } from './blocks/info-graph-params';

const PLOT_LOCALSTORAGE_NAME = 'plot_info';

const VehicleChartPage = () => {
  const { vehicleId } = useParams();
  const [getPlotData] = useLazyFilterPlotDataQuery();
  const plotInfo = JSON.parse(localStorage.getItem(PLOT_LOCALSTORAGE_NAME) || '{}');
  const [selectedEquipment, setSelectedEquipment] = useState<string | null>(
    vehicleId === plotInfo.vehicleId ? plotInfo.equipmentId : null,
  );
  const [progress, setProgress] = useState<number>(0);
  const [range, setRange] = useState<PlotRangeItem>(plotInfo?.range || formDefaults['cd']);
  const [plot, setPlot] = useState<FilterPlotData | undefined>();
  const [checkFilter] = useLazyFilterCheckQuery();
  const [isSendFilterCreateRequest, changeIsSendFilterCreateRequest] = useState<boolean>(false);
  const [selectedChartLines, changeSelectedChartLines] = useState<Array<string>>([]);
  const [selectedIndexValues, changeSelectedIndexValues] = useState<string>('0');
  const { data: equipmentModelsList } = useGetEquipmentModelBulkQuery();
  const [createFilter] = useCreateFilterWithEquipmentMutation();
  const [createData] = useFilterCreateDataMutation();
  const equipmentByVehicle = useGetEquipmentByVehicleQuery(
    { vehicle_id: Number(vehicleId) },
    {
      skip: typeof vehicleId !== 'string',
      selectFromResult: ({ data }) => {
        return (
          {
            equipments: data?.equipments.filter(
              (equipment) => equipment.vehicle_id === Number(vehicleId),
            ),
          } || undefined
        );
      },
    },
  );
  function createFilterRequest({
    modelId,
    equipments,
    range,
    tables,
  }: {
    modelId: number;
    equipments: Array<number>;
    range: FilterRangeItem;
    tables: Array<string>;
  }) {
    if (!isSendFilterCreateRequest) {
      changeIsSendFilterCreateRequest(true);
      createFilter({
        model_id: modelId,
        equipments: equipments,
        body: {
          range: range,
          tables: tables.map((table) => ({
            name: table,
            time_delta: Number(table.split('_')[0]),
          })),
        },
      })
        .unwrap()
        .then(() => {
          setProgress(10);
          handleChangeStorage('modelId', modelId);
          handleChangeStorage('vehicleId', vehicleId);
          filterSubscriber(0, 10);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Не удалось создать фильтр');
          setProgress(0);
          changeIsSendFilterCreateRequest(false);
        });
    }
  }

  function filterSubscriber(equipmentId = 0, prevProgress = 0) {
    setTimeout(() => {
      checkFilter(null)
        .unwrap()
        .then((result) => {
          const currentEquipmentId =
            (result?.filter?.counts && Object.keys(result?.filter?.counts)[0]) || equipmentId;
          if (result?.filter?.status === 'counted') {
            createData({
              plot: true,
              csv: false,
              vehicle_equipment_id: Number(currentEquipmentId),
            }).then(() => {
              setProgress(50);
              filterSubscriber(Number(currentEquipmentId), 50);
            });
          } else if (result?.filter?.status === 'finished') {
            getPlotData(null)
              .unwrap()
              .then((plotResponse: FilterPlotData) => {
                const plotStorage = localStorage.getItem(PLOT_LOCALSTORAGE_NAME) || '{}';
                const plotState = JSON.parse(plotStorage);
                setPlot(plotResponse);
                changeIsSendFilterCreateRequest(false);
                setProgress(0);
                localStorage.setItem(
                  PLOT_LOCALSTORAGE_NAME,
                  JSON.stringify({
                    ...plotState,
                    vehicleId,
                    equipmentId: selectedEquipment,
                    plot: plotResponse,
                  }),
                );
              });
          } else {
            filterSubscriber(equipmentId, prevProgress);
          }
        });
    }, 3000);
  }
  function getRequestHandler(selectedEquipmentTab: number, range: FilterRangeItem) {
    const currentEquipmentModelId = equipmentByVehicle?.equipments?.find(
      (equipment) => equipment.vehicle_equipment_id === selectedEquipmentTab,
    )?.equipment_model_id;
    const currentModel = equipmentModelsList?.models.find(
      (model) => model.id === currentEquipmentModelId,
    );
    if (currentModel) {
      setSelectedEquipment(String(selectedEquipmentTab));
      localStorage.removeItem(PLOT_LOCALSTORAGE_NAME);
      handleChangeStorage('equipmentId', String(selectedEquipmentTab));
      handleChangeStorage('vehicleId', vehicleId);
      handleChangeStorage('range', range);
      createFilterRequest({
        modelId: Number(currentModel.id),
        equipments: [selectedEquipmentTab],
        tables: currentModel.clickhouse_structure.tables.map((currentTable) => currentTable.name),
        range,
      });
    }
  }

  function handleOnSubmitFilter(data: PlotRangeItem) {
    changeIsSendFilterCreateRequest(false);
    changeSelectedChartLines([]);
    const convertedData =
      data.type === 'r'
        ? {
            ...data,
            parameters: data.parameters.map((parameter) => ({
              ...parameter,
              value: `${parameter.value}:00`,
            })),
          }
        : data;
    localStorage.setItem('is_graph', 'true');
    setRange(convertedData);
    setPlot(undefined);
    handleChangeStorage('range', convertedData);
    selectedEquipment
      ? getRequestHandler(Number(selectedEquipment), convertedData)
      : toast.info('Выберите оборудование для поиска!');
  }

  function handleChangeStorage(key: string, data: any) {
    const storage = JSON.parse(localStorage.getItem(PLOT_LOCALSTORAGE_NAME) || '{}');
    localStorage.setItem(PLOT_LOCALSTORAGE_NAME, JSON.stringify({ ...storage, [key]: data }));
  }

  useEffect(() => {
    if (vehicleId !== handleGetStorage('vehicleId')?.vehicleId) {
      localStorage.removeItem(PLOT_LOCALSTORAGE_NAME);
      setPlot(undefined);
    }
  }, [vehicleId]);

  function handleGetStorage(keys: Array<string> | string): null | { [x: string]: string } {
    const storage = JSON.parse(localStorage.getItem(PLOT_LOCALSTORAGE_NAME) || '{}');
    const storageToReturn = {};
    if (Array.isArray(keys)) {
      for (const key in keys) {
        if (Object.keys(storage).includes(key)) {
          storageToReturn[key] = storage[key];
        } else {
          storageToReturn[key] = null;
        }
      }
    } else {
      storageToReturn[keys] = storage[keys] || null;
    }
    return storageToReturn;
  }
  const RenderInfoMessage = (props: { message: string }) => {
    return (
      <div className="w-full p-[5px]">
        <div className="bg-pastel_action text-[12px] rounded-[2px] p-[10px]">{props.message}</div>
      </div>
    );
  };
  return (
    <div>
      <VehicleFilterBlock
        blocked={isSendFilterCreateRequest}
        defaultServerFilter={range as PlotRangeItem}
        handleOnSubmitFilter={handleOnSubmitFilter}
      />
      <ul className="flex w-full mt- [10px]">
        {equipmentByVehicle?.equipments?.map((equipment, key) => (
          <li
            className={cn(
              Number(selectedEquipment) === equipment.vehicle_equipment_id && 'bg-white',
              'w-full p-[10px] rounded-t-[12px] block hover:bg-white transition duration-150 cursor-pointer',
            )}
            key={key}
            onClick={() => setSelectedEquipment(String(equipment.vehicle_equipment_id))}
          >
            {equipment.serial_number}
          </li>
        ))}
      </ul>
      {plot && (
        <div className="z-20">
          <ChartBlock
            chartData={plot}
            onChangeCurrentIndex={changeSelectedIndexValues}
            currentValueIndex={selectedIndexValues}
            currentShowSeries={selectedChartLines}
          />
        </div>
      )}
      {plot && !selectedChartLines?.length && (
        <RenderInfoMessage message="Выберите поле для отображения" />
      )}
      {!plot && progress < 1 && <RenderInfoMessage message="График не рассчитан" />}
      {progress > 0 && progress < 100 && (
        <div className="py-[5px] mb-[10px] w-full h-[30px]">
          <div
            className="bg-basic_orange flex animate-pulse items-center text-white justify-end border-[1px] border-red_border h-[30px] rounded-[3px] text-[9px]"
            style={{ width: `${progress}%` }}
          >
            график <b className="pl-[5px]">{Math.ceil(progress)}%</b>
          </div>
        </div>
      )}
      <div className="mt-[8px]">
        <div>
          <div>
            {equipmentModelsList && plot?.items && (
              <InfoGraphParamsBlock
                defaultSelect={selectedChartLines}
                onSelect={(selectedLines) => changeSelectedChartLines(selectedLines)}
                graphDataInfo={plot.items}
                currentActiveIndex={selectedIndexValues}
              />
            )}
          </div>
          <div className="mt-[8px]">
            {plot && (
              <CurrentGraphParamsBlock
                params={plot.items}
                currentActiveIndex={selectedIndexValues}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleChartPage;
