import { FilterCondition } from '@appTypes/search-data.type';
import moment from 'moment';
export type FilterPlotType = 'r' | 'l' | 'cd' | 'cm' | 'cw';

export type DaysData = Array<{
  label: string;
  value: FilterPlotType;
}>;
export const daysData: DaysData = [
  { label: 'today', value: 'cd' },
  { label: 'yesterday', value: 'l' },
  { label: 'week', value: 'cw' },
  { label: 'month', value: 'cm' },
];
export type PlotRangeItem = {
  type: FilterPlotType;
  parameters: Array<{
    value: string;
    condition?: FilterCondition;
  }>;
};
const emptyParameters = { parameters: [] };
export const formDefaults: Record<FilterPlotType, PlotRangeItem> = {
  r: {
    type: 'r',
    parameters: [
      {
        value: `${moment(moment().toDate()).format('yyyy-MM-DD')} 00:00`,
        condition: '>',
      },
      {
        value: `${moment(moment().toDate()).format('yyyy-MM-DD')} 00:00`,
        condition: '<',
      },
    ],
  },
  l: {
    type: 'l',
    parameters: [{ value: '1' }],
  },
  cd: {
    type: 'cd',
    ...emptyParameters,
  },
  cw: {
    type: 'cw',
    ...emptyParameters,
  },
  cm: {
    type: 'cm',
    ...emptyParameters,
  },
};
