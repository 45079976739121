import { TableColumn } from '@app/components/ui/table/types/table.type';

export const titlesData: TableColumn[] = [
  { index: 'name', title: '.name' },
  {
    index: 'install_time',
    title: '.editDate',
  },
  {
    index: 'status',
    title: '.status',
  },
  {
    index: 'phone_number',
    title: '.phoneNumber',
  },
  {
    index: 'contact_full_name',
    title: '.fullName',
  },
  {
    index: 'utils',
    title: '',
    tag: 'utils',
    divider: 'left',
    maxWidth: 100,
  },
];
