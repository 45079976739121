import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const VerticalDotsIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" height={size} fill="none" viewBox="0 0 3 13">
        <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor"></circle>
        <circle cx="1.5" cy="6.5" r="1.5" fill="currentColor"></circle>
        <circle cx="1.5" cy="11.5" r="1.5" fill="currentColor"></circle>
      </svg>
    </div>
  );
};

export default VerticalDotsIcon;
