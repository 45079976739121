import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const ExclamationMark: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 17"
      >
        <path
          fill="currentColor"
          d="M3.674 16.88h10.643c1.908 0 2.892-.983 2.892-2.864V3.319c0-1.88-.984-2.865-2.892-2.865H3.674C1.775.454.782 1.43.782 3.32v10.697c0 1.88.993 2.865 2.892 2.865zm.114-1.748c-.809 0-1.257-.422-1.257-1.275V3.477c0-.852.448-1.274 1.257-1.274h10.415c.8 0 1.257.422 1.257 1.275v10.38c0 .852-.457 1.274-1.257 1.274H3.788zM9 10.105c.483 0 .756-.273.773-.783l.132-4.227c.018-.519-.378-.888-.914-.888-.545 0-.923.36-.905.879l.123 4.245c.018.501.299.774.791.774zm0 2.918c.571 0 1.037-.414 1.037-.967 0-.563-.466-.967-1.037-.967s-1.037.413-1.037.967c0 .553.474.967 1.037.967z"
        ></path>
      </svg>
    </div>
  );
};

export default ExclamationMark;
