import React, { FC, useRef, MouseEvent } from 'react';
import cn from 'classnames';

import Button from '../button';

import useClickOutside from '../../../hooks/use-click-outside';

import { TrashIcon, XIcon } from '../icons/icons-list';

import { ModalProps, ModalVariant } from './types/modal.type';

import { easyModalClassName } from './styles/modal.styles';

const Modal: FC<ModalProps> = (props) => {
  const {
    show,
    onClose,
    children,
    label,
    variant = 'medium',
    onRemove,
    onSuccess,
    failText,
    successText,
    removeText,
    withoutFooter = variant === 'removal',
    withoutContentPadding = false,
    withoutLabel = false,
    isAutoClosable = true,
    noOverflowContent,
    isNotContentClosable,
    customLayer,
    disabled = false,
  } = props;
  // const { t } = useTranslation('components.easyModal');
  const modalRef = useRef(null);
  const variantSizes: Record<ModalVariant, string> = {
    small: 'max-w-[600px]',
    smallW230: 'max-w-[230px]',
    smallW300: 'max-w-[300px]',
    medium: 'max-w-[879px]',
    mediumW650: 'max-w-[650px]',
    large: 'max-w-[1032px]',
    largeW1127: 'max-w-[1127px]',
    removal: 'max-w-[484px]',
    medium484: 'max-w-[484px]',
  };

  const modalFooterClassName = cn(
    // 'h-[92px]',
    'px-[40px]',
    'py-[40px]',
    'w-full',
    'flex',
    'items-center',
    'justify-between',
  );

  useClickOutside(modalRef, () => !isNotContentClosable && onClose?.());

  function handlerClose() {
    onClose?.();
  }
  function handlerRemove() {
    onRemove?.();
    handlerClose();
  }

  function handlerSuccess(event: MouseEvent<HTMLButtonElement>) {
    onSuccess?.(event, handlerClose);
    isAutoClosable && handlerClose();
  }

  const renderModalFooter = !withoutFooter ? (
    <div className={modalFooterClassName}>
      <div className="flex items-center gap-[18px]">
        {onSuccess && (
          <Button
            disabled={disabled}
            size="medium"
            label={successText || 'Сохранить'}
            onClick={(event) => handlerSuccess(event)}
            className={'!py-[20px] !px-[33px]'}
          />
        )}
        {onClose && (
          <Button
            size="medium"
            fill="linked"
            label={failText || 'Отменить'}
            onClick={handlerClose}
          />
        )}
      </div>
      <div>
        {onRemove && (
          <Button
            size="medium"
            variant="danger"
            onClick={handlerRemove}
            fill="linked"
            icon="TrashIcon"
            label={removeText || 'Удалить'}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );

  return (
    <div
      className={easyModalClassName({ show })}
      style={customLayer ? { zIndex: customLayer } : { zIndex: 9998 }}
    >
      <div
        ref={modalRef}
        className={cn(
          'w-full',
          'ease-in-out',
          'duration-150',
          'relative',
          'inline-flex',
          'flex-col',
          'max-h-[calc(100%-100px)]',
          show ? 'scale-10 visible' : 'scale-0 invisible',
          'z-50',
          'bg-white',
          'rounded-defaultR',
          'min-h-[150px]',
          variantSizes[variant],
        )}
      >
        {!withoutLabel && (
          <div className="inline-flex w-full mt-[16px] justify-between">
            <div className="w-full">
              {variant === 'removal' ? (
                <div className="flex justify-center flex-col items-center pt-[35px]">
                  <div className="w-[57px] h-[57px] rounded-full flex justify-center items-center bg-basic_red/[.1]">
                    <TrashIcon size={25} className="text-basic_red" />
                  </div>
                  {label && (
                    <div className="text-0color px-[42px] mt-[24px] text-[24px] font-[700]">
                      {label}
                    </div>
                  )}
                </div>
              ) : (
                label && (
                  <div className="text-0color px-[42px] mt-[24px] text-[24px] font-[700]">
                    {label}
                  </div>
                )
              )}
            </div>
            <div onClick={onClose?.bind(null)}>
              <XIcon className="relative top-[0px] cursor-pointer right-[15px]" size={14} />
            </div>
          </div>
        )}
        <div
          className={cn(
            'h-fit max-h-[calc(100%-200px)] w-full inline-flex',
            !noOverflowContent && 'overflow-hidden overflow-y-auto z-50',
          )}
        >
          <div className={`w-full h-full ${withoutContentPadding ? '' : 'px-[70px] mt-[12px]'} `}>
            {children}
            {variant === 'removal' && (
              <div className="flex flex-col items-center py-[40px]">
                <Button
                  label={'Удалить'}
                  variant="danger"
                  className="w-[228px] h-[44px] mb-[13px]"
                  onClick={handlerRemove}
                />
                <Button
                  label={'Отменить'}
                  fill="linked"
                  className="mb-[26px]"
                  onClick={handlerClose}
                />
              </div>
            )}
          </div>
        </div>
        {renderModalFooter}
      </div>
    </div>
  );
};

export default Modal;
