import { createApi } from '@reduxjs/toolkit/query/react';

import {
  CurrentVehicleData,
  CurrentVehicleList,
  VehicleCreateResponse,
  VehicleItem,
  VehicleItemsList,
} from '@app/store/interfaces/vehicle.type';
import { endpointUrl } from '@app/utils/endpoint-url';

import { CommonPagination } from '@appTypes/common-pagination.type';

import customFetchBase from '../customFetchBase';

const vehicleApi = createApi({
  reducerPath: 'vehicleApi',
  baseQuery: customFetchBase,
  tagTypes: ['Vehicle', 'Vehicles', 'VehicleAdministration', 'CurrentVehicles'],
  endpoints: (builder) => ({
    getCurrentVehicle: builder.query<CurrentVehicleData, number>({
      query(vehicle_id) {
        return {
          url: endpointUrl.currentVehicle,
          params: {
            vehicle_id: vehicle_id,
          },
        };
      },
    }),
    // Получение карточки оборудования транспортного средства
    getCurrentVehicles: builder.query<CurrentVehicleList, CommonPagination>({
      query(params) {
        return {
          url: endpointUrl.currentVehicles,
          params,
        };
      },
      providesTags: ['CurrentVehicles'],
    }),
    // Получение карточки транспортного средства { vehicle_id: string }
    getVehicle: builder.query<VehicleItem, { vehicle_id: number }>({
      query(params) {
        return {
          url: endpointUrl.vehicle,
          params,
        };
      },
    }),
    VehicleImage: builder.query<Blob, { model_id: number }>({
      query(params) {
        return {
          url: endpointUrl.imageVehicles,
          params,
          headers: {
            'content-type': 'application/octet-stream',
          },
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          cache: 'no-cache',
        };
      },
    }),
    // Получение списка транспортных средства, с учётом пагинации
    getVehicles: builder.query<VehicleItemsList, CommonPagination>({
      query(params) {
        return {
          url: endpointUrl.vehicles,
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.vehicles.map(({ vehicle_id }) => ({
                type: 'Vehicles' as const,
                vehicle_id,
              })),
              'Vehicles',
            ]
          : ['Vehicles'],
    }),
    // Удаление транспортного средства
    removeVehicle: builder.mutation<{ status: number }, { vehicle_id: number }>({
      query(params) {
        return {
          url: endpointUrl.vehicle,
          method: 'DELETE',
          params,
        };
      },
      invalidatesTags: ['Vehicles'],
    }),
    // Добавление транспортного средства
    addVehicle: builder.mutation<VehicleItem, VehicleCreateResponse>({
      query(body) {
        return {
          url: endpointUrl.vehicle,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Vehicles'],
    }),
    // Редактирование данных транспортного средства
    editVehicle: builder.mutation<
      VehicleItem,
      { params: { vehicle_id: number }; body: VehicleCreateResponse }
    >({
      query({ body, params }) {
        return {
          url: endpointUrl.vehicle,
          method: 'PUT',
          body,
          params,
        };
      },
      invalidatesTags: ['Vehicles'],
    }),
  }),
});

export const {
  useVehicleImageQuery,
  useGetVehicleQuery,
  useAddVehicleMutation,
  useGetCurrentVehiclesQuery,
  useEditVehicleMutation,
  useGetVehiclesQuery,
  useRemoveVehicleMutation,
  useLazyGetCurrentVehiclesQuery,
  useLazyGetVehicleQuery,
  useLazyGetVehiclesQuery,
  useGetCurrentVehicleQuery,
} = vehicleApi;
export default vehicleApi;
