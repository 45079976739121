import React, { FC } from 'react';

import { IconType } from '../icon.type';

const FolderMediaIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 26 22">
        <path
          fill="currentColor"
          d="M3.769 21.1h18.788c2.01 0 3.19-1.168 3.19-3.481V6.085c0-2.314-1.19-3.481-3.527-3.481H11.44c-.764 0-1.224-.18-1.786-.663l-.685-.562C8.228.75 7.654.55 6.542.55h-3.2c-1.966 0-3.1 1.122-3.1 3.38v13.69c0 2.324 1.18 3.481 3.527 3.481zM2.05 4.03c0-1.112.584-1.685 1.662-1.685h2.37c.741 0 1.19.191 1.763.674l.685.573c.73.606 1.325.82 2.437.82h11.22c1.111 0 1.752.606 1.752 1.774v.64H2.05V4.03zm1.741 15.262c-1.123 0-1.74-.595-1.74-1.763V8.522h21.887v9.018c0 1.157-.64 1.752-1.752 1.752H3.791z"
        ></path>
        <path
          fill="currentColor"
          d="M16.55 13.24a.3.3 0 010 .52l-5.85 3.377a.3.3 0 01-.45-.26v-6.754a.3.3 0 01.45-.26l5.85 3.377z"
        ></path>
      </svg>
    </div>
  );
};

export default FolderMediaIcon;
