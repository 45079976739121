import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const SaveIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
      >
        <path
          fill="currentColor"
          d="M29.121 8.121L23.88 2.88A3 3 0 0021.757 2H5a3 3 0 00-3 3v22a3 3 0 003 3h22a3 3 0 003-3V10.243a3 3 0 00-.879-2.122zM20 4v6H8V4h12zm8 23c0 .551-.449 1-1 1H5c-.551 0-1-.449-1-1V5c0-.551.449-1 1-1h1v6.5A1.5 1.5 0 007.5 12h13a1.5 1.5 0 001.5-1.5V4.03a.993.993 0 01.465.263l5.242 5.243a.993.993 0 01.293.707V27zM16 14.5a5.506 5.506 0 00-5.5 5.5c0 3.033 2.467 5.5 5.5 5.5s5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5zm0 9c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"
        ></path>
      </svg>
    </div>
  );
};

export default SaveIcon;
