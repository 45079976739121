import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ColsWithoutTable, Input, Switch } from '@ui';
import { EquipmentMessageSettings } from '@appTypes/message.type';
import { CustomFormResponseType } from '@app/pages/emergency-information/information-settings/information-settings.page';
import AllowComponentPermission from '@app/hocs/allow-component-permission';
type InformationTableBlockPropsType = {
  tableData: {
    field: string;
    name: string;
    minimal_parameter_value?: number | boolean;
    maximal_parameter_value?: number | boolean;
  }[];
  handleOnSaveSettings(data: CustomFormResponseType): void;
  currentMessagesSettings: EquipmentMessageSettings | undefined;
  filterParametersValues: { [p: string]: (number | boolean)[] } | undefined;
  changeFilterParametersValues: React.Dispatch<
    React.SetStateAction<{ [p: string]: (number | boolean)[] } | undefined>
  >;
};
const InformationTableBlock: FC<InformationTableBlockPropsType> = (props) => {
  const {
    tableData,
    handleOnSaveSettings,
    changeFilterParametersValues,
    filterParametersValues,
    currentMessagesSettings,
  } = props;
  const { t } = useTranslation();
  const [switchState, changeSwitchState] = useState<CustomFormResponseType>({
    output_error: currentMessagesSettings?.output_error || false,
    output_warning: currentMessagesSettings?.output_warning || false,
  });
  function getInputDefaultValue(value: number | boolean) {
    if (!value) return;
    return typeof value === 'boolean' ? '' : value;
  }

  const tableColumnsData = [
    {
      index: 'name',
      title: t('pages.informationSettings.table.parameter_name'),
      className: 'w-[25%]',
    },
    {
      index: 'minimal_parameter_value',
      title: t('pages.informationSettings.table.minimal_parameter_value'),
      className: 'w-[25%]',
    },
    {
      index: 'maximal_parameter_value',
      title: t('pages.informationSettings.table.maximal_parameter_value'),
      className: 'w-[25%]',
    },
    {
      index: 'switch_info',
      title: t('pages.informationSettings.table.switch_info'),
      className: 'w-[25%]',
    },
  ];
  function onInputChangeMax(value: number | boolean, field: string) {
    changeFilterParametersValues((prev) => ({
      ...prev,
      [field]: [prev?.[field][0] || false, value],
    }));
  }
  function onInputChangeMin(value: number | boolean, field: string) {
    changeFilterParametersValues((prev) => ({
      ...prev,
      [field]: [value, prev?.[field][1] || false],
    }));
  }
  useEffect(() => {
    if (!currentMessagesSettings) return;
    changeSwitchState({
      output_error: currentMessagesSettings?.output_error,
      output_warning: currentMessagesSettings?.output_warning,
    });
  }, [currentMessagesSettings]);
  return (
    <div className="flex flex-col">
      <div>
        {!!tableData.length && (
          <>
            <ColsWithoutTable columns={tableColumnsData} />
            <div
              className={'shadow-sm  rounded-[10px] bg-white h-[46px] flex items-center mb-[1px]'}
            >
              <div className={'flex items-center justify-between w-full px-[20px]'}>
                <div>{t('pages.informationSettings.table.output_error')}</div>
                <div className={'pr-[100px]'}>
                  <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
                    <Switch
                      size={'medium'}
                      onChecked={(status) =>
                        changeSwitchState((prev) => ({ ...prev, output_error: status }))
                      }
                      checked={switchState?.output_error}
                    />
                  </AllowComponentPermission>
                </div>
              </div>
            </div>
            <div
              className={'shadow-sm  rounded-[10px] bg-white h-[46px] flex items-center mb-[1px]'}
            >
              <div className={'flex items-center justify-between w-full px-[20px]'}>
                <div>{t('pages.informationSettings.table.output_warning')}</div>
                <div className={'pr-[100px]'}>
                  <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
                    <Switch
                      size={'medium'}
                      onChecked={(status) =>
                        changeSwitchState((prev) => ({ ...prev, output_warning: status }))
                      }
                      checked={switchState?.output_warning}
                    />
                  </AllowComponentPermission>
                </div>
              </div>
            </div>
          </>
        )}
        {filterParametersValues &&
          tableData.map((tableItem, index) => (
            <div
              key={tableItem.field + index}
              className={'shadow-sm  rounded-[10px] bg-white h-[46px] flex items-center mb-[1px]'}
            >
              <div className={'w-full flex items-center gap-[25px]'}>
                <div className={'w-[22%] pl-[20px] truncate'}>{tableItem.name}</div>
                <div className={'w-[22%] pl-[20px] truncate'}>
                  <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
                    <Input
                      defaultValue={
                        getInputDefaultValue(filterParametersValues?.[tableItem.field]?.[0]) || ''
                      }
                      onChange={(event) => {
                        const value = event.target.value ? Number(event.target.value) : false;
                        onInputChangeMin(value, tableItem.field);
                      }}
                    />
                  </AllowComponentPermission>
                </div>
                <div className={'w-[22%] pl-[20px] truncate'}>
                  <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
                    <Input
                      defaultValue={
                        getInputDefaultValue(filterParametersValues?.[tableItem.field]?.[1]) || ''
                      }
                      onChange={(event) => {
                        const value = event.target.value ? Number(event.target.value) : false;
                        onInputChangeMax(value, tableItem.field);
                      }}
                    />
                  </AllowComponentPermission>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="mt-[44px] flex justify-between">
        <div className="flex items-center mr-[10px] gap-[10px]">
          <AllowComponentPermission permissionKey={'NoChanges04'} type={'disabled'}>
            <Button
              onClick={() =>
                handleOnSaveSettings({
                  output_error: switchState.output_error,
                  output_warning: switchState.output_warning,
                })
              }
              label={t('pages.informationSettings.button.save')}
            />
          </AllowComponentPermission>
        </div>
      </div>
    </div>
  );
};

export default InformationTableBlock;
