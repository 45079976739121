import React, { useEffect, useState, useRef, FC } from 'react';
import cn from 'classnames';

import { ChevronDownV2Icon } from '../icons/icons-list';
import { OptionItem, OptionsPosition, SelectVariant } from '../select/types/select.type';
import SelectItem from '../select/select-item';

import useClickOutside from '../../../hooks/use-click-outside';

import {
  optionValueClassName,
  selectOptionsWrapper,
  selectWrapperClassName,
} from './styles/select.styles';

type SelectProps = {
  defaultValue?: string | number | string[];
  currentValue?: string | number;
  isEmpty?: boolean;
  size?: number;
  placeholder?: string;
  title?: string;
  dropTop?: boolean;
  position?: OptionsPosition;
  variant?: SelectVariant;
  options: Array<OptionItem>;
  onChange?: (value: OptionItem['value']) => void;
  onChangeMultiple?: (value: OptionItem['value'][]) => void;
  dropHeight?: number;
  dropWidth?: number;
  disable?: boolean;
  isMulti?: boolean;
  error?: string;
  tooltip?: { content: string; place?: 'left' | 'right' | 'top' | 'bottom'; id: string };
};

const Select: FC<SelectProps> = (props) => {
  const {
    currentValue,
    defaultValue,
    position = 'left',
    dropTop = false,
    isEmpty,
    options,
    size,
    title,
    variant = 'medium',
    placeholder,
    onChange,
    dropHeight = 180,
    dropWidth,
    disable = false,
    tooltip,
    isMulti = false,
    error,
    onChangeMultiple,
  } = props;
  const [isShowOptions, changeIsShowOptions] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<OptionItem['value'] | OptionItem['value'][]>(
    defaultValue || '' || [],
  );
  const optionsRef = useRef<HTMLDivElement>(null);

  const defaultEmptyOption: OptionItem = { title: placeholder || '', value: '' };
  const resizeSelectStyle = size ? { width: `${size}px` } : {};

  if (title && defaultValue && options[0].title !== title)
    options.unshift({ title, value: defaultValue || defaultValue[0] || '' });

  function findActiveOptionByValue(value: OptionItem['value']) {
    return options.find((option) => option.value === value);
  }

  function handleChangeValue(value: OptionItem['value']) {
    if (isMulti) {
      let newValuesArray;
      if ((activeOption as (string | number)[]).includes(value)) {
        newValuesArray = (activeOption as (string | number)[]).filter((item) => item !== value);
      } else {
        newValuesArray = [...(activeOption as (string | number)[]), value];
      }
      setActiveOption(newValuesArray);
      onChangeMultiple?.(newValuesArray);
    } else {
      setActiveOption(value);
      onChange?.(value);
    }
  }
  function toggleShowOptions() {
    changeIsShowOptions(!isShowOptions);
  }

  useEffect(() => {
    if (defaultValue && !currentValue) setActiveOption(defaultValue);
  }, [currentValue, defaultValue, options]);

  useClickOutside(optionsRef, () => changeIsShowOptions(false));

  const renderOptions = options.map((option, index) => {
    const activeItem = isMulti
      ? (activeOption as (string | number)[]).includes(option.value)
      : activeOption === option.value;
    return (
      <SelectItem
        key={index}
        onChange={handleChangeValue.bind(null, option.value)}
        active={activeItem}
        option={option}
      />
    );
  });
  const renderSelectedValue = () => {
    const activeOptionData = findActiveOptionByValue(activeOption as string | number);
    if (activeOptionData?.title) {
      return (
        <span
          className={cn(
            (findActiveOptionByValue(activeOption as string | number)?.title === '' ||
              activeOption === '') &&
              'text-b_dark',
          )}
        >
          {activeOptionData.title}
        </span>
      );
    } else return <span className="text-4color">{placeholder}</span>;
  };
  return (
    <div
      ref={optionsRef}
      className={selectWrapperClassName({ variant, disable, error: !!error })}
      onClick={disable ? undefined : toggleShowOptions}
      style={resizeSelectStyle}
    >
      <div className="inline-flex h-full relative items-center justify-between w-full">
        {tooltip ? (
          <div className={optionValueClassName({ variant })}>
            {isMulti && (activeOption as (string | number)[]).length
              ? (activeOption as (string | number)[])?.map?.((option, index) => {
                  return (
                    <span key={index}>{options.find((item) => item.value === option)?.title}</span>
                  );
                })
              : renderSelectedValue()}
          </div>
        ) : (
          <div className={optionValueClassName({ variant })}>
            {isMulti && (activeOption as (string | number)[]).length
              ? (activeOption as (string | number)[])?.map?.((option, index) => {
                  return (
                    <span className="mr-[5px]" key={index}>
                      {options.find((item) => item.value === option)?.title}
                    </span>
                  );
                })
              : renderSelectedValue()}
          </div>
        )}
        <div className="w-[24px] text-center items-center inline-flex justify-center">
          <ChevronDownV2Icon
            size={13}
            className={cn('text-3color transition mr-[6px]', isShowOptions && 'rotate-180')}
          />
        </div>
      </div>
      {isShowOptions && (
        <div
          className={selectOptionsWrapper({ dropTop, position })}
          style={
            dropWidth
              ? { width: `${dropWidth}px` }
              : { width: `${(optionsRef?.current?.offsetWidth as number) - 10}px` }
          }
        >
          <div
            onClick={(e) => isMulti && e.stopPropagation()}
            // ref={!isMulti ? undefined : optionsRef}
            className={cn(
              'select_scrollbar mediumScrollBar',
              ' max-h-[160px] my-[8px] min-h-[34px] mx-[3px] overflow-hidden overflow-y-auto',
            )}
            style={{ maxHeight: `${dropHeight}px` }}
          >
            <ul>
              {isEmpty && (
                <SelectItem
                  active={activeOption === ''}
                  onChange={handleChangeValue.bind(null, '')}
                  option={defaultEmptyOption}
                />
              )}
              {renderOptions}
            </ul>
          </div>
        </div>
      )}
      {!!error && true && (
        <div className="mt-[1px] absolute text-sm text-basic_red dark:text-red-500">{error}</div>
      )}
    </div>
  );
};
export default Select;
