import React, { FC } from 'react';

import { IconType } from '../icon.type';

const EditIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="currentColor"
          d="M1.704 13.16h.937c.225 0 .3-.042.45-.158l.459-.349c.28.253.656.301 1.039.137l2.256-.95c.17-.075.266-.144.383-.26l6.63-6.59c.67-.67.67-1.483 0-2.153l-1.51-1.518c-.67-.67-1.484-.676-2.154-.013l-6.63 6.59a1.145 1.145 0 00-.26.39l-.957 2.255c-.164.376-.123.745.13 1.032l-1.005 1.04c-.22.232-.117.546.232.546zm9.297-10.863c.17-.164.362-.164.52-.007l1.367 1.374c.157.157.15.349-.02.513l-.411.417-1.873-1.88.417-.417zM4.869 8.4l4.915-4.887 1.873 1.873-4.915 4.894-1.873-1.88zm-.444 3.083c-.116.048-.205.048-.315-.061l-.403-.403c-.103-.11-.103-.199-.055-.315l.575-1.347 1.551 1.559-1.353.567zm-3.001 4.102h13.145a.643.643 0 000-1.285H1.424a.639.639 0 00-.63.642c0 .342.288.643.63.643z"
        ></path>
      </svg>
    </div>
  );
};

export default EditIcon;
