import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LockCircle: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 19"
      >
        <path
          fill="currentColor"
          d="M10 18.733c4.966 0 9.08-4.104 9.08-9.079 0-4.966-4.114-9.079-9.089-9.079-4.966 0-9.07 4.113-9.07 9.08 0 4.974 4.113 9.078 9.079 9.078zm0-1.784a7.27 7.27 0 01-7.286-7.295A7.255 7.255 0 019.99 2.368a7.277 7.277 0 017.304 7.286A7.277 7.277 0 0110 16.95zm-3.155-3.876c0 .668.299.976.914.976h4.473c.616 0 .915-.308.915-.976V9.637c0-.598-.247-.906-.748-.95V7.687c0-1.618-.966-2.699-2.408-2.699-1.432 0-2.408 1.081-2.408 2.699v.993c-.492.052-.738.36-.738.958v3.436zM8.61 8.661V7.598c0-.967.554-1.609 1.38-1.609.835 0 1.389.633 1.389 1.609V8.66H8.61z"
        ></path>
      </svg>
    </div>
  );
};

export default LockCircle;
