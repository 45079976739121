import React, { FC, ReactNode, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Input } from '@ui';
type CustomDatePickerPropsType = {
  intervalPicker?: boolean;
  maxDate?: Date;
  minDate?: Date;
  customInput?: ReactNode;
  disabled?: boolean;
  isFull?: boolean;
  onChange?(date: string): void;
  inputClassName?: string;
  format?: string;
  selectedDate?: Date;
};
const CustomDatePicker: FC<CustomDatePickerPropsType> = (props) => {
  const {
    selectedDate = moment().toDate(),
    intervalPicker = false,
    maxDate,
    onChange,
    minDate,
    isFull,
    customInput,
    disabled = false,
    inputClassName,
    format = 'yyyy.MM.DD',
  } = props;
  const [startDate, setStartDate] = useState(selectedDate);
  const [endDate, setEndDate] = useState(null);
  const onChangePicker = (dates) => {
    intervalPicker ? onIntervalChangeHandler(dates) : onOneDateChange(dates);
  };
  function onIntervalChangeHandler(dates) {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  function onOneDateChange(dates) {
    setStartDate(dates);
    const date = moment(dates).format(format);
    onChange && onChange(date);
  }
  function getInputDefaultValue() {
    return intervalPicker
      ? `${moment(startDate).format(format)} ${
          endDate ? '- ' + moment(endDate).format(format) : ''
        }`
      : String(moment(startDate).format(format));
  }
  const rangeDateProps = intervalPicker && {
    startDate: startDate,
    endDate: endDate,
    selectsRange: true,
  };
  const inputProps = {
    disabled: disabled,
    datePickerMode: !disabled,
  };
  return (
    <div>
      <DatePicker
        {...rangeDateProps}
        showYearDropdown
        showMonthDropdown
        showDisabledMonthNavigation
        dropdownMode="select"
        onChange={onChangePicker}
        selected={moment(startDate).toDate()}
        maxDate={maxDate}
        minDate={minDate}
        customInput={
          customInput ? (
            customInput
          ) : (
            <div
              className={`flex items-center ${isFull ? 'w-full' : 'w-[214px]'} ${
                inputClassName || ''
              }`}
            >
              <Input
                {...inputProps}
                name="dateStart"
                icon="CalendarEmptyIcon"
                defaultValue={getInputDefaultValue()}
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default CustomDatePicker;
