import React, { FC, useState } from 'react';
import { Button, CustomDatePicker, Input } from '@ui';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  daysData,
  FilterPlotType,
  formDefaults,
  PlotRangeItem,
} from '@app/pages/vehicle-park/vehicle-chart/blocks/vehicle-filter/data';
import moment from 'moment/moment';
import { FilterRangeItem } from '@appTypes/search-data.type';

type VehicleFilterBlockPropsType = {
  handleOnSubmitFilter(data: FilterRangeItem): void;
  defaultServerFilter?: PlotRangeItem;
  blocked: boolean;
};
const VehicleFilterBlock: FC<VehicleFilterBlockPropsType> = (props) => {
  const { handleOnSubmitFilter, defaultServerFilter, blocked } = props;
  const [currentRange, changeCurrentRange] = useState<FilterPlotType>(
    defaultServerFilter?.type || 'cd',
  );
  const { t } = useTranslation();
  const { handleSubmit, getValues, watch, reset, setValue } = useForm<FilterRangeItem>({
    defaultValues: formDefaults[currentRange],
  });
  const fieldsDescriptionClassName = cn(
    'whitespace-nowrap',
    'font-[500]',
    'text-[15px]',
    'leading-[16.4px]',
    'text-1color',
  );
  const daysClassName = ({ isActive }) =>
    cn(
      isActive
        ? 'bg-gradient-to-b from-white to-gray text-1color'
        : 'text-[#66788C] cursor-pointer',
      'p-[7.5px_15px]',
      'text-[15px]',
      'leading-[19px]',
      'rounded-defaultR',
      'transition-all',
    );
  function onSubmit(data: FilterRangeItem) {
    handleOnSubmitFilter(data);
  }

  function changeDefaultFormValue(range: FilterPlotType) {
    if (getValues('type') !== range) {
      reset(formDefaults[range]);
      changeCurrentRange(range);
    }
  }
  function onChangeRParametersHandler({
    value,
    type,
    position,
  }: {
    value: string;
    type: 'time' | 'date';
    position: number;
  }) {
    if (currentRange !== 'r') changeDefaultFormValue('r');
    const typesList = ['date', 'time'];
    const rangeValue = getValues(`parameters.${position}.value`)?.split(' ') || [
      moment(moment().toDate()).format('YYYY-MM-DD'),
      position > 0 ? '0100' : '0000',
    ];
    rangeValue[typesList.indexOf(type)] = value;
    setValue(`parameters.${position}.value`, rangeValue.join(' '));
  }
  console.log(watch());
  return (
    <form className={'pb-[20px]'} onSubmit={handleSubmit(onSubmit)}>
      <div
        className={'w-full h-[75px] rounded-[10px] border-solid border-[1px] border-b_dark flex'}
      >
        <div
          className={
            'flex items-center px-[15px] w-[400px] gap-[10px] border-r-[1px] border-solid border-b_dark'
          }
        >
          <div className={'flex items-center justify-center'}>
            {daysData.map((day, key) => (
              <div
                className={daysClassName({ isActive: day.value === currentRange })}
                key={key}
                onClick={() => changeDefaultFormValue(day.value)}
              >
                {t(`pages.vehiclePark.filter.days.${day.label}`)}
              </div>
            ))}
          </div>
        </div>
        <div
          onClick={changeDefaultFormValue.bind(null, 'r')}
          className={
            'flex items-center px-[15px] min-w-[510px] gap-[10px] border-r-[1px] border-solid border-b_dark'
          }
        >
          <span className={fieldsDescriptionClassName}>
            {t('pages.vehiclePark.filter.fromField')}
          </span>
          <div className={'flex items-center gap-[2px]'}>
            <CustomDatePicker
              selectedDate={moment(
                getValues('parameters.0.value')?.split(' ')[0] || moment(),
              ).toDate()}
              format="yyyy-MM-DD"
              inputClassName={'w-[122px]'}
              onChange={(value) => onChangeRParametersHandler({ value, position: 0, type: 'date' })}
              isFull
            />
            <div className={'w-[140px]'}>
              <Input
                icon={'ClockIcon'}
                type={'text'}
                defaultValue={watch('parameters.0.value')?.split(' ')[1] || '0000'}
                onChange={(event) =>
                  onChangeRParametersHandler({
                    value: event.target.value,
                    position: 0,
                    type: 'time',
                  })
                }
                mask={'99:99'}
              />
            </div>
          </div>
          <span className={fieldsDescriptionClassName}>
            {t('pages.vehiclePark.filter.beforeField')}
          </span>
          <div className={'flex items-center gap-[2px]'}>
            <CustomDatePicker
              inputClassName={'w-[122px]'}
              format="yyyy-MM-DD"
              selectedDate={moment(
                getValues('parameters.1.value')?.split(' ')[0] || moment(),
              ).toDate()}
              onChange={(value) => onChangeRParametersHandler({ value, position: 1, type: 'date' })}
              isFull
            />
            <div className={'w-[140px]'}>
              <Input
                icon={'ClockIcon'}
                type={'text'}
                defaultValue={watch('parameters.1.value')?.split(' ')[1] || '0100'}
                onChange={(event) =>
                  onChangeRParametersHandler({
                    value: event.target.value,
                    position: 1,
                    type: 'time',
                  })
                }
                mask={'99:99'}
              />
            </div>
          </div>
        </div>
        <div
          className={'flex w-full items-center justify-center max-w-[200px] px-[15px] gap-[10px]'}
        >
          <Button
            disabled={blocked}
            type={'submit'}
            label={t('pages.vehiclePark.filter.confirm')}
          />
        </div>
      </div>
    </form>
  );
};

export default VehicleFilterBlock;
