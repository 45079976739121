import { ExtendedUser } from '@app/store/interfaces/user.type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserState = {
  user: ExtendedUser | null;
  error: null | string;
};

const initialState: UserState = {
  user: null,
  error: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    setUser: (state, action: PayloadAction<ExtendedUser>) => {
      state.user = action.payload;
      state.error = null;
    },
    logOut: (state) => {
      state.user = null;
    },
    setUserError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { setUserError, setUser, logOut } = userSlice.actions;
