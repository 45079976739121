import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const ZoomIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 14">
        <path
          fill="currentColor"
          d="M.572 5.854a5.648 5.648 0 005.647 5.647 5.453 5.453 0 002.98-.882l2.974 2.974c.225.225.526.335.84.335.67 0 1.163-.513 1.163-1.17 0-.314-.116-.608-.335-.826l-2.946-2.96a5.42 5.42 0 00.97-3.118A5.65 5.65 0 006.22.201 5.65 5.65 0 00.572 5.854zm1.668 0A3.98 3.98 0 016.22 1.87a3.985 3.985 0 013.985 3.985A3.984 3.984 0 016.22 9.833 3.978 3.978 0 012.24 5.854z"
        ></path>
      </svg>
    </div>
  );
};

export default ZoomIcon;
