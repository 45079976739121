import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const CircleChannelIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 19">
        <path
          fill="currentColor"
          d="M9 18.62c4.904 0 8.965-4.07 8.965-8.966C17.965 4.75 13.895.69 8.99.69 4.096.69.035 4.75.035 9.654c0 4.896 4.07 8.965 8.965 8.965zm0-1.495a7.435 7.435 0 01-7.462-7.47c0-4.15 3.305-7.471 7.453-7.471a7.45 7.45 0 017.48 7.47A7.442 7.442 0 019 17.125zm-.896-1.723c.29 0 .518-.228.518-.536V4.425a.516.516 0 00-.518-.528.525.525 0 00-.537.528v10.441c0 .317.229.536.537.536zm3.56-1.23a.518.518 0 00.526-.527V5.646a.518.518 0 00-.527-.527.518.518 0 00-.527.527v7.999c0 .298.228.527.527.527zm-5.345-1.107a.525.525 0 00.528-.537V6.754a.518.518 0 00-.528-.527.518.518 0 00-.527.527v5.774c0 .3.229.537.527.537zm3.56-.704a.523.523 0 00.527-.527V7.457a.523.523 0 00-.527-.527.529.529 0 00-.527.527v4.377c0 .29.237.527.527.527zm3.577-.975c.299 0 .519-.22.519-.528V8.433a.506.506 0 00-.519-.528.52.52 0 00-.536.528v2.425a.52.52 0 00.536.528zm-8.93-.44a.525.525 0 00.537-.527V8.872a.525.525 0 00-.537-.527.521.521 0 00-.518.527v1.547c0 .29.228.527.518.527z"
        ></path>
      </svg>
    </div>
  );
};

export default CircleChannelIcon;
