import React, { FC } from 'react';

import cn from 'classnames';

import { optionItemClassName } from '../select/styles/select.styles';
import { OptionItem, SelectItemProps } from '../select/types/select.type';

const SelectItem: FC<SelectItemProps> = (props) => {
  const { onChange, option, active } = props;

  function handleOnClickOption(option: OptionItem) {
    if (option?.disabled) return;
    onChange?.(option);
  }
  return (
    <li
      className={optionItemClassName({
        nullable: option.nullable || false,
        active,
        disabled: option?.disabled || false,
      })}
      onClick={handleOnClickOption.bind(null, option)}
      title={String(option.title)}
    >
      <div className="truncate w-full">
        <span
          className={cn(
            option.value === '' && 'text-b_dark',
            option.disabled && '!text-3color',
            'font-[500] tracking-tight leading-[110%]',
            option?.className ? option.className : `${active ? '!text-action' : 'text-1color'}`,
          )}
        >
          {option.title}
        </span>
      </div>
    </li>
  );
};

export default SelectItem;
