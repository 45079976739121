import React from 'react';

import { Breadcrumbs } from './breadcrumbs';

const FooterBlock = () => {
  // const { t } = useTranslation('components.footer');
  return (
    <div className="h-[40px] bg-bg_3 w-full">
      <div className="inline-flex w-full items-center h-full justify-between px-[20px]">
        <div className="inline-flex items-center">
          <Breadcrumbs />
        </div>
        <div className="text-[14px] font-[500px] inline-flex items-center text-2color">
          <div className="mr-[20px]"></div>
          <div>{new Date().getFullYear()}</div>
        </div>
      </div>
    </div>
  );
};

export default FooterBlock;
