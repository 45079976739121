import React, { FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ChevronLeftV2Icon } from '../icons/icons-list';
import Select from '../select';

import { OptionItem } from '../select/types/select.type';

import { navigationButtonClassName, pageClassName } from './styles/paginator.styles';

export type PaginationResponse = {
  offset: number;
  limit: number;
};

type PaginatorProps = {
  page: number;
  count: number;
  limit?: number;
  containerClassName?: string;
  visiblePages?: number;
  onChange: (paginationData: PaginationResponse) => void;
  pageOptions?: Array<OptionItem>;
  onChangePageOptions?: (value: string | number) => void;
  offsetPlaceholder?: string;
};

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const Paginator: FC<PaginatorProps> = (props) => {
  const {
    page,
    count,
    limit = 10,
    visiblePages = 2,
    onChange,
    offsetPlaceholder,
    onChangePageOptions,
    pageOptions,
    containerClassName,
  } = props;
  const [currentLimit, setCurrentLimit] = useState<number>(limit);
  const [currentPage, setCurrentPage] = useState<number>(page);
  const [currentTotal, setCurrentTotal] = useState<number>(count);
  const [totalPages, setTotalPages] = useState(Math.ceil(currentTotal / currentLimit));
  const { t } = useTranslation();
  const offsetShift = 1;

  const buildPaginationArray = useCallback(() => {
    const totalNumbers = visiblePages * 2 + 2;
    const totalBlocs = totalNumbers + 2;
    if (totalPages > totalBlocs) {
      const startPage = Math.max(2, currentPage - visiblePages);
      const endPage = Math.min(totalPages - 1, currentPage + visiblePages);
      let pages: Array<string | number> = range(startPage, endPage);
      const hasLeftSplit = startPage > 2;
      const hasRightSplit = totalPages - endPage > 1;
      const splitOffset = totalNumbers - (pages.length + 1);
      switch (true) {
        case hasLeftSplit && !hasRightSplit: {
          pages = [LEFT_PAGE, ...range(startPage - splitOffset, startPage - 1), ...pages];
          break;
        }
        case !hasLeftSplit && hasRightSplit: {
          pages = [...pages, ...range(endPage + 1, endPage + splitOffset), RIGHT_PAGE];
          break;
        }
        case hasLeftSplit && hasRightSplit:
        default:
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  }, [currentPage, totalPages, visiblePages]);

  useEffect(() => {
    //setCurrentLimit(limit);
    setCurrentTotal(count);
    //setCurrentPage(page);
    setTotalPages(Math.ceil(count / limit));
    buildPaginationArray();
  }, [buildPaginationArray, count, limit, page]);

  function range(from: number, to: number, step = 1) {
    let i = from;
    const range: Array<number> = [];
    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  }

  function handleChangeCurrentOffset(value: string | number) {
    setCurrentLimit(Number(value));
    setCurrentPage(1);
    onChange?.({ offset: 0, limit: Number(value) });
    onChangePageOptions?.(Number(value));
  }

  function handleChangePage(pageClicked: string | number, limitChanged: number) {
    if (pageClicked === '...') return;
    const currentPageAction =
      pageClicked === LEFT_PAGE
        ? currentPage - 1
        : pageClicked === RIGHT_PAGE
        ? currentPage + 1
        : pageClicked;
    if ((currentPageAction as number) < 1 || (currentPageAction as number) > (totalPages as number))
      return;
    setCurrentPage(Number(currentPageAction));
    onChange?.({ offset: Number(currentPageAction) - offsetShift, limit: limitChanged });
  }

  const renderPages = buildPaginationArray().map((pageItem) => {
    return (
      <div
        key={pageItem}
        onClick={handleChangePage.bind(null, pageItem, currentLimit)}
        className={pageClassName(String(pageItem) === String(currentPage))}
      >
        {pageItem === 'LEFT' || pageItem === 'RIGHT' ? '...' : pageItem}
      </div>
    );
  });

  return (
    <div
      className={`inline-flex items-center w-full justify-between  ${
        containerClassName && containerClassName
      }`}
    >
      {totalPages > 1 ? (
        <div className="inline-flex items-center">
          {totalPages > 1 ? (
            <div className="bg-white inline-flex h-[40px] overflow-hidden items-center shadow-medium font-[700] text-[14px] rounded-[10px]">
              <div className="bg-white inline-flex h-[40px] overflow-hidden items-center shadow-medium font-[700] text-[14px] rounded-[10px]">
                <button
                  className={navigationButtonClassName({ isRight: false })}
                  onClick={handleChangePage.bind(null, LEFT_PAGE, currentLimit)}
                >
                  <ChevronLeftV2Icon size={8} />
                </button>
                <div className="inline-flex items-center mr-[8px] ml-[12px]"> {renderPages}</div>
                <button
                  className={navigationButtonClassName({ isRight: true })}
                  onClick={handleChangePage.bind(null, RIGHT_PAGE, currentLimit)}
                >
                  <ChevronLeftV2Icon size={8} className="rotate-180" />
                </button>
              </div>
            </div>
          ) : (
            <div className="flex-1 h-[40px]" />
          )}
          {currentTotal > 0 && (
            <div className="ml-[20px] select-none truncate">
              <b>
                {currentPage * currentLimit - currentLimit + 1} –&nbsp;
                {Math.min(currentPage * currentLimit, currentTotal)}&nbsp;
              </b>
              {t('components.pagination.from')} {currentTotal}
            </div>
          )}
          {currentTotal > 0 && (
            <div className="ml-[20px] text-[15px] select-none font-[500] inline-flex items-center">
              <div className="mr-[10px] truncate">{t('components.pagination.go_to_page')} </div>
              <Select
                size={94}
                defaultValue={currentPage}
                position="right"
                dropHeight={160}
                dropWidth={110}
                onChange={(value) => handleChangePage(value, currentLimit)}
                placeholder={offsetPlaceholder || t('components.pagination.input.page')}
                options={Array(Math.ceil(currentTotal / currentLimit))
                  .fill('_')
                  .map((_, index) => ({ title: String(index + 1), value: index + 1 }))}
                dropTop
              />
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      {totalPages > 1 || currentTotal > 25 ? (
        <div className="inline-flex text-[15px] select-none font-[500] items-center">
          {t('components.pagination.show')}:
          <div className="ml-[11px] z-[99]">
            <Select
              size={114}
              defaultValue={currentLimit}
              position="right"
              dropHeight={160}
              dropWidth={150}
              onChange={handleChangeCurrentOffset}
              placeholder={offsetPlaceholder || 'input.offset_placeholder'}
              options={
                pageOptions || [
                  { title: '10', value: 10 },
                  { title: '25', value: 25 },
                  { title: '50', value: 50 },
                  { title: '100', value: 100 },
                ]
              }
              dropTop
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Paginator;
