import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PageHeader } from '@ui';
import { APP_NAME } from '@app/utils/constants';

import VehicleTableBlock from './blocks/vehicle-table.block';

const ListOfVehiclesPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>
          {t('pages.vehiclePark.title')} | {APP_NAME}
        </title>
      </Helmet>
      <PageHeader icon="SearchInTextIcon" label={t('pages.vehiclePark.title')} />
      <VehicleTableBlock />
    </div>
  );
};

export default ListOfVehiclesPage;
