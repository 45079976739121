import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { Paginator, Table, HalfDonutBar } from '@ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { TableItem } from '@app/components/ui/table/types/table.type';
import { useGetCurrentVehiclesQuery, useGetVehiclesQuery } from '@app/store/api/vehicle.api';

const VehicleTableBlock = () => {
  const navigate = useNavigate();
  const handleVehicleClick = (vehicleId) => {
    navigate(`/vehicle/${vehicleId}`);
  };
  const [paginationData, changedPaginationData] = useState<PaginationResponse>({
    limit: 10,
    offset: 0,
  });
  const { data: vehicleList, isLoading } = useGetCurrentVehiclesQuery({
    ...paginationData,
    sortDesc: true,
  });

  const { data: vehiclesList } = useGetVehiclesQuery({
    ...paginationData,
    sortDesc: true,
  });

  console.log('test vehicle api response', vehicleList, isLoading);
  console.log('test vehicles api response', vehiclesList);

  const { t } = useTranslation();
  const vehicleColumns = [
    {
      index: 'vehicle_name',
      className: 'break-words',
      title: t('pages.vehiclePark.table.vehicle_name'),
      minWidth: 200,
    },
    {
      index: 'ich',
      title: t('pages.vehiclePark.table.permissible_discharge'),
      maxWidth: 120,
    },
    { index: 'idis', title: t('pages.vehiclePark.table.permissible_charging'), maxWidth: 120 },
    { index: 'uall', title: t('pages.vehiclePark.table.total_voltage'), maxWidth: 120 },
    { index: 'tmax', title: t('pages.vehiclePark.table.max_cell_temperature'), maxWidth: 120 },
    {
      index: 'status_work_state',
      title: t('pages.vehiclePark.table.status_work_state'),
      maxWidth: 130,
    },
    { index: 'errors', title: t('pages.vehiclePark.table.errors'), maxWidth: 130 },
  ];
  const tableVehicleData = vehicleList?.data_list?.map((vehicle) => ({
    vehicle_name: (
      <NavLink
        onClick={() => handleVehicleClick(vehicle.vehicle_id)}
        className="text-black font-normal text-[14px] underline hover:text-action transition"
        to="#"
      >
        {vehicle.name}
      </NavLink>
    ),
    ich: (
      <div className="relative h-[50px] w-[200px] flex justify-center items-center">
        <HalfDonutBar
          MaxScale={vehicle.ich.MaxScale}
          MinScale={vehicle.ich.MinScale}
          MaxAvailable={vehicle.ich.MaxAvailable}
          MinAvailable={vehicle.ich.MinAvailable}
          current={vehicle.ich.current}
        />
        <div className="absolute text-[12px] text-3color font-sf-pro-display font-light mr-[98px] font mt-[24px]">
          {vehicle.ich.current}А
        </div>
      </div>
    ),
    idis: (
      <div className="relative h-[50px] w-[200px] flex justify-center items-center">
        <HalfDonutBar
          MaxScale={vehicle.idis.MaxScale}
          MinScale={vehicle.idis.MinScale}
          MaxAvailable={vehicle.idis.MaxAvailable}
          MinAvailable={vehicle.idis.MinAvailable}
          current={vehicle.idis.current}
        />
        <div className="absolute text-[12px] text-3color font-sf-pro-display font-light mr-[98px] font mt-[24px]">
          {vehicle.idis.current}А
        </div>
      </div>
    ),
    uall: (
      <div className="relative h-[50px] w-[200px] flex justify-center items-center">
        <HalfDonutBar
          MaxScale={vehicle.uall.MaxScale}
          MinScale={vehicle.uall.MinScale}
          MaxAvailable={vehicle.uall.MaxAvailable}
          MinAvailable={vehicle.uall.MinAvailable}
          current={vehicle.uall.current}
        />
        <div className="absolute text-[12px] text-3color font-sf-pro-display font-light mr-[98px] font mt-[24px]">
          {vehicle.uall.current}в
        </div>
      </div>
    ),
    tmax: (
      <div className="relative h-[50px] w-[200px] flex justify-center items-center">
        <HalfDonutBar
          MaxScale={vehicle.tmax.MaxScale}
          MinScale={vehicle.tmax.MinScale}
          MaxAvailable={vehicle.tmax.MaxAvailable}
          MinAvailable={vehicle.tmax.MinAvailable}
          current={vehicle.tmax.current}
        />
        <div className="absolute text-[12px] text-3color font-sf-pro-display font-light mr-[98px] font mt-[24px]">
          {vehicle.tmax.current}℃
        </div>
      </div>
    ),
    status_work_state: vehicle.workstate_status,
    errors: vehicle.error,
  }));

  return (
    <div>
      <Table
        columns={vehicleColumns}
        dataSource={tableVehicleData as unknown as Array<TableItem>}
      />
      <Paginator
        page={paginationData.offset + 1}
        limit={paginationData.limit}
        count={vehicleList?.total || 0}
        onChange={(page) => changedPaginationData(page)}
      />
    </div>
  );
};

export default VehicleTableBlock;
