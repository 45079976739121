import React, { FC } from 'react';

import { IconType } from '../icon.type';

const ChevronRightIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 9 9"
      >
        <g clipPath="url(#clip0_6429_3116)">
          <path
            fill="currentColor"
            d="M2.859.642l-.125.124a.211.211 0 000 .3L6.17 4.5 2.734 7.937a.211.211 0 000 .298l.125.125a.211.211 0 00.298 0l3.711-3.709a.211.211 0 000-.299L3.158.643a.21.21 0 00-.3-.001z"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_6429_3116">
            <path fill="currentColor" d="M0 0H9V9H0z" transform="rotate(-90 4.5 4.5)"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ChevronRightIcon;
