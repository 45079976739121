import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Button, Input } from '@ui';
import { AuthLoginRequest } from '@app/store/interfaces/auth.type';
import { navigationRouteList } from '@app/utils/routes';
import { useLoginUserMutation } from '@app/store/api/auth.api';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/store';
import { UseGetMainPageUrl } from '@app/hooks/use-get-main-page-url';

const LoginForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AuthLoginRequest>();
  const { user, error: errorFromServer } = useAppSelector((state) => state.userState);
  const { t } = useTranslation();
  const [getLoginUser, { isLoading, isSuccess }] = useLoginUserMutation();
  function onSubmitForm(data: AuthLoginRequest) {
    getLoginUser(data);
  }
  const mainPage = UseGetMainPageUrl();

  if (isSuccess || !!user) return <Navigate to={mainPage} />;
  return (
    <form
      className="flex flex-col items-center p-[50px_57px_40px_58px] bg-white shadow-hard rounded-defaultR"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="w-[294px]">
        <div className="flex w-full justify-center items-center text-black text-[24px] font-[700] leading-[29px]">
          <h1>{t('pages.login.title')}</h1>
        </div>
        <div className="flex w-full flex-col gap-[27px] mt-[40px]">
          <Input
            label={t('pages.login.form.field.username.label')}
            {...register('username', { required: true })}
            error={errors.username && t('pages.login.form.error.login')}
            name={'username'}
          />
          <Input
            type="password"
            label={t('pages.login.form.field.password.label')}
            error={errors.password && t('pages.login.form.error.password')}
            {...register('password', { required: true })}
            name={'password'}
          />
        </div>
        <div className={cn(errorFromServer ? 'mt-[20px]' : 'mt-[35px]')}>
          {errorFromServer && (
            <div className="mb-[10px] p-[10px] bg-basic_red text-white text-[10px]">
              {errorFromServer}
            </div>
          )}
          <Button type={'submit'} label="Войти" link={navigationRouteList.login} />
        </div>
        <div className="flex w-full justify-between mt-[15px]">
          <div>
            <Button
              className="underline underline-offset-[3px] !border-0 !px-0 !font-[500]"
              fill="linked"
              label={t('pages.login.form.button.remind')}
              link={navigationRouteList.remindPassword}
            />
          </div>
          <div>
            <Button
              className="underline underline-offset-[3px] !border-0 !px-0 !font-[500]"
              fill="linked"
              label={t('pages.login.form.button.access')}
              disabled={isLoading}
              link={navigationRouteList.loginAccess}
            />
          </div>
        </div>
        <div className={'text-[13px] font-[500] leading-[130%] text-2color text-center mt-[30px]'}>
          {t('pages.login.description')}
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
