import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '@app/store/customFetchBase';
import { endpointUrl } from '@app/utils/endpoint-url';

import {
  OrganizationPermissions,
  OrganizationPermissionsEditRequest,
  PermissionUserCRUDResponse,
  UserPermissions,
} from '@appTypes/permissions.type';

const permissionApi = createApi({
  reducerPath: 'permissionApi',
  baseQuery: customFetchBase,
  tagTypes: ['PermissionsUser', 'PermissionsOrganization', 'PermissionsEquipment'],
  endpoints: (builder) => ({
    getUserPermissions: builder.query<UserPermissions, { user_id?: string } | null>({
      query(params) {
        return {
          url: endpointUrl.userPermission,
          params: params || {},
        };
      },
      providesTags: ['PermissionsUser'],
    }),
    /* Получение матрицы доступа для всех пользователей организации */
    getOrganizationPermissions: builder.query<OrganizationPermissions, { organization_id: number }>(
      {
        query(params) {
          return {
            url: endpointUrl.organizationPermission,
            params,
          };
        },
        providesTags: ['PermissionsOrganization'],
      },
    ),
    editOrganizationPermissions: builder.mutation<
      OrganizationPermissions,
      { body: OrganizationPermissionsEditRequest; params: { organization_id: number } }
    >({
      query({ body, params }) {
        return {
          url: endpointUrl.organizationPermission,
          method: 'POST',
          body,
          params,
        };
      },
      invalidatesTags: ['PermissionsOrganization'],
    }),
    // getEquipmentPermissions: builder.query<EquipmentPermissions, { user_id: string }>({
    //   query() {
    //     return {
    //       url: endpointUrl.equipmentPermission,
    //     };
    //   },
    //   providesTags: ['PermissionsEquipment'],
    // }),
    editUserPermissions: builder.mutation<UserPermissions, PermissionUserCRUDResponse>({
      query(body) {
        return {
          url: endpointUrl.userPermission,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['PermissionsUser'],
    }),
    // editEquipmentPermissions: builder.mutation<
    //   EquipmentPermissions,
    //   PermissionEquipmentCRUDResponse
    // >({
    //   query(body) {
    //     return {
    //       url: endpointUrl.equipmentPermission,
    //       method: 'POST',
    //       body,
    //     };
    //   },
    //   invalidatesTags: ['PermissionsEquipment'],
    // }),
  }),
});

export default permissionApi;
export const {
  useLazyGetUserPermissionsQuery,
  useGetUserPermissionsQuery,
  useGetOrganizationPermissionsQuery,
  // useGetEquipmentPermissionsQuery,
  //useLazyGetEquipmentPermissionsQuery,
  useEditUserPermissionsMutation,
  //useEditEquipmentPermissionsMutation,
  useEditOrganizationPermissionsMutation,
} = permissionApi;
