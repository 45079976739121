import cn from 'classnames';

export const easyModalClassName = ({ show }: { show: boolean }) =>
  cn(
    show ? 'visible' : 'invisible',
    'noDrag',
    'fixed',
    'ease-in-out',
    'duration-180',
    'w-screen',
    'h-screen',
    'inset-0',
    'inline-flex',
    'items-center',
    'justify-center',
    'bg-bg5_modal ',
    'backdrop-blur-sm',
    'px-[10px]',
  );
