import React, { FC, forwardRef, ForwardedRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { v4 } from 'uuid';
import { SwitchProps, ToggleColor, ToggleSize } from '@app/components/ui/switch/types/switch.type';

const Switch: FC<SwitchProps> = forwardRef((props, forwardRef: ForwardedRef<HTMLInputElement>) => {
  const {
    checked = false,
    disabled = false,
    size = 'medium',
    variant = 'primary',
    onChecked,
    ...remainingProps
  } = props;
  const toggleRandomId = `toggle_${v4()}`;

  const [currentChecked, setChecked] = useState<boolean>(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const toggleWrapperList: Record<ToggleSize, string> = {
    small: 'w-[21.75px] h-[12px] rounded-[90px]',
    medium: 'w-[29px] h-[16px] rounded-[90px]',
    large: 'w-[36px] h-[20px] rounded-[90px]',
  };
  const toggleCircleWrapperList: Record<ToggleSize, string> = {
    small: 'w-[9px] h-[9px]',
    medium: 'w-[12px] h-[12px]',
    large: 'w-[18px] h-[18px]',
  };

  const backgroundList: Record<ToggleColor, string> = {
    primary: cn(currentChecked ? 'bg-action border-action' : 'bg-bg_4  border-b_dark '),
    success: cn(currentChecked ? 'bg-basic_green border-basic_green' : 'bg-bg_4 border-b_dark'),
  };

  function handlerOnCheck(event) {
    onChecked?.(event.target.checked);
    setChecked(event.target.checked);
  }

  return (
    <div>
      <label
        className={cn(
          toggleWrapperList[size],
          backgroundList[variant],
          'blocks',
          'cursor-pointer',
          currentChecked ? 'justify-end' : 'justify-start',
          'border',
          'transition',
          'px-[1px] inline-flex items-center',
          disabled && '!bg-2color/[.3] !border-2color/[.2]',
        )}
        htmlFor={toggleRandomId}
      >
        <input
          id={toggleRandomId}
          className="hidden appearance-none"
          ref={forwardRef}
          checked={currentChecked}
          disabled={disabled}
          onChange={handlerOnCheck}
          type="checkbox"
          {...remainingProps}
        />
        <div
          className={cn(
            toggleCircleWrapperList[size],
            'bg-white transition rounded-full shadow-[0px_2px_2px_rgba(61,74,88,0.06)]',
          )}
        />
      </label>
    </div>
  );
});

Switch.displayName = 'Switch';

export default Switch;
