import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const PauseExceed: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg width={size} height="57" fill="none" viewBox="0 0 58 57">
        <circle cx="29" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          d="M35.593 32.3a6 6 0 100 11.999 6 6 0 000-12zm2.182 6.545h-2.182a.546.546 0 01-.545-.546v-3.272a.545.545 0 111.09 0v2.727h1.637a.545.545 0 110 1.09z"
        ></path>
        <path
          fill="#2987CC"
          d="M37.775 38.845h-2.182a.546.546 0 01-.545-.546v-3.272a.545.545 0 111.09 0v2.727h1.637a.545.545 0 110 1.09z"
        ></path>
        <path
          fill="#fff"
          d="M34.123 35.727a.735.735 0 00-.735.735v3.675a.735.735 0 101.47 0v-3.675a.735.735 0 00-.735-.735zm2.94 0a.735.735 0 00-.735.735v3.675a.735.735 0 101.47 0v-3.675a.735.735 0 00-.734-.735z"
        ></path>
        <path
          fill="#2987CC"
          fillRule="evenodd"
          d="M27.987 41.125c0 .586-.476 1.074-1.099 1.074-.61 0-1.062-.488-1.062-1.074V17.26c0-.586.452-1.074 1.062-1.074.623 0 1.099.488 1.099 1.074v23.865zm8.398-9.253a2.408 2.408 0 00-2.16.134V19.958c0-.598.463-1.099 1.074-1.099.61 0 1.086.5 1.086 1.099v11.914zm-12.61 4.505c0 .585-.476 1.074-1.086 1.074-.61 0-1.074-.489-1.074-1.075V22.01a1.07 1.07 0 011.074-1.087c.61 0 1.086.489 1.086 1.087v14.367zm6.25-1.373c.443-1.1 1.625-1.966 2.161-2.16v-9.26c0-.599-.476-1.087-1.086-1.087a1.07 1.07 0 00-1.075 1.087v11.42zm-10.449-3.56c0 .599-.476 1.087-1.086 1.087a1.07 1.07 0 01-1.074-1.086V26.94c0-.598.463-1.098 1.074-1.098.61 0 1.086.5 1.086 1.098v4.505z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default PauseExceed;
