import React, { useState, useEffect } from 'react';
import { Button, Input } from '@ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { navigationRouteList } from '@app/utils/routes';
import { useTokenValidateMutation, useChangePasswordMutation } from '@app/store/api/auth.api';
import { useTranslation } from 'react-i18next';

import { AlarmIcon } from '@icons';

type FormType = { password: string; validatePassword: string; token: string };

const NewPasswordForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormType>();

  const [showForm, setShowForm] = useState(false);

  const [validateToken] = useTokenValidateMutation();
  const [changePassword] = useChangePasswordMutation();

  const onSubmit: SubmitHandler<FormType> = (data) => {
    if (data.password !== data.validatePassword) {
      setError('validatePassword', {
        type: 'manual',
        message: t('pages.newPasswordForm.password_dont_mach'),
      });
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      changePassword({ token, password: data.password })
        .then((response) => {
          if ('data' in response) {
            window.location.href = navigationRouteList.login;
          } else if ('error' in response) {
            setError('password', {
              type: 'manual',
            });
          }
        })
        .catch(() => {
          setError('password', {
            type: 'manual',
          });
        });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      validateToken({ token })
        .unwrap()
        .then(() => {
          setShowForm(true);
        })
        .catch(() => {
          setError('token', {
            type: 'manual',
          });
        });
    }
  }, [setError, validateToken]);

  return (
    <div>
      {showForm ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center p-[50px_57px_40px_58px] bg-white shadow-hard rounded-defaultR font-sf-pro-display"
        >
          <div className="w-[294px]">
            <div className="flex w-full justify-center items-center text-black text-[24px] font-[700] mb-[35px] leading-[29px]">
              <h1>{t('pages.newPasswordForm.form_header')}</h1>
            </div>
            <div className="flex w-full flex-col gap-[27px] mt-[30px]">
              <Input
                label={t('pages.newPasswordForm.form_label1')}
                {...register('password', {
                  required: {
                    value: true,
                    message: t('pages.newPasswordForm.field_required'),
                  },
                })}
                type="password"
              />
            </div>
            <div className="flex w-full flex-col gap-[27px] mt-[30px]">
              <Input
                label={t('pages.newPasswordForm.form_label2')}
                {...register('validatePassword', {
                  required: {
                    value: true,
                    message: t('pages.newPasswordForm.field_required'),
                  },
                })}
                type="password"
              />
            </div>
            {errors.validatePassword && (
              <div className="flex justify-center content-center ">
                <div className="mt-[10px] flex items-center  bg-red_pal border-red_border border-[1px] w-[307px] h-[32px] text-1color text-[13px] rounded-[6px]">
                  <div className="mx-[12px]">
                    <AlarmIcon size={15} />
                  </div>
                  {errors.validatePassword.message}
                </div>
              </div>
            )}
            <div className="mt-[35px]">
              <Button type={'submit'} label={t('pages.newPasswordForm.form_button1')} />
            </div>
            <div className="flex w-full justify-center mt-[15px]">
              <div>
                <Button
                  className="underline underline-offset-[3px] !border-0 !px-0 !font-[500]"
                  fill="linked"
                  label={t('pages.newPasswordForm.form_button2')}
                  link={`${navigationRouteList.login}`}
                />
              </div>
            </div>
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default NewPasswordForm;
