import React, { FC } from 'react';
import { CircleButton, Icon, Modal } from '@ui';

const HelpModal: FC<{ helperText?: string }> = ({ helperText = '' }) => {
  const [showHelper, setShowHelper] = React.useState(false);
  return (
    <>
      <CircleButton
        onClick={() => setShowHelper(!showHelper)}
        className="text-action pb-1"
        icon={'HelpCircleIcon'}
        size={15}
      />
      <Modal show={showHelper} onClose={() => setShowHelper(false)} withoutFooter variant="small">
        <div className="w-full p-4">
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <div className="">
              <Icon
                size={30}
                className="text-action p-4 bg-action bg-opacity-10 rounded-full"
                name={'HelpCircleIcon'}
              />
            </div>
            <div>
              <h2>{'help'}</h2>
            </div>
            <div className="block">
              <div className="text-[14px] mx-12 leading-normal text-1color font-medium">
                {helperText}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HelpModal;
