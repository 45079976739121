import React, { FC, Fragment, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Icon, Select } from '@ui';
import { OptionItem } from '@app/components/ui/select/types/select.type';

import { conditions } from '../data';
import { cellBlockClassName, nestedFieldsBlockClassName } from '../styles';

import NestedField from './nested-field';

const Field: FC<{ fieldsOption: Array<OptionItem>; scroller: RefObject<HTMLDivElement> }> = (
  props,
) => {
  const { fieldsOption, scroller } = props;
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filter',
  });
  // function getParamsSelectOptions(): OptionItem[] {
  //   return dataForFormSelectMock.map((item) => ({ title: item.name, value: item.id }));
  // }
  function getConditionsSelectOptions(): OptionItem[] {
    return conditions.map((item) => ({ title: item.name, value: item.id }));
  }
  function onChangeParams(value: string | number, index: number) {
    setValue(`filter[${index}].id`, value);
    !watch(`filter[${index}].condition`) &&
      setValue(`filter[${index}].condition`, getConditionsSelectOptions()[0].value);
  }
  function onChangeFieldArray() {
    scroller.current?.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <ul className={'flex flex-col'}>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <div className={nestedFieldsBlockClassName}>{t('components.findData.filter.and')}</div>
          <li className={cellBlockClassName}>
            <div className={'flex items-start justify-center gap-[16px] w-full'}>
              <div className="w-[400px]">
                <Select
                  defaultValue={watch(`filter[${index}].id`)}
                  onChange={(value) => onChangeParams(value, index)}
                  options={fieldsOption}
                  dropTop
                />
              </div>
              <div className={'w-[130px]'}>
                <Select
                  defaultValue={watch(`filter[${index}].condition`)}
                  onChange={(value) => setValue(`filter[${index}].condition`, value)}
                  options={getConditionsSelectOptions()}
                  dropTop
                />
              </div>
              <div className="flex-1">
                <NestedField
                  defaultValue={watch(`filter[${index}].value`)}
                  onChangeIterFields={onChangeFieldArray}
                  fieldsLength={fields.length}
                  appendFilter={append}
                  nestIndex={index}
                />
              </div>
            </div>
            {fields.length !== index + 1 && (
              <div className={'cursor-pointer absolute right-[-25px]'}>
                <Icon
                  name="TrashIcon"
                  className="!text-basic_red"
                  size={14}
                  onClick={() => remove(index)}
                />
              </div>
            )}
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

export default Field;
