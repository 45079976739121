import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const CancelIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 21 20">
        <path
          fill="currentColor"
          d="M10.5 19.885c5.242 0 9.584-4.332 9.584-9.583 0-5.242-4.342-9.584-9.593-9.584C5.249.718.917 5.06.917 10.302c0 5.25 4.341 9.583 9.583 9.583zm0-1.883a7.673 7.673 0 01-7.69-7.7 7.658 7.658 0 017.68-7.691 7.681 7.681 0 017.71 7.69c0 4.268-3.432 7.701-7.7 7.701zm4.787-6.596c0-2.227-1.596-3.6-3.776-3.6H8.765l-1.002.037.696-.538.937-.89a.681.681 0 00.223-.501.707.707 0 00-.705-.715.64.64 0 00-.501.214L5.945 7.917a.81.81 0 00-.26.576c0 .222.093.417.26.575l2.477 2.523a.65.65 0 00.5.204c.381 0 .697-.315.697-.714a.667.667 0 00-.214-.5L8.301 9.55l-.538-.39 1.002.046h2.746c1.308 0 2.292.77 2.292 2.134 0 1.391-.956 2.264-2.301 2.264h-1.299c-.445 0-.78.26-.78.714 0 .464.335.723.79.723h1.289c2.18 0 3.785-1.4 3.785-3.636z"
        ></path>
      </svg>
    </div>
  );
};

export default CancelIcon;
