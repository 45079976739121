import React, { CSSProperties, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Box, Button, Icon, Modal, PageHeader } from '@ui';
import {
  useCreateFilterMutation,
  useFilterCreateDataMutation,
  useLazyDownloadFileQuery,
  useLazyFilterCheckQuery,
  useLazyFilterCsvDataQuery,
  useLazyResetFilterQuery,
} from '@app/store/api/search-data.api';
import { useGetEquipmentModelBulkQuery } from '@app/store/api/models.api';
import {
  FilterCreateResponse,
  FilterRangeItem,
  FilterRangeType,
  FilterStatus,
} from '@appTypes/search-data.type';
import { useEquipmentByModelQuery } from '@app/store/api/equipment.api';
import { navigationRouteList } from '@app/utils/routes';
import DocIcon from '@app/components/ui/icons/icons-list/doc.icon';
import { metaDateTimeCalendar } from '@app/pages/datas/blocks/find-data-form/data';
import { usePermissionCheck } from '@app/hooks/use-check-allowed-permissions';

import FindDataForm from './blocks/find-data-form';

const FINDING_EQUIPMENT_KEY = 'calc_equipment_id';

const DataSearchPage = () => {
  const { t } = useTranslation();
  const dataSearchVehicleLinkPermission = usePermissionCheck('VehicleAccesse02');
  const [currentStatus, setCurrentStatus] = useState<FilterStatus>('process');
  const [isOpenFilter, changeOpenFilter] = useState<boolean>(false);
  const [createFilter] = useCreateFilterMutation();
  const [downloadCsv] = useLazyDownloadFileQuery();
  const [resetFilter] = useLazyResetFilterQuery();
  const dataSearchVehicleLinkClassName: CSSProperties = dataSearchVehicleLinkPermission
    ? { pointerEvents: 'none' }
    : {};
  const tableCellClassName = (
    type: 'title' | 'cell' = 'cell',
    position: 'start' | 'center' = 'center',
  ) =>
    cn(
      type === 'title' ? 'font-[600] text-[12px] uppercase' : 'text-[14px] font-[400]',
      'flex',
      'center',
      'items-center',
      'px-[20px]',
      'py-[16px]',
      'transition',
      'easy-out',
      position === 'start' ? 'justify-start' : 'justify-center',
    );
  const [createCsvData] = useFilterCreateDataMutation();
  const { data: equipmentModels, isLoading: isEquipmentLoading } = useGetEquipmentModelBulkQuery();
  const [checkFilterData, { data: filterData }] = useLazyFilterCheckQuery();
  const {
    data: equipmentsByModel,
    isLoading,
    isFetching,
  } = useEquipmentByModelQuery(filterData?.filter?.model_id || 0, {
    skip: !filterData?.filter?.model_id,
  });
  const [csvLinksLoading, { data: csvLinks }] = useLazyFilterCsvDataQuery();

  const checkFilterResultHandler = useCallback(() => {
    setTimeout(
      () =>
        checkFilterData(null).then(({ data }) => {
          setCurrentStatus(data?.filter?.status || 'process');
          switch (String(data?.filter?.status)) {
            case 'process':
              return checkFilterResultHandler();
            case 'counted':
              break;
            case 'calculating':
              return checkFilterResultHandler();
            case 'finished':
              csvLinksLoading(null);
              return;
          }
        }),
      3000,
    );
  }, [checkFilterData, csvLinksLoading]);

  function submitSearchFilterHandler(data: FilterCreateResponse) {
    localStorage.removeItem('is_graph');
    const filterResponse =
      data.body.range.type === 'r'
        ? {
            ...data,
            body: {
              ...data.body,
              range: {
                ...data.body.range,
                parameters: data.body.range.parameters.map((parameter) => ({
                  ...parameter,
                  value:
                    parameter.value.split(':').length < 3
                      ? `${parameter.value}:00`
                      : parameter.value.split(':').slice(0, 3).join(':'),
                })),
              },
            },
          }
        : data;
    createFilter(filterResponse)
      .unwrap()
      .then(() => {
        changeOpenFilter(false);
        checkFilterResultHandler();
        setCurrentStatus('process');
      });
  }

  useEffect(() => {
    checkFilterResultHandler();
  }, [checkFilterResultHandler]);
  function createCsvHandler(equipment_id: number, isBlocked = false) {
    if (isBlocked) return;
    createCsvData({
      vehicle_equipment_id: equipment_id,
      plot: false,
      csv: true,
    })
      .unwrap()
      .then(() => {
        localStorage.setItem(FINDING_EQUIPMENT_KEY, String(equipment_id));
        checkFilterResultHandler();
        setCurrentStatus('calculating');
      });
  }

  const currentCellRange = renderTableRange(
    filterData?.filter?.filter?.range.type || 'n',
    filterData?.filter?.filter?.range.parameters || [],
  );
  function resetFilterHandler() {
    resetFilter()
      .unwrap()
      .then(() => {
        checkFilterResultHandler();
        resetFilterHandler();
      });
  }
  function downloadCsvHandler(fileLink: string) {
    downloadCsv({ file: fileLink })
      .unwrap()
      .then((file) => {
        const a = document.createElement('a');
        a.href = file;
        a.setAttribute(`${fileLink.split('/')[1]}`, file);
        a.download = fileLink;
        a.click();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Не удалось скачать csv');
      });
  }
  function renderTableRange(type: FilterRangeType, period: FilterRangeItem['parameters']) {
    const defaultState: {
      title: string | Array<string>;
      grid: string;
      period: string | Array<string>;
    } = {
      title: t('pages.dataSearch.table.time_not_specified'),
      grid: 'grid-cols-[2fr_1fr_1fr]',
      period: '-',
    };
    const typeCell = {
      l: { ...defaultState, period: period[0]?.value },
      c: {
        ...defaultState,
        title: t('pages.dataSearch.table.calendar_binding'),
        period: metaDateTimeCalendar.find((interval) => interval.value === type)?.['title'],
      },
      r: {
        ...defaultState,
        title: [
          <>
            {t('pages.dataSearch.table.date')} <br /> {t('pages.dataSearch.table.start')}
          </>,
          <>
            {t('pages.dataSearch.table.date')} <br /> {t('pages.dataSearch.table.end')}
          </>,
        ],
        grid: 'grid-cols-[2fr_1fr_1fr_1fr]',
        period: period.map((date) => date.value),
      },
      n: {
        ...defaultState,
        grid: 'grid-cols-[2fr_1fr]',
      },
    };
    return typeCell[type.substring(0, 1)];
  }

  const renderStatusData: (
    equipment_id: number,
  ) => Record<FilterStatus, (options?: boolean) => ReactNode> = (equipment_id: number) => ({
    error: () => (
      <div className="text-basic_red w-full center flex justify-center">
        {t('pages.dataSearch.error')}
      </div>
    ),
    process: () => {
      localStorage.removeItem(FINDING_EQUIPMENT_KEY);
      return (
        <div className=" w-full center flex justify-center animate-pulse">
          {t('pages.dataSearch.calculate')}...
        </div>
      );
    },
    counted: (isDownloaded = true) => {
      const pointCount = filterData?.filter?.counts?.[equipment_id]
        ? Object.values(filterData.filter.counts[equipment_id]).reduce(
            (totalItems, currentItem) => totalItems + currentItem || 0,
            0,
          )
        : 0;
      return (
        <>
          <div className="flex items-center justify-center w-full h-full">
            {pointCount}
            <div title={t('pages.dataSearch.create_csv')}>
              <Icon
                onClick={() => createCsvHandler(equipment_id, !isDownloaded)}
                name="DownloadArrowIcon"
                size={16}
                className={cn(
                  !isDownloaded ? 'opacity-20 hover:opacity-20' : 'opacity-90 hover:opacity-100',
                  'ml-[10px] text-action  text-[12px] cursor-pointer',
                )}
              />
            </div>
          </div>
        </>
      );
    },
    calculating: () => (
      <div className="flex items-center justify-center w-full h-full">
        {localStorage.getItem(FINDING_EQUIPMENT_KEY) === String(equipment_id) ? (
          <div>{t('pages.dataSearch.data_finding')}</div>
        ) : (
          <>{renderStatusData(equipment_id).counted(false)}</>
        )}
      </div>
    ),
    finished: () => {
      localStorage.removeItem(FINDING_EQUIPMENT_KEY);
      const renderName = (file: string) => file.split('/').pop()?.split('.')[0];
      const equipmentInDoc = csvLinks?.csv_data
        ? Object.values(csvLinks?.csv_data).filter((table) => {
            const arrayPath = table.split('.').shift()?.split('_');
            if (arrayPath) {
              return (
                arrayPath[arrayPath.length - 2].replace(/[table, _]/gm, '') === String(equipment_id)
              );
            }
            return false;
          })
        : [];
      if (equipmentInDoc.length) {
        return (
          <div>
            {csvLinks &&
              equipmentInDoc.map((file, key) => {
                return (
                  <div key={key} className="items-center flex w-full">
                    <span className="pr-[10px]">
                      <DocIcon size={13} />
                    </span>
                    <div
                      onClick={() => downloadCsvHandler(file)}
                      className="text-action opacity-90 hover:opacity-100 cursor-pointer"
                      rel="noreferrer"
                    >
                      {renderName(file)}.
                    </div>
                  </div>
                );
              })}
          </div>
        );
      }
      return renderStatusData(equipment_id).counted();
    },
  });

  const renderTableEquipments =
    localStorage.getItem('is_graph') !== 'true' && equipmentsByModel?.equipments ? (
      equipmentsByModel?.equipments.map((equipment) => {
        return (
          <Fragment key={equipment.equipment_id}>
            <div className={tableCellClassName('cell', 'start')}>
              <span>
                {equipment.description} <br />
                <Link
                  style={dataSearchVehicleLinkClassName}
                  className={`${dataSearchVehicleLinkClassName} text-action opacity-90 hover:opacity-100 text-[11px] cursor-pointer`}
                  to={`${navigationRouteList.vehicle}/${equipment.vehicle.vehicle_id}/info`}
                >
                  {equipment.vehicle_model.model_name}
                </Link>
              </span>
            </div>
            {Array.isArray(currentCellRange.period) ? (
              currentCellRange.period.map((value: string, key: number) => (
                <div key={key} className={tableCellClassName('cell')}>
                  {value}
                </div>
              ))
            ) : (
              <div className={tableCellClassName('title')}>{currentCellRange.period}</div>
            )}
            <div className={tableCellClassName()}>
              <div className="flex-1 center w-full">
                <div className=" w-full center flex justify-center">
                  {renderStatusData(equipment.equipment_id)[currentStatus]()}
                </div>
              </div>
            </div>
            {/*<div className={tableCellClassName()}>*/}
            {/*  <CircleButton*/}
            {/*    icon="ChartIcon"*/}
            {/*    size={24}*/}
            {/*    className="text-action"*/}
            {/*    onClick={() => {*/}
            {/*      console.log('test');*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </Fragment>
        );
      })
    ) : (
      <div className="col-span-5 flex center p-[20px] text-3color justify-center">
        {t('pages.dataSearch.not_found')}
      </div>
    );

  return (
    <div>
      <PageHeader icon="MagnifierTextSearch" label={t('pages.dataSearch.pageHeader.label')}>
        <div className="h-full flex items-center justify-end">
          {['process', 'calculating'].includes(String(filterData?.filter?.status)) ? (
            <Button label="Остановить поиск" onClick={resetFilterHandler} />
          ) : (
            <Button
              label={t('pages.dataSearch.add_edit_btn')}
              onClick={() => changeOpenFilter((prevState) => !prevState)}
            />
          )}
        </div>
      </PageHeader>
      <h1 className="font-[700] text-black text-[24px] pb-[30px] px-[20px]">
        {filterData?.filter?.model_id ? (
          <>
            {t('pages.dataSearch.searched_for')}
            {
              equipmentModels?.models?.find(
                (model) => model?.id === filterData?.filter?.model_id,
              )?.['name']
            }
          </>
        ) : (
          <>-</>
        )}
      </h1>
      <Box className={cn('grid', currentCellRange.grid)}>
        <div className={tableCellClassName('title', 'start')}>
          {t('pages.dataSearch.table.equipment')}
        </div>
        {Array.isArray(currentCellRange.title) ? (
          currentCellRange.title.map((title: string, key: number) => (
            <div key={key} className={tableCellClassName('title')}>
              {title}
            </div>
          ))
        ) : (
          <div className={tableCellClassName('title')}>{currentCellRange.title}</div>
        )}
        <div className={tableCellClassName('title')}>{t('pages.dataSearch.table.founded')}</div>
        {/*<div className={tableCellClassName('title')}>{t('pages.dataSearch.table.action')}</div>*/}
        {isLoading || isFetching ? (
          <div className="col-span-5 flex center p-[20px] text-3color justify-center">
            {t('pages.dataSearch.date_search')} ...
          </div>
        ) : (
          renderTableEquipments
        )}
      </Box>
      {!isEquipmentLoading && equipmentModels?.models && (
        <Modal
          label={t('pages.dataSearch.search_filter_title')}
          onClose={changeOpenFilter.bind(null, false)}
          show={isOpenFilter}
          withoutFooter
        >
          <FindDataForm
            onSubmitFilter={submitSearchFilterHandler}
            defaultServerFilter={
              localStorage.getItem('is_graph') !== 'true' ? filterData?.filter?.filter : null
            }
            modelsData={equipmentModels?.models || []}
            serverModelId={
              localStorage.getItem('is_graph') !== 'true' ? filterData?.filter?.model_id : undefined
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default DataSearchPage;
