import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ExtendedUser } from '@app/store/interfaces/user.type';
import { HelpCircleIcon, LogOutIcon } from '@icons';
import { Avatar } from '@ui';
import { navigationRouteList } from '@app/utils/routes';
import { useAppDispatch } from '@app/store';
import { logOut } from '@app/store/features/user.slice';
import authStorage from '@app/utils/auth';
import { useTranslation } from 'react-i18next';

type AuthMenuProps = {
  user: ExtendedUser | null;
};

const AuthMenuBlock: FC<AuthMenuProps> = (props) => {
  const { t } = useTranslation();
  const { user } = props;
  const dispatch = useAppDispatch();

  async function handlerLogout() {
    dispatch(logOut());
    await authStorage.logOut();
  }

  return (
    <ul className="inline-flex divide-x">
      <li className="px-[22px] items-center inline-flex">
        <NavLink to="/" className="inline-flex hover:opacity-80 ">
          <HelpCircleIcon size={20} className="mr-[4px]" /> {t('pages.layout.auth_menu_block.help')}
        </NavLink>
      </li>
      <li className="px-[22px] inline-flex items-center">
        <Avatar alt={user?.username} size={30} classname="mr-[8px] text-speech-analitics" />
        <div className="block max-w-[150px] overflow-hidden truncate">
          <NavLink to="/" className="mr-[8px] text-sm flex self-center">
            {user?.username}
          </NavLink>
        </div>
        <NavLink
          to={navigationRouteList.login}
          className="hover:opacity-80"
          onClick={handlerLogout}
        >
          <LogOutIcon size={16} />
        </NavLink>
      </li>
    </ul>
  );
};

export default AuthMenuBlock;
