import React, { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router';
import {
  ButtonProps,
  ButtonSize,
  ButtonVariant,
} from '@app/components/ui/button/types/button.type';

import Icon from '../icons/icon';
const Button: FC<ButtonProps> = (props) => {
  const {
    type = 'button',
    fill = 'filled',
    label,
    variant = 'primary',
    size = 'large',
    disabled = false,
    onClick,
    link,
    className,
    icon,
    sizeIcon = 14,
    // isFull = false,
    // hintTitle,
    // hintTitlePosition = 'bottom',
    ...remindedProps
  } = props;
  const navigate = useNavigate();
  const buttonSize: Record<ButtonSize, string> = {
    large: 'h-[34px]',
    medium: 'h-[29px]',
    small: 'h-[23px]',
  };
  const variantListStyle: Record<ButtonVariant, string> = {
    primary: cn(
      fill === 'filled' &&
        (disabled
          ? 'border-bg-b-light bg-b-light text-4color border-[1px]'
          : 'border-[1px] border-action bg-action text-white'),
      fill === 'outlined' &&
        (disabled
          ? 'text-4color border-b-light bg-transparent'
          : 'text-action border-[1px] border-action bg-transparent'),
      fill === 'linked' &&
        (disabled
          ? 'border-[1px] text-4color border-transparent'
          : 'border-[1px] border-transparent text-action'),
    ),
    danger: cn(
      fill === 'filled' &&
        (disabled
          ? 'bg-red_pal text-white border-[1px] border-red_pal'
          : 'bg-basic_red border-[1px] border-basic_red text-white'),
      fill === 'outlined' &&
        (disabled
          ? 'text-red_pal border-red_pal border-[1px] bg-transparent'
          : 'text-basic_red border-[1px] border-basic_red bg-transparent'),
      fill === 'linked' &&
        (disabled ? 'text-red_pal border-transparent' : 'text-basic_red border-transparent'),
    ),
  };

  const renderButtonIcon = icon && (
    <Icon
      name={icon}
      size={sizeIcon}
      className={cn(variantListStyle[variant], 'border-none', !!label && 'mr-[10px]')}
    />
  );

  function handlerOnClickButton(event: MouseEvent<HTMLButtonElement>) {
    if (link) navigate(link);
    else onClick?.(event);
  }
  return (
    <div
      className={`${
        !disabled && 'active:scale-95 shadow-lg hover:opacity-90'
      } inline-block w-full `}
    >
      <button
        type={type}
        onClick={(event) => handlerOnClickButton(event)}
        className={cn(
          'w-full',
          variantListStyle[variant],
          'rounded-defaultR text-[15px] font-bold',
          'transition',
          'flex items-center justify-center',
          label ? 'px-[18px]' : 'px-[11px]',
          buttonSize[size],
          className || '',
        )}
        disabled={disabled}
        {...remindedProps}
      >
        <div className={cn(variantListStyle[variant], 'border-none', 'inline-flex items-center')}>
          {renderButtonIcon}
          <div className={cn('truncate border-none text-[14px] font-[700] leading-[17px]')}>
            {label}
          </div>
        </div>
      </button>
    </div>
  );
};

export default Button;
