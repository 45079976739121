import { ACCESS_TOKEN, REFRESH_TOKEN } from '@app/utils/constants';

const authStorage = {
  signIn: async (tokens: { access?: string; refresh?: string }) => {
    if (tokens) {
      const { refresh, access } = tokens;
      if (refresh && access) {
        await localStorage.setItem(ACCESS_TOKEN, access);
        await localStorage.setItem(REFRESH_TOKEN, refresh);
      }
    }
  },
  logOut: async () => {
    await localStorage.removeItem(ACCESS_TOKEN);
    await localStorage.removeItem(REFRESH_TOKEN);
  },
};

export default authStorage;
