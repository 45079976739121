import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CustomDatePicker, Input, Select } from '@ui';
import { OptionItem } from '@app/components/ui/select/types/select.type';
import { EquipmentModel } from '@appTypes/models.type';
import {
  FilterCondition,
  FilterCreateResponse,
  FilterData,
  FilterRangeType,
  Range,
} from '@appTypes/search-data.type';

import { Filter, FilterItem } from './types';
import { defaultFilterForm, metaDateTime, metaDateTimeCalendar } from './data';
import { Field } from './blocks';
import { cellBlockClassName, textStyleClassName } from './styles';

type FindDataFormProps = {
  modelsData: Array<EquipmentModel>;
  defaultServerFilter?: FilterData | null;
  serverModelId?: number;
  onSubmitFilter: (data: FilterCreateResponse) => void;
};

const FindDataForm: FC<FindDataFormProps> = (props) => {
  const { modelsData, onSubmitFilter, defaultServerFilter, serverModelId } = props;
  const [modelInfo, setModelInfo] = useState<{
    model: EquipmentModel;
    fields: Array<OptionItem>;
  } | null>();

  const [rangeType, setRangeType] = useState<FilterRangeType>(
    defaultServerFilter?.range.type || 'n',
  );
  const refFormScroller = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const methods = useForm<Filter & Range>({
    defaultValues: defaultFilterForm[rangeType || 'n'],
  });
  const { handleSubmit, setValue, watch, reset, getValues } = methods;
  function getRangeSelectOptions(): OptionItem[] {
    return metaDateTime.map((item) => ({ value: item.id, title: item.name }));
  }

  const decodeFilterCheckToFilterForm = useCallback(
    (data?: FilterData | null): Range & Filter => {
      if (data) {
        return {
          range: data.range,
          filter: [
            ...(data.tables
              ? data.tables.reduce<FilterItem[]>((filter, table) => {
                  if (table?.filter === null) return filter;
                  const fields: FilterItem[] = [];
                  let currentIndex = 0;
                  table.filter?.forEach((tableFilter) => {
                    if (tableFilter) {
                      setValue(`filter.${currentIndex++}.value`, [tableFilter.value]);
                      fields.push({
                        id: `${table.name}&${tableFilter.id}`,
                        name: tableFilter.id,
                        value: [tableFilter.value],
                        condition: tableFilter.condition,
                      });
                    }
                  });
                  if (fields) return [...filter, ...fields];
                  return filter;
                }, [])
              : []),
            {
              id: '',
              name: '',
              condition: '<',
              value: [],
            },
          ],
        };
      } else {
        return defaultFilterForm['n'];
      }
    },
    [setValue],
  );
  function onSubmit(data: Filter & Range): void {
    if (modelInfo?.model) {
      data.filter.pop();
      const dataRange = data.range.parameters.map((parameter) => {
        return data.range.type === 'r' ? parameter : { ...parameter, value: `${parameter.value}` };
      });
      const resultOfFilter: {
        [table_name: string]: Array<{
          id: string;
          condition: FilterCondition;
          value: string | number;
        }>;
      } = {};
      for (const structureTable of modelInfo.model.clickhouse_structure.tables) {
        resultOfFilter[structureTable.name] = [];
      }
      for (const filter of data['filter']) {
        const [table, field] = filter.id.split('&');
        for (const value of filter.value) {
          if (field.length > 0) {
            resultOfFilter[table]?.push({
              id: field,
              condition: filter.condition,
              value: isNaN(Number(value)) ? String(value) : Number(value),
            });
          }
        }
      }
      const tableOfFilter = Object.entries(resultOfFilter).map(([table, filter]) => {
        return {
          name: table,
          time_delta:
            table.split('_')[1] === 'Min'
              ? Number(table.split('_')[0]) * 60
              : Number(table.split('_')[0]),
          filter: filter.length ? filter : null,
        };
      });
      onSubmitFilter?.({
        body: {
          range: { ...data.range, parameters: dataRange },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tables: tableOfFilter,
        },
        model_id: modelInfo?.model.id,
      });
    }
  }

  function onChangeCalendarIntervalHandler(value: string) {
    setValue('range.type', value as FilterRangeType);
  }

  const onChangeModel = useCallback(
    (model_id: number) => {
      // eslint-disable-next-line no-alert
      const currentModel = modelsData.find(({ id }) => id === model_id);
      if (currentModel) {
        const buildFieldsDataOptions: Array<OptionItem> =
          currentModel?.clickhouse_structure.tables.reduce<Array<OptionItem>>((fields, table) => {
            const fieldsName = table.fields.map((field) => ({
              value: `${table.name}&${field.name}`,
              title: field.description || field.name,
            }));
            fields.push(...fieldsName);
            return fields;
          }, []);
        setModelInfo(currentModel ? { model: currentModel, fields: buildFieldsDataOptions } : null);
      } else {
        setModelInfo(null);
      }
    },
    [modelsData],
  );

  const renderModelSelectOptions: Array<OptionItem> = modelsData.map(({ id, name }) => ({
    value: id,
    title: name,
  }));

  const renderRangeCondition = {
    n: <div></div>,
    r: (
      <div className={'flex flex-col items-start gap-[35px]'}>
        <div>{t('components.findData.filter.from')}</div>
        <div>{t('components.findData.filter.before')}</div>
      </div>
    ),
    l: <div></div>,
    cd: <div>=</div>,
  };

  function changeTimeEventHandler(data: {
    timeData: string;
    type: 'date' | 'time';
    position: number;
  }) {
    const timeDateList = ['date', 'time'];
    const conditions = ['>', '<'];
    const currentValue = getValues(`range.parameters.${data.position}.value`);
    setValue(
      `range.parameters.${data.position}.condition`,
      conditions[data.position] as FilterCondition,
    );
    if (currentValue) {
      const dateValue = currentValue.split(' ');
      dateValue[timeDateList.indexOf(data.type)] = data.timeData;
      setValue(`range.parameters.${data.position}.value`, dateValue.join(' '));
    } else {
      const resultOfChange: Array<string> = Array(2).fill('');
      resultOfChange[timeDateList.indexOf(data.type)] = data.timeData;
      setValue(`range.parameters.${data.position}.value`, resultOfChange.join(' '));
    }
  }
  const renderRangeValue = {
    n: <div></div>,
    r: (
      <div className="flex flex-col gap-[15px]">
        <div className="flex items-center gap-[11px]">
          <CustomDatePicker
            selectedDate={
              defaultServerFilter?.range.parameters?.[0]?.value.split(' ')[0]
                ? moment(
                    String(defaultServerFilter?.range.parameters?.[0]?.value.split(' ')[0]),
                  ).toDate()
                : moment().toDate()
            }
            format="yyyy-MM-DD"
            onChange={(value) =>
              changeTimeEventHandler({ timeData: value, type: 'date', position: 0 })
            }
          />
          <Input
            mask="99:99"
            defaultValue={
              watch('range.parameters.0.value')?.split(' ')[1] ||
              defaultServerFilter?.range.parameters?.[0]?.value
                ?.split(' ')[1]
                ?.split(':')
                ?.slice(0, 2)
                ?.join(':') ||
              '0000'
            }
            onChange={(event) =>
              changeTimeEventHandler({ timeData: event.target.value, type: 'time', position: 0 })
            }
          />
        </div>
        <div className={'flex items-center gap-[11px]'}>
          <CustomDatePicker
            selectedDate={
              defaultServerFilter?.range.parameters?.[1]?.value.split(' ')[0]
                ? moment(
                    String(defaultServerFilter?.range.parameters?.[1].value.split(' ')[0]),
                  ).toDate()
                : moment().toDate()
            }
            format="yyyy-MM-DD"
            onChange={(value) =>
              changeTimeEventHandler({ timeData: value, type: 'date', position: 1 })
            }
          />
          <Input
            defaultValue={
              watch('range.parameters.1.value')?.split(' ')[1] ||
              defaultServerFilter?.range.parameters?.[1]?.value
                ?.split(' ')[1]
                ?.split(':')
                ?.slice(0, 2)
                ?.join(':') ||
              '0100'
            }
            mask="99:99"
            onChange={(event) =>
              changeTimeEventHandler({ timeData: event.target.value, type: 'time', position: 1 })
            }
          />
        </div>
      </div>
    ),
    l: (
      <div className={'w-full flex items-center gap-[15px]'}>
        <div className={textStyleClassName}>{t('components.findData.filter.last')}</div>
        <Input
          type="number"
          defaultValue={watch('range.parameters.0.value')}
          onChange={(event) => setValue('range.parameters.0.value', event.target.value)}
        />
        <div className={textStyleClassName}>{t('components.findData.filter.days')}</div>
      </div>
    ),
    c: (
      <div className="flex-1">
        <Select
          defaultValue={metaDateTimeCalendar[0].value}
          onChange={(value) => onChangeCalendarIntervalHandler(String(value))}
          options={metaDateTimeCalendar}
        />
      </div>
    ),
    cd: (
      <div className="flex-1">
        <Select
          defaultValue={metaDateTimeCalendar[0].value}
          onChange={(value) => onChangeCalendarIntervalHandler(String(value))}
          options={metaDateTimeCalendar}
        />
      </div>
    ),
    cm: (
      <div className="flex-1">
        <Select
          defaultValue={metaDateTimeCalendar[2].value}
          onChange={(value) => onChangeCalendarIntervalHandler(String(value))}
          options={metaDateTimeCalendar}
        />
      </div>
    ),
    cw: (
      <div className="flex-1">
        <Select
          defaultValue={metaDateTimeCalendar[1].value}
          onChange={(value) => onChangeCalendarIntervalHandler(String(value))}
          options={metaDateTimeCalendar}
        />
      </div>
    ),
  };

  const resetAndSetDefault = useCallback(
    (value: string | number) => {
      setRangeType(value as FilterRangeType);
      reset(defaultFilterForm[String(value)]);
    },
    [reset],
  );
  useEffect(() => {
    if (modelInfo?.model.id && serverModelId !== modelInfo?.model.id) {
      onChangeModel(modelInfo?.model.id);
    } else {
      serverModelId && onChangeModel(serverModelId);
    }
    defaultServerFilter?.range.type && resetAndSetDefault(defaultServerFilter?.range.type);
    reset(decodeFilterCheckToFilterForm(defaultServerFilter));
  }, [
    decodeFilterCheckToFilterForm,
    defaultServerFilter,
    modelInfo?.model,
    onChangeModel,
    reset,
    resetAndSetDefault,
    serverModelId,
  ]);

  return (
    <div className="h-full min-h-[320px] pt-[40px] px-[10px]">
      <div className="w-full">
        <FormProvider {...methods}>
          <form className="relative h-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="h-full  w-full ">
              <div className="sticky top-0 z-40 bg-white">
                <div className="w-full flex items-center gap-[20px] z-20">
                  <div className="whitespace-nowrap text-1color text-[12px] leading-[13px] font-[600]">
                    {t('components.findData.filter.chooseModelFormSearch')}
                  </div>
                  <div className="w-full">
                    <Select
                      isEmpty
                      defaultValue={modelInfo?.model.id || serverModelId || undefined}
                      placeholder={t('components.findData.filter.select.placeholder')}
                      onChange={(value) => onChangeModel(Number(value))}
                      options={renderModelSelectOptions}
                    />
                  </div>
                </div>
                <div className="px-[24]">
                  <div className="border-solid border-[1px] w-full  border-dividerColor  my-[24px]"></div>
                </div>
              </div>
              {(modelInfo?.model || serverModelId) && (
                <>
                  <div className="flex-1">
                    <div className=" h-full">
                      <div className="py-4 relative bg-action/[0.1] rounded-[12px] border-solid border-l-[2px] border-action px-[24px]">
                        <div className={'flex items-start gap-[16px]'}>
                          <div className={'w-[327px]'}>
                            <Select
                              defaultValue={
                                rangeType.substring(0, 1) ||
                                defaultServerFilter?.range.type?.substring(0, 1)
                              }
                              onChange={resetAndSetDefault}
                              options={getRangeSelectOptions().splice(0, 4)}
                            />
                          </div>
                          <div
                            className={`w-[100px] flex self-center justify-center ${textStyleClassName}`}
                          >
                            {renderRangeCondition[rangeType]}
                          </div>
                          <div className={'w-[380px] flex self-center'}>
                            {renderRangeValue[rangeType]}
                          </div>
                        </div>
                      </div>
                    </div>
                    {modelInfo?.fields.length ? (
                      <Field scroller={refFormScroller} fieldsOption={modelInfo?.fields} />
                    ) : (
                      <div className="pt-[16px]">
                        <div
                          className={cn(
                            cellBlockClassName,
                            'px-[32px] text-4color',
                            'justify-center',
                          )}
                        >
                          {t('components.findData.model_unselect')}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="py-[24px] px-[32px] pt-[20px] w-full bg-white sticky bottom-0">
                    <Button
                      fill={'outlined'}
                      disabled={
                        rangeType === 'n' ||
                        String(watch().filter[0].id)?.split('')?.length < 1 ||
                        String(watch().filter[0].value[0])?.split('')?.length < 1
                      }
                      label={t('components.findData.filter.applyButton')}
                      type={'submit'}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      <div ref={refFormScroller} />
    </div>
  );
};

export default FindDataForm;
