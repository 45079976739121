import React from 'react';
import AuthMenuBlock from '@app/pages/layout/blocks/auth-menu.block';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '@app/store';
import logoIcon from '@app/assets/images/newlogo.png';
import { UseGetMainPageUrl } from '@app/hooks/use-get-main-page-url';

const HeaderBlock = () => {
  const user = useAppSelector((state) => state.userState.user);
  const mainPage = UseGetMainPageUrl();
  return (
    <div className="bg-white flex justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={mainPage} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px] ml-[14px]">
          <img className="h-[53px]" src={logoIcon} alt="LOGO" />
        </div>
      </NavLink>
      <AuthMenuBlock user={user} />
    </div>
  );
};

export default HeaderBlock;
