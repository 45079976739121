import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '@ui';

import { useNavigate } from 'react-router-dom';

import { UseGetMainPageUrl } from '@app/hooks/use-get-main-page-url';

import logoIcon from '../../assets/images/logo.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainPage = UseGetMainPageUrl();
  return (
    <div className="w-screen h-screen flex items-center justify-center bg-basic_app_bg top-0 left-0 z-[99]">
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col items-center gap-[20px]">
          <img src={logoIcon} alt="logo" />
          <span className="text-3color text-[35px] font-[500] leading-[110%] tracking-tight">
            ОШИБКА
          </span>
          <span className="text-3color text-[18px] font-[500] leading-[110%] tracking-tight">
            {t('pages.notFound.error_message')}
          </span>
          <div className="flex items-center gap-[20px]">
            <Button
              onClick={() => navigate(-1)}
              fill="linked"
              label={t('pages.notFound.backButton')}
            />
            <Button
              onClick={() => navigate(mainPage)}
              fill="linked"
              label={t('pages.notFound.mainPageButton')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
