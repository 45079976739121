import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const EyeIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg width={size} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M9 11.9204C14.2051 11.9204 17.7153 7.72461 17.7153 6.42334C17.7153 5.12207 14.1987 0.932617 9 0.932617C3.87109 0.932617 0.27832 5.12207 0.27832 6.42334C0.27832 7.72461 3.86475 11.9204 9 11.9204ZM9 10.7017C4.94385 10.7017 1.71924 7.31201 1.71924 6.42334C1.71924 5.68066 4.94385 2.15137 9 2.15137C13.0498 2.15137 16.2808 5.68066 16.2808 6.42334C16.2808 7.31201 13.0498 10.7017 9 10.7017ZM9 9.84473C10.9043 9.84473 12.4277 8.28955 12.4277 6.42334C12.4277 4.5127 10.9043 3.0083 9 3.0083C7.08936 3.0083 5.55957 4.5127 5.56592 6.42334C5.57861 8.28955 7.08936 9.84473 9 9.84473ZM9 7.52783C8.38428 7.52783 7.88281 7.03271 7.88281 6.42334C7.88281 5.81396 8.38428 5.31885 9 5.31885C9.61572 5.31885 10.1108 5.81396 10.1108 6.42334C10.1108 7.03271 9.61572 7.52783 9 7.52783Z"
        />
      </svg>
    </div>
  );
};

export default EyeIcon;
