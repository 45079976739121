type NavigationOutlets =
  | 'login'
  | 'emergencyInformation'
  | 'settings'
  | 'main'
  | 'dataSearch'
  | 'vehicle';
export type NavigationRoute =
  | 'main'
  | 'login'
  | 'remindPassword'
  | 'newPassword'
  | 'loginAccess'
  | 'dataSearch'
  | 'emergencyInformation'
  | 'informingSettings'
  | 'settings'
  | 'settingsUsers'
  | 'settingsVehicles'
  | 'settingsEquipment'
  | 'settingsPermission'
  | 'vehicle'
  | 'notFounded';

const navigationOutletRoutes: Record<NavigationOutlets, string> = {
  main: '/',
  vehicle: '/vehicle',
  login: '/login',
  emergencyInformation: '/emergency-info',
  settings: '/settings',
  dataSearch: '/data-search',
};
export const navigationRouteList: Record<NavigationRoute, string> = {
  //Главная - Список ТС
  main: navigationOutletRoutes.main,
  /* страница просмотра ТС */
  vehicle: navigationOutletRoutes.vehicle,
  //Авторизация
  login: navigationOutletRoutes.login,
  remindPassword: `${navigationOutletRoutes.login}/remind`,
  newPassword: `${navigationOutletRoutes.login}/recovery`,
  loginAccess: `${navigationOutletRoutes.login}/access`,
  // Данные
  dataSearch: navigationOutletRoutes.dataSearch,
  // Аварийное информирование
  emergencyInformation: navigationOutletRoutes.emergencyInformation,
  informingSettings: `${navigationOutletRoutes.emergencyInformation}/informing`,
  //Настройки
  settings: navigationOutletRoutes.settings,
  settingsUsers: `${navigationOutletRoutes.settings}/users`,
  settingsVehicles: `${navigationOutletRoutes.settings}/vehicles`,
  settingsEquipment: `${navigationOutletRoutes.settings}/equipment`,
  settingsPermission: `${navigationOutletRoutes.settings}/permissions`,
  // Внутренние страницы
  notFounded: '/404',
};
