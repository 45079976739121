import { createApi } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

import { CreateUserRequest, ExtendedUser, User, UsersList } from '@app/store/interfaces/user.type';
import { setUser } from '@app/store/features/user.slice';
import { endpointUrl } from '@app/utils/endpoint-url';
import { CommonPagination } from '@appTypes/common-pagination.type';

import { UseGetFormattedPermissions } from '@app/hooks/use-get-formatted-permissions';

import customFetchBase from '../customFetchBase';

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  tagTypes: ['User', 'UsersList'],
  endpoints: (builder) => ({
    getUsers: builder.query<UsersList, CommonPagination>({
      query(params) {
        return {
          url: endpointUrl.userList,
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({ user_id }) => ({
                type: 'UsersList' as const,
                user_id,
              })),
              'UsersList',
            ]
          : ['UsersList'],
    }),
    getOrganizationUsers: builder.query<UsersList, { operator_id: number } & CommonPagination>({
      query(params) {
        return {
          url: endpointUrl.userList,
          params,
        };
      },
    }),
    getUserSettings: builder.query<User, { user_id: string }>({
      query(params) {
        return {
          url: endpointUrl.adminUserCRUD,
          params,
        };
      },
    }),
    deleteUser: builder.mutation<UsersList, { user_id: string }>({
      query({ user_id }: { user_id: string }) {
        return {
          url: endpointUrl.adminUserCRUD,
          method: 'DELETE',
          params: { user_id },
        };
      },
      invalidatesTags: ['UsersList'],
    }),
    addUser: builder.mutation<User, CreateUserRequest>({
      query(body) {
        return {
          method: 'POST',
          url: endpointUrl.adminUserCRUD,
          body,
        };
      },
      invalidatesTags: ['UsersList'],
    }),
    editUser: builder.mutation<
      User,
      {
        params: { user_id: string };
        body: {
          CreateUserRequest;
        };
      }
    >({
      query({ body, params }) {
        return {
          method: 'PUT',
          url: endpointUrl.adminUserCRUD,
          body,
          params,
        };
      },
      invalidatesTags: ['UsersList'],
    }),
    getMe: builder.query<ExtendedUser, null>({
      query() {
        return {
          url: endpointUrl.me,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setUser({
              ...data,
              permissions: UseGetFormattedPermissions({
                permissions: data.permissions,
                organization_type: data.vendor,
              }),
            }),
          );
        } catch (err) {
          toast(err.message, { type: 'error' });
        }
      },
    }),
  }),
});
export default userApi;
export const {
  useGetMeQuery,
  useGetUsersQuery,
  useGetOrganizationUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
} = userApi;
