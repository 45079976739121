import React from 'react';
// import { AuthLoginRequest } from '@app/store/interfaces/auth.type';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { APP_NAME } from '@app/utils/constants';

import logoIcon from '../../assets/images/logo.svg';

const AuthPage = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <Helmet>
        <title>
          {t('pages.login.title')} | {APP_NAME}
        </title>
      </Helmet>
      <div className={'mb-[50px]'}>
        <img src={logoIcon} alt="logo" />
      </div>
      <Outlet />
    </div>
  );
};

export default AuthPage;
