import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const TemplateListIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 18 19">
        <path
          fill="currentColor"
          d="M2.02 16.307c0 1.735.855 2.598 2.573 2.598h8.815c1.719 0 2.573-.863 2.573-2.598V3.715c0-1.727-.854-2.607-2.573-2.607H4.593c-1.718 0-2.573.88-2.573 2.607v12.592zm1.336-.025V3.74c0-.83.44-1.295 1.303-1.295h8.683c.863 0 1.303.465 1.303 1.295v12.542c0 .83-.44 1.287-1.303 1.287H4.659c-.863 0-1.303-.457-1.303-1.287zm2.415-8.475h1.403c.25 0 .407-.133.465-.349l.324-1.286.714 3.478c.091.465.697.465.838.016l.805-2.805.141.573c.05.24.216.373.465.373h1.303c.241 0 .432-.19.432-.423a.424.424 0 00-.431-.432h-1.046l-.432-1.668c-.108-.432-.689-.424-.83.016l-.789 2.574-.747-3.71a.414.414 0 00-.813-.01l-.68 2.798H5.77a.427.427 0 100 .855zm.083 4.416h6.3a.5.5 0 00.507-.514.498.498 0 00-.506-.507h-6.3a.5.5 0 00-.515.507c0 .29.216.514.514.514zm0 2.897h2.98a.5.5 0 00.515-.506.502.502 0 00-.515-.515h-2.98a.502.502 0 00-.514.515.5.5 0 00.514.506z"
        ></path>
      </svg>
    </div>
  );
};

export default TemplateListIcon;
