import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const BigChartIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 149 59">
        <path
          fill="url(#paint0_linear_7360_88261)"
          d="M1 11.192c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.46 1.03 1.03v32.635H1V11.192z"
        ></path>
        <path
          fill="url(#paint1_linear_7360_88261)"
          d="M11 16.062c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.46 1.03 1.03v27.766h-7V16.062z"
        ></path>
        <path
          fill="url(#paint2_linear_7360_88261)"
          d="M21.333 27.284c0-.568.46-1.03 1.03-1.03h4.94c.57 0 1.03.462 1.03 1.03v16.545h-7V27.284z"
        ></path>
        <path
          fill="url(#paint3_linear_7360_88261)"
          d="M31.333 10.133c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.461 1.03 1.03v33.694h-7V10.133z"
        ></path>
        <path
          fill="url(#paint4_linear_7360_88261)"
          d="M41.333 27.284c0-.568.46-1.03 1.03-1.03h4.94c.57 0 1.03.462 1.03 1.03v16.545h-7V27.284z"
        ></path>
        <path
          fill="url(#paint5_linear_7360_88261)"
          d="M51.333 11.192c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.46 1.03 1.03v32.635h-7V11.192z"
        ></path>
        <path
          fill="url(#paint6_linear_7360_88261)"
          d="M61.333 23.26c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.461 1.03 1.03v20.567h-7V23.26z"
        ></path>
        <path
          fill="url(#paint7_linear_7360_88261)"
          d="M71.666 1.03c0-.57.461-1.03 1.03-1.03h4.941c.569 0 1.03.46 1.03 1.03v42.798h-7V1.03z"
        ></path>
        <path
          fill="url(#paint8_linear_7360_88261)"
          d="M82 23.26c0-.568.46-1.029 1.03-1.029h4.94c.57 0 1.03.461 1.03 1.03v20.567h-7V23.26z"
        ></path>
        <path
          fill="url(#paint9_linear_7360_88261)"
          d="M92 7.17c0-.569.46-1.03 1.03-1.03h4.94c.57 0 1.03.461 1.03 1.03v36.659h-7V7.169z"
        ></path>
        <path
          fill="url(#paint10_linear_7360_88261)"
          d="M102 10.133a1.03 1.03 0 011.029-1.029h4.942c.568 0 1.029.461 1.029 1.03v33.694h-7V10.133z"
        ></path>
        <path
          fill="url(#paint11_linear_7360_88261)"
          d="M112 25.379c0-.569.461-1.03 1.029-1.03h4.942c.568 0 1.029.461 1.029 1.03v18.45h-7v-18.45z"
        ></path>
        <path
          fill="url(#paint12_linear_7360_88261)"
          d="M122 25.802a1.03 1.03 0 011.029-1.03h4.942c.568 0 1.029.461 1.029 1.03v18.026h-7V25.801z"
        ></path>
        <path
          fill="url(#paint13_linear_7360_88261)"
          d="M131.667 7.804c0-.568.46-1.029 1.029-1.029h4.941c.569 0 1.029.461 1.029 1.03v36.023h-6.999V7.804z"
        ></path>
        <path
          fill="url(#paint14_linear_7360_88261)"
          d="M142 11.143a1.03 1.03 0 011.029-1.029h4.942c.568 0 1.029.461 1.029 1.03v32.684h-7V11.143z"
        ></path>
        <rect width="43" height="6.743" y="52.257" fill="#F0F1F2" rx="1.422"></rect>
        <rect width="43" height="6.743" x="49" y="52.257" fill="#F0F1F2" rx="1.422"></rect>
        <defs>
          <linearGradient
            id="paint0_linear_7360_88261"
            x1="4.5"
            x2="4.5"
            y1="10.163"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_7360_88261"
            x1="14.5"
            x2="14.5"
            y1="15.033"
            y2="43.829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_7360_88261"
            x1="24.833"
            x2="24.833"
            y1="26.255"
            y2="43.829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint3_linear_7360_88261"
            x1="34.833"
            x2="34.833"
            y1="9.104"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint4_linear_7360_88261"
            x1="44.833"
            x2="44.833"
            y1="26.255"
            y2="43.829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint5_linear_7360_88261"
            x1="54.833"
            x2="54.833"
            y1="10.163"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint6_linear_7360_88261"
            x1="64.833"
            x2="64.833"
            y1="22.231"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint7_linear_7360_88261"
            x1="75.166"
            x2="75.166"
            y1="0"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint8_linear_7360_88261"
            x1="85.5"
            x2="85.5"
            y1="22.231"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint9_linear_7360_88261"
            x1="95.5"
            x2="95.5"
            y1="6.141"
            y2="43.829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint10_linear_7360_88261"
            x1="105.5"
            x2="105.5"
            y1="9.104"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint11_linear_7360_88261"
            x1="115.5"
            x2="115.5"
            y1="24.35"
            y2="43.829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint12_linear_7360_88261"
            x1="125.5"
            x2="125.5"
            y1="24.773"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint13_linear_7360_88261"
            x1="135.166"
            x2="135.166"
            y1="6.775"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
          <linearGradient
            id="paint14_linear_7360_88261"
            x1="145.5"
            x2="145.5"
            y1="10.114"
            y2="43.828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#55A9E5"></stop>
            <stop offset="1" stopColor="#88A5F0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default BigChartIcon;
