import React, { FC } from 'react';

import { IconType } from '../icon.type';

const folderOpenIcon: FC<IconType> = (props) => {
  const { size, className } = props;

  return (
    <div className={className}>
      <svg width={size} viewBox="0 0 89 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4865_80083)">
          <path
            d="M61.0919 3.73329C61.5517 2.43035 62.8434 1.50464 64.2042 1.50464H85.4799C87.0932 1.50464 88.2494 2.79153 88.0633 4.3754L82.5696 50.8079C82.3809 52.3945 80.9218 53.6787 79.3059 53.6787H11.7896C10.1763 53.6787 9.02012 52.3918 9.20617 50.8079L13.8201 11.8051C14.0088 10.2186 15.468 8.93438 17.0839 8.93438H53.8228C57.0042 8.93705 60.0181 6.77796 61.0919 3.73329Z"
            fill="currentColor"
          />
          <path
            d="M10.4343 51.5436H80.9726L84.9912 3.76538H14.4529L10.4343 51.5436Z"
            fill="white"
          />
          <path
            d="M10.4343 51.5436H80.9726L83.8802 3.76538H14.4529L10.4343 51.5436Z"
            fill="#E0E0E0"
          />
          <path
            d="M10.4343 51.5437H80.9726L83.3965 2.24841H12.8582L10.4343 51.5437Z"
            fill="white"
          />
          <path
            d="M10.4343 51.5437H80.9726L81.932 2.24841H12.8582L10.4343 51.5437Z"
            fill="#E0E0E0"
          />
          <path
            d="M10.4343 51.5436H80.9726L81.3925 0.910645H10.8543L10.4343 51.5436Z"
            fill="white"
          />
          <path
            d="M10.4342 51.5436H80.9724L79.5239 1.86841H9.44019L10.4342 51.5436Z"
            fill="#E0E0E0"
          />
          <path
            d="M10.4342 51.5437H80.9724L78.8781 2.24841H8.33984L10.4342 51.5437Z"
            fill="white"
          />
          <path
            d="M10.4342 51.5437H80.9724L77.6369 2.99219H7.09863L10.4342 51.5437Z"
            fill="#E0E0E0"
          />
          <path
            d="M10.4342 51.5436H80.9725L77.1054 3.40686H6.56714L10.4342 51.5436Z"
            fill="white"
          />
          <path
            d="M45.7564 6.74847C45.8016 5.41342 46.8009 4.46631 48.1617 4.46631H69.4375C71.0508 4.46631 72.6162 5.78263 72.9299 7.40931L81.3338 50.741C81.6501 52.365 80.595 53.684 78.9817 53.684H11.4707C9.85741 53.684 8.29196 52.3676 7.97834 50.741L1.04943 15.0237C0.73315 13.3997 1.7883 12.0807 3.40159 12.0807H40.1378C43.3192 12.078 45.6475 9.86805 45.7564 6.74847Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_4865_80083">
            <rect width="89" height="54" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default folderOpenIcon;
