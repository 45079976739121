import cn from 'classnames';

export const cellBlockClassName = cn(
  'py-4',
  'flex',
  'items-center',
  'w-full',
  'relative',
  'bg-basic_app_bg',
  'rounded-[12px]',
  'border-solid border-l-[2px]',
  'border-action',
  'px-[24px]',
);
export const nestedFieldsBlockClassName = cn(
  'h-[17px]',
  'w-full',
  'flex',
  'flex-1',
  'items-center',
  'justify-center',
  'text-[14px]',
  'leading-[17px]',
  'font-[400]',
  'text-4color',
);
export const textStyleClassName = cn('font-[500]', 'text-[14px]', 'leading-[16px]', 'text-3color');
