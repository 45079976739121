import React, { FC } from 'react';
import cn from 'classnames';

type SkeletonRound = 'small' | 'full';
type SkeletonProps = {
  width?: string | number;
  height?: string | number;
  round?: SkeletonRound;
  className?: string;
  label?: string;
  count?: number;
};

const DEFAULT_SIZES = {
  width: '100%',
  height: '80px',
};
const Skeleton: FC<SkeletonProps> = (props) => {
  const { width, count = 1, height, round = 'smail', className, label } = props;
  const roundedStyle: Record<SkeletonRound, string> = {
    small: 'rounded-[8px]',
    full: 'rounded-full',
  };
  const sizeStyle = {
    width: width ? (typeof width === 'number' ? `${width}px` : width) : DEFAULT_SIZES.width,
    height: height ? (typeof height === 'number' ? `${height}px` : height) : DEFAULT_SIZES.height,
  };
  return (
    <>
      {Array(count).map((_, index) => (
        <div
          key={index}
          className={cn('animate-pulse bg-3color', roundedStyle[round], className)}
          style={sizeStyle}
        >
          <div className="flex w-full, h-full items-center justify-center font-bold">{label}</div>
        </div>
      ))}
    </>
  );
};

export default Skeleton;
