import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { MessagesFilterForm } from '@app/pages/emergency-information/list-of-messages/blocks/messages-filter-form';
import { PageHeader, Paginator, Select, Table } from '@ui';
import { useTranslation } from 'react-i18next';
import { useGetMessagesQuery } from '@app/store/api/message.api';
import { MessageHistory, MessagesList } from '@appTypes/message.type';
import { TableColumn } from '@app/components/ui/table/types/table.type';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import moment from 'moment';
import { useGetVehiclesQuery } from '@app/store/api/vehicle.api';
import { OptionItem } from '@app/components/ui/select/types/select.type';

const ListOfMessagesPage = () => {
  const [paginationData, changedPaginationData] = useState<PaginationResponse>({
    limit: 10,
    offset: 0,
  });
  const [currentVehicleId, changeCurrentVehicleId] = useState<number>();
  const [messagesData, changeMessagesData] = useState<MessageHistory>();

  function handleDownloadMessage(message: MessagesList) {
    const parsedMessageData = JSON.parse(message.message);

    if (parsedMessageData.output_error) {
      Object.keys(parsedMessageData.output_error).forEach((key) => {
        parsedMessageData.output_error[key] = parsedMessageData.output_error[key].join(',\n');
      });
    }

    const formattedMessageData = JSON.stringify(parsedMessageData, null, 2);

    fileDownload(formattedMessageData, `(${message.vehicle_id}).txt`);
  }

  const { data: vehiclesList } = useGetVehiclesQuery({ limit: -1 });
  const { data, isLoading } = useGetMessagesQuery(
    {
      body: messagesData || {
        gt: `${moment().format('yyyy-MM-DD')} 23:59:59`,
        lt: `${moment().format('yyyy-MM-DD')} 00:00:00`,
      },
      params: { ...paginationData, vehicle_id: currentVehicleId as number, sortDesc: true },
    },
    { skip: !currentVehicleId },
  );
  const { t } = useTranslation();

  function handleChangeFilter(data) {
    changeMessagesData(data);
  }
  function getVehiclesOptionDataForSelect(): OptionItem[] {
    return (
      vehiclesList?.vehicles.map((vehicle) => ({
        title: `${vehicle.vehicle_id} (${vehicle.licence_plate})`,
        value: vehicle.vehicle_id,
      })) || []
    );
  }

  const columns: TableColumn[] = [
    {
      index: 'vehicle_id',
      title: t('pages.informationSettings.list_of_messages.table.TC'),
    },
    {
      index: 'install_time',
      title: t('pages.informationSettings.list_of_messages.table.event_time'),
    },
    {
      index: 'operator_name',
      title: t('pages.informationSettings.list_of_messages.table.operator'),
    },
    {
      index: 'download',
      title: t('pages.informationSettings.list_of_messages.table.error_message'),
      render: (record: MessagesList) => {
        return (
          <button className="ml-[65px]" onClick={() => handleDownloadMessage(record)}>
            {t('pages.informationSettings.button.save')}
          </button>
        );
      },
    },
  ];
  const getMessagesTableData = data?.message.length
    ? data?.message.map((message) => ({
        ...message,
        install_time: moment(message.install_time).format('yyyy.MM.DD hh:mm'),
        vehicle_id: `${message.vehicle_id} (${message.licence_plate})`,
      }))
    : [];
  useEffect(() => {
    if (!vehiclesList?.vehicles.length || currentVehicleId) return;
    changeCurrentVehicleId(vehiclesList.vehicles[0].vehicle_id);
  }, [currentVehicleId, vehiclesList?.vehicles]);
  return (
    <div>
      <PageHeader
        icon={'TextSquare'}
        label={t('pages.messagesList.pageHeader.label') as React.ReactNode}
      />
      <div className="flex items-center justify-end py-[10px]">
        <div className="text-1color text-[13px] font-medium w-[200px]">
          {t('pages.messagesList.choose_vehicle')}&nbsp;
        </div>
        <div className="w-[280px]">
          <Select
            defaultValue={currentVehicleId}
            onChange={(value) => changeCurrentVehicleId(value as number)}
            options={getVehiclesOptionDataForSelect()}
          />
        </div>
      </div>
      <MessagesFilterForm handleChangeFilter={handleChangeFilter} />
      <div className={'mt-[40px] flex flex-col gap-[40px]'}>
        <Table columns={columns} dataSource={getMessagesTableData} isPending={isLoading} />
        {!!getMessagesTableData.length && (
          <Paginator
            page={paginationData.offset + 1}
            limit={paginationData.limit}
            count={data?.total || 0}
            onChange={(page) => changedPaginationData(page)}
          />
        )}
      </div>
    </div>
  );
};

export default ListOfMessagesPage;
