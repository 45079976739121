import React, { useState } from 'react';
import cn from 'classnames';

import MenuBarElement from '@app/pages/layout/blocks/menu-bar/menu-bar-element';

import { ChevronLeftIcon } from '@app/components/ui/icons/icons-list';

import { menuBarData } from '@app/pages/layout/blocks/menu-bar/data/data';
import { MenuBarList } from '@app/pages/layout/blocks/menu-bar/types/types';
import AllowComponentPermission from '@app/hocs/allow-component-permission';

import { useTranslation } from 'react-i18next';

import {
  collapseBarClassName,
  collapsedButtonClassName,
  // gridButtonClassName,
  menuBarClassName,
} from './menu-bar.styles';

const BAR_COLLAPSED = 'BAR_COLLAPSED';

const MenuBar = () => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    Boolean(Number(localStorage.getItem(BAR_COLLAPSED))),
  );
  const data: MenuBarList[] = menuBarData.map((item) => ({
    ...item,
    menuItems: item.menuItems.map((item) => ({ ...item })),
  }));
  const renderMenuList = data.map((list) => {
    const items = list.menuItems.map((item) => (
      <MenuBarElement key={item.id} itemMenu={item} isCollapsed={isCollapsed} tag={item.tag} />
    ));
    return (
      <div key={list.id} className={cn(!isCollapsed && '', 'w-full')}>
        {isCollapsed ? (
          <div className="w-full bg-bg_4 h-[1px]" />
        ) : (
          <AllowComponentPermission permissionKey={list.titleTag}>
            <h3
              className={cn(
                list.title && 'mt-[25px]',
                'text-xs mb-[9px] font-[600] text-3color uppercase px-[40px] w-full',
              )}
            >
              {t(`components.menuTab.${list.title}`)}
            </h3>
          </AllowComponentPermission>
        )}
        <ul>{items}</ul>
      </div>
    );
  });

  const renderCollapsedButtonIcon = (
    <ChevronLeftIcon size={6} className={cn(isCollapsed && 'rotate-180', 'text-1color')} />
  );

  function handleCollapsedToggle() {
    setIsCollapsed((prevState) => !prevState);
    localStorage.setItem('BAR_COLLAPSED', String(Number(!isCollapsed)));
  }

  return (
    <aside className={menuBarClassName({ isCollapsed })}>
      <div className="inline-flex w-full flex-col h-full justify-between">
        <div className="h-full overflow-y-auto overflow-x-hidden mediumScrollBar w-full">
          {renderMenuList}
        </div>
        <div
          className={cn(
            'inline-flex mt-[10px]',
            isCollapsed ? 'flex-col-reverse h-[90px]' : 'items-end justify-end',
          )}
        >
          <div className={collapsedButtonClassName(isCollapsed)}>
            <div className={collapseBarClassName} onClick={handleCollapsedToggle.bind(null)}>
              {renderCollapsedButtonIcon}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default MenuBar;
