import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const MaxMonologDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 58 57">
        <circle cx="29" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_1252_12117)">
          <path
            fill="#2987CC"
            d="M28.616 38.165c-.813.283-2.535.808-3.932 1.252-1.3.404-2.34 1.616-2.73 3.231L20.167 50h23.07l-1.592-7.352c-.357-1.615-1.332-2.867-2.632-3.312l-3.704-1.333-6.693.162z"
          ></path>
          <path
            fill="#2987CC"
            d="M28.914 34.318l-.442 3.681s4.002 4.25 6.46-.134c-1.229-3.547-.22-5.689-.22-5.689l-.19-2.108-3.529.435-2.08 3.815zm3.623-4.518s1.67.636 1.639-.87c-.032-1.506-1.89-3.18-2.458-.837"
          ></path>
          <path
            fill="#2987CC"
            d="M36.476 21.818c-3.44-3.911-8.797-2.244-10.913 1.031-1.092 1.699-.926 5.368-.199 7.976.463-.03 1.786-.182 2.778-.455-.099 1.061-1.455 1.728-2.315 1.728.165.334.562 1.031.76 1.213 1.588 1.517 3.837.97 4.63.546 1.158-.637 3.507-1.364 4.664-3.123 2.117-3.245 2.48-6.732.595-8.916z"
          ></path>
          <path
            stroke="#2987CC"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="0.791"
            d="M22.936 33.752a2.122 2.122 0 01-.496-2.965m-.589 4.448a3.876 3.876 0 01-.898-5.537M20.167 36.823a5.856 5.856 0 01-1.357-8.364M17.982 38.004a7.508 7.508 0 01-1.74-10.724"
          ></path>
        </g>
        <path
          fill="#EEF9FD"
          stroke="#EEF9FD"
          strokeWidth="0.85"
          d="M28.601 14.441a6.82 6.82 0 11-13.64 0 6.82 6.82 0 0113.64 0z"
        ></path>
        <path
          fill="#2987CC"
          d="M21.782 12.195a.748.748 0 111.496 0v2.994a.749.749 0 01-.748.749h-2.994a.749.749 0 010-1.497h2.245v-2.246z"
        ></path>
        <path
          fill="#2987CC"
          stroke="#2987CC"
          strokeWidth="0.85"
          d="M28.093 14.442a6.311 6.311 0 11-12.622 0 6.311 6.311 0 0112.622 0zm-2.2 4.11a5.814 5.814 0 10-8.222-8.221 5.814 5.814 0 008.221 8.222z"
        ></path>
      </svg>
    </div>
  );
};

export default MaxMonologDuration;
