import React, { useRef, FC } from 'react';
import { CircleButton } from '@ui';

const HelpText: FC<{ text?: string }> = (props) => {
  const { text } = props;
  const helpRef = useRef<HTMLDivElement>(null);
  if (!text) return <></>;
  function toggleHelpHandler(isShow: boolean) {
    const helpDivClassList = helpRef?.current?.classList;
    if (helpDivClassList) {
      if (isShow) {
        helpDivClassList.add('visible');
        helpDivClassList.remove('invisible');
        helpDivClassList.add('scale-100');
      } else {
        helpDivClassList.remove('visible');
        helpDivClassList.add('invisible');
      }
    }
  }
  return (
    <div
      className="h-full = flex items-center mt-[-10px]"
      onMouseEnter={toggleHelpHandler.bind(null, true)}
      onMouseLeave={toggleHelpHandler.bind(null, false)}
    >
      <CircleButton
        onClick={toggleHelpHandler.bind(null, true)}
        icon="HelpCircleIcon"
        className="text-action"
        size={20}
      />
      <div
        className="invisible blockAfter w-full top-[35px] rounded-[5px] right-0 absolute boxShadow-hard text-[10px] font-light transition mt-[5px] duration-75 bg-white p-[10px]"
        ref={helpRef}
      >
        {text}
      </div>
    </div>
  );
};

export default HelpText;
