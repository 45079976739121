import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const BrokenDocIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 700 700">
        <path
          fill="currentColor"
          d="M449.54 124.59c-14.879-11.828-26.617-36.953-42.996-32.637-10.449 14.43-6.457 66.758 1.695 82.414 20.47 11.402 36.31 4.356 57.004 5.078 9.239 29.301.606 56.336 4.176 88.97.738 6.765 3.613 39.53 3.23 44.772-.89 12.105-12.956 22.477-23.21 27.707-19.254-1.187-32.043-19.383-44.336-18.918-18.262.688-26.18 17.348-40.324 24.566-21.13-1.305-34.61-18.672-49.727-25.473-17.77-7.984-34.977 15.227-51.863 21.52l-20.613-10.742c-7.98-26.797-4.184-76.699-4.176-106.14.012-29.91-9.078-77.48-1.164-105.53 22.773-11.859 141.11-2.516 158.03-12.98-25.117-20.562-94.848-17.07-125.51-13.77-73.461 7.895-53.957 3.77-53.957 163.36 0 25.777 2.328 48.95 2.75 73.461.242 14.293.726 17.238 14.14 22.547 8.512 3.371 17.5 10.824 24.23 12.344 17.97 4.055 33.97-18.434 49.18-22.547 13.77 3.73 31.59 20.828 49.747 25.492 22.602 5.8 28.055-17.89 49.023-23.793 17.305 6.004 35.789 28.273 55.363 15.898 4.898-3.098 14.852-13.77 20.832-18.715 20.82-17.219 8.46-56.598 8.46-104.43-.007-93.215 17.595-66.723-39.98-112.46zm-23.258 32.957l-4.598-28.164c6.25 1.16 30.062 21.32 31.695 27.48z"
        ></path>
        <path
          fill="currentColor"
          d="M209.57 400.16c-6.988 20.109-9.832 58.992 16.312 66.664 11.25 3.305 77.34 2.582 90.324.48 33.594-5.437 156.81 7.903 175-8.437 3.254-19.594 9.402-80.785-5.652-93.703-28.61-.617-36.227 35.051-66.617 13.016-34.648-25.129-36.434-4.098-65.441 10.703-26.84.621-34.125-22.637-47.75-24.711-18.492-2.828-31.695 19.117-48.2 27.535-20.495-3.617-38.515-20.324-45.71-20.316-.004 12.051 10.73 13.34 15.238 29.348 49.086 32.727 41.3 1.29 73.37-12.98 15.423 4.172 26.606 22.457 48.306 24.07 21.28 1.578 28.617-18.922 47.633-24.07 12.19 5.422 22.645 18.473 37.117 19.695 18.066 1.523 27.199-14.777 40.762-15.746 3.078 15.176.84 35.066-3.383 49.672-15.836 4.473-99.562 1.703-122.48 1.695-126.04-.062-121.34 26.824-121.34-42.336-9.727-3.672-7.723-3.933-17.488-.578zM396.38 208.8c-33.441.074-16.738-2.945-50.812-.035-19.18 1.64-92.871-.293-98.184 16.406 10.426 6.965 120.93 3.383 148.99 3.387 14.43 0 37.078 5.41 44.586-9.032-4.676-16.785-30.348-10.754-44.582-10.727zM404.72 262.36c-14.094.477-32.727-3.977-50.703-2.781-21.676 1.445-90.242 1.562-97.613 6.804 1.121 18.048 72.992 12.989 91.996 12.989 19.812-.004 31.965 1.226 50.898 2.726 13.445 1.07 34.82 2.586 41.098-11.195-8.023-17.62-21.961-9.008-35.676-8.543z"
        ></path>
      </svg>
    </div>
  );
};

export default BrokenDocIcon;
