import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const LiveIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 14 12">
        <path
          fill="currentColor"
          d="M1.707 9.887h3.427v.972h-.022a.49.49 0 00-.488.49c0 .262.22.482.488.482h3.55a.49.49 0 00.49-.483.49.49 0 00-.49-.489h-.02v-.972h3.42c.838 0 1.343-.5 1.343-1.337V2.078c0-.838-.505-1.338-1.342-1.338H1.707C.875.74.37 1.24.37 2.078V8.55c0 .838.505 1.337 1.337 1.337zm.064-1.026c-.23 0-.37-.145-.37-.37V2.137c0-.226.14-.37.37-.37h10.232c.231 0 .371.144.371.37V8.49c0 .225-.14.37-.37.37H1.77zM5.526 7.03c0 .225.247.317.435.21l2.884-1.693a.263.263 0 00-.005-.462L5.96 3.394c-.187-.113-.434-.022-.434.209V7.03z"
        ></path>
      </svg>
    </div>
  );
};

export default LiveIcon;
