import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import userApi from '@app/store/api/user.api';
import { Loading } from '@ui';
import { useAppSelector } from '@app/store';
import authStorage from '@app/utils/auth';

export type AuthProviderMiddlewareProps = {
  children: ReactNode;
};

const AuthProvider: FC<AuthProviderMiddlewareProps> = ({ children }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userState.user);
  const accessToken = localStorage.getItem('access_token');
  const { isLoading, isFetching, isError } = userApi.endpoints.getMe.useQuery(null, {
    skip: !accessToken || !!user,
  });
  if (isError) authStorage.logOut();
  const loading = isLoading || isFetching;
  if (loading) {
    return <Loading text={t('common.loading_info')} />;
  }

  return <>{children}</>;
};

export default AuthProvider;
