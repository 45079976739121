import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const UkTechIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 74 15">
        <path
          fill="currentColor"
          d="M5.648 14.256c-1.749 0-3.074-.413-3.976-1.24C.771 12.184.32 10.96.32 9.344V.8h3.52v7.488c0 .95.131 1.605.392 1.968.262.363.734.544 1.416.544.683 0 1.155-.181 1.416-.544.262-.363.392-1.019.392-1.968V.8h3.52v8.544c0 1.616-.45 2.84-1.352 3.672-.896.827-2.221 1.24-3.976 1.24zM14.745 14V.8h3.52v5.136L22.377.8h4.272l-5.12 6.224 6 6.976h-4.848l-4.416-5.296V14h-3.52zM30.005 14V3.888h-2.88V.8h9.28v3.088h-2.88V14h-3.52zM38.77 14V.8h7.809v3.088H42.29V5.84h4.096v3.088H42.29v1.984h4.288V14H38.77zM56.426 14.256a7.696 7.696 0 01-2.84-.528 7.753 7.753 0 01-2.368-1.48 7.088 7.088 0 01-1.624-2.176 6.155 6.155 0 01-.584-2.656c0-.944.197-1.83.592-2.656a7.086 7.086 0 011.616-2.192 7.629 7.629 0 012.368-1.472A7.513 7.513 0 0156.41.56c.8 0 1.568.112 2.304.336.74.219 1.362.496 1.864.832L59.09 4.736c-.288-.219-.654-.392-1.096-.52a4.61 4.61 0 00-1.352-.2 4.3 4.3 0 00-1.496.256c-.46.17-.864.41-1.216.72a3.332 3.332 0 00-.816 1.072c-.198.41-.296.859-.296 1.344 0 .485.098.933.296 1.344.197.41.469.77.816 1.08.352.304.757.541 1.216.712.464.17.962.256 1.496.256.464 0 .914-.064 1.352-.192.442-.133.808-.31 1.096-.528l1.488 3.008c-.475.32-1.08.595-1.816.824a7.76 7.76 0 01-2.336.344zM63.062 14V.8h3.52v5.04h3.392V.8h3.52V14h-3.52V8.928h-3.392V14h-3.52z"
        ></path>
      </svg>
    </div>
  );
};

export default UkTechIcon;
