import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  menuBarItemClassName,
  menuBarItemTextClassName,
} from '@app/pages/layout/blocks/menu-bar/menu-bar.styles';
import Icon from '@app/components/ui/icons';
import { MenuBarItem } from '@app/pages/layout/blocks/menu-bar/types/types';
import { useTranslation } from 'react-i18next';
import AllowComponentPermission from '@app/hocs/allow-component-permission';

type MenuBarItemProps = {
  isCollapsed: boolean;
  itemMenu: MenuBarItem;
  tag?: string;
};

const MenuBarElement: FC<MenuBarItemProps> = (props) => {
  const { isCollapsed, itemMenu } = props;
  const { t } = useTranslation();
  const { id, name, icon, route, routeEnd, tag } = itemMenu;
  return (
    <AllowComponentPermission permissionKey={tag}>
      <li key={id}>
        <NavLink
          to={route}
          className={({ isActive }) => menuBarItemClassName({ isActive, isCollapsed })}
          end={routeEnd}
        >
          <div className="inline-flex items-center overflow-y-hidden overflow-x-hidden">
            <Icon className="text-speech_analitics" name={icon} size={isCollapsed ? 25 : 18} />
            {!isCollapsed && (
              <span className={menuBarItemTextClassName}>{t(`components.menuTab.${name}`)}</span>
            )}
          </div>
        </NavLink>
      </li>
    </AllowComponentPermission>
  );
};

export default MenuBarElement;
