import * as process from 'process';

import { Mutex } from 'async-mutex';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { endpointUrl } from '@app/utils/endpoint-url';
import { logOut } from '@app/store/features/user.slice';
import authStorage from '@app/utils/auth';
import { AuthLoginResponse } from '@app/store/interfaces/auth.type';
import { navigationRouteList } from '@app/utils/routes';
import { toast } from 'react-toastify';

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? `${window.location.protocol}//${window.location.host}/${process.env.REACT_APP_BACKEND_PREFIX}`
    : `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PREFIX}`;
const mutex = new Mutex();
const headerTokenMutation = (tokenType: 'access' | 'refresh', headers: Headers) => {
  const token =
    tokenType === 'access'
      ? localStorage.getItem('access_token')
      : localStorage.getItem('refresh_token');
  if (token) {
    const tokenValue = tokenType === 'access' ? token : `bearer ${token}`;
    headers.set('Authorization', tokenValue);
  }
  return headers;
};
const loginEndpointPass = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PREFIX}${endpointUrl.authToken}`;
const baseQueryWithRefreshToken = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => headerTokenMutation('refresh', headers),
});
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => headerTokenMutation('access', headers),
});
const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result?.meta?.response?.status === 500) {
    toast.error('Ошибка на сервере');
    return { ...result, data: undefined };
  }
  if (result?.meta?.response?.status === 403) {
    toast.error('Нет доступа');
    return { ...result, meta: { ...result.meta, request: {}, response: {} }, data: undefined };
  }
  if (result?.meta?.response?.status === 401 && result?.meta?.request.url !== loginEndpointPass) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQueryWithRefreshToken(
          { url: endpointUrl.refreshToken },
          api,
          extraOptions,
        );
        if (refreshResult?.meta?.response?.status === 401) {
          await authStorage.logOut();
          api.dispatch(logOut());
          window.location.href = navigationRouteList.login;
        } else {
          const data = refreshResult.data as AuthLoginResponse;
          if (data.refresh_token && data.access_token) {
            await authStorage.signIn({
              access: data.access_token,
              refresh: data.refresh_token,
            });
          }
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBase;
