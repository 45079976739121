import { createApi } from '@reduxjs/toolkit/query/react';

import { endpointUrl } from '@app/utils/endpoint-url';

import {
  Equipment,
  EquipmentCreateFormResponse,
  EquipmentModelBulk,
  EquipmentOfVehicleResponse,
  EquipmentResponse,
  EquipmentsList,
} from '@appTypes/equipment.type';

import { CommonPagination } from '@appTypes/common-pagination.type';

import customFetchBase from '../customFetchBase';

const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: customFetchBase,
  tagTypes: ['Equipment', 'EquipmentType', 'Equipments'],
  endpoints: (builder) => ({
    /* Получение типов оборудования ТС */
    getEquipmentTypes: builder.query<EquipmentsList, null>({
      query() {
        return {
          url: endpointUrl.equipmentsType,
        };
      },
      providesTags: ['EquipmentType'],
    }),
    /* Получение карточки оборудования транспортного средства */
    getEquipment: builder.query<Equipment, EquipmentResponse>({
      query(params) {
        return {
          url: endpointUrl.equipment,
          params,
        };
      },
      providesTags: ['Equipment'],
    }),
    /* Получение списка списка оборудования транспортных средств, с учётом пагинации */
    getEquipments: builder.query<EquipmentsList, CommonPagination>({
      query(params) {
        return {
          url: endpointUrl.equipments,
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.equipments.map(({ vehicle_equipment_id }) => ({
                type: 'Equipments' as const,
                vehicle_equipment_id,
              })),
              'Equipments',
            ]
          : ['Equipments'],
    }),
    EquipmentImage: builder.query<Blob, { model_id: number }>({
      query(params) {
        return {
          url: endpointUrl.imageEquipment,
          params,
          headers: {
            'content-type': 'application/octet-stream',
          },
          responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
          cache: 'no-cache',
        };
      },
    }),
    /* Получение списка оборудования транспортного средства */

    getEquipmentByVehicle: builder.query<EquipmentsList, EquipmentOfVehicleResponse>({
      query(params) {
        return {
          url: endpointUrl.equipmentByVehicle,
          params,
        };
      },
    }),
    /* Удаление оборудования транспортного средства */
    removeEquipment: builder.mutation<{ status: number }, EquipmentResponse>({
      query(params) {
        return {
          url: endpointUrl.equipment,
          method: 'DELETE',
          params,
        };
      },
      invalidatesTags: ['Equipments'],
    }),
    /* Удаление всего оборудования транспортного средства */
    removeAllEquipments: builder.mutation<{ status: number }, EquipmentOfVehicleResponse>({
      query(params) {
        return {
          url: endpointUrl.equipments,
          method: 'DELETE',
          params,
        };
      },
      invalidatesTags: ['Equipments'],
    }),
    /* Добавление оборудования транспортного средства */
    addEquipment: builder.mutation<Equipment, EquipmentCreateFormResponse>({
      query(body) {
        return {
          url: endpointUrl.equipment,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Equipments'],
    }),
    /*  Забрать список оборудования по model id */
    equipmentByModel: builder.query<EquipmentModelBulk, number>({
      query: (model_id) => ({
        url: endpointUrl.equipmentByModel,
        params: { model_id },
      }),
    }),
    /* Редактирование оборудования транспортного средства */
    editEquipment: builder.mutation<
      Equipment,
      {
        params: {
          vehicle_equipment_id: number;
        };
        body: EquipmentCreateFormResponse;
      }
    >({
      query({ body, params }) {
        return {
          url: endpointUrl.equipment,
          method: 'PUT',
          body,
          params,
        };
      },
      invalidatesTags: ['Equipments'],
    }),
  }),
});
export default equipmentApi;
export const {
  useEquipmentImageQuery,
  useGetEquipmentQuery,
  useGetEquipmentsQuery,
  useGetEquipmentByVehicleQuery,
  useRemoveEquipmentMutation,
  useRemoveAllEquipmentsMutation,
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useGetEquipmentTypesQuery,
  useLazyGetEquipmentTypesQuery,
  useLazyGetEquipmentsQuery,
  useLazyGetEquipmentByVehicleQuery,
  useLazyGetEquipmentQuery,
  useEquipmentByModelQuery,
  useLazyEquipmentImageQuery,
} = equipmentApi;
