import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import authApi from './api/auth.api';
import userApi from './api/user.api';
import userReducer from './features/user.slice';
import organizationsApi from './api/organizations.api';
import rolesApi from './api/roles.api';
import vehicleApi from './api/vehicle.api';
import messageApi from './api/message.api';
import equipmentApi from './api/equipment.api';
import searchDataApi from './api/search-data.api';
import permissionApi from './api/permission.api';
import modelsApi from './api/models.api';

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [searchDataApi.reducerPath]: searchDataApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [modelsApi.reducerPath]: modelsApi.reducer,
    userState: userReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      organizationsApi.middleware,
      rolesApi.middleware,
      vehicleApi.middleware,
      messageApi.middleware,
      equipmentApi.middleware,
      searchDataApi.middleware,
      permissionApi.middleware,
      modelsApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
