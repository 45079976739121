import cn from 'classnames';

export const pageClassName = (active: boolean) =>
  cn(
    active ? 'bg-1color text-white' : 'hover:bg-b_dark text-1color',
    'transition',
    'cursor-pointer',
    'select-none',
    'ease-in-out',
    'px-[10px]',
    'py-[3px]',
    'my-[7px]',
    'rounded-[10px]',
  );

export const navigationButtonClassName = ({ isRight }: { isRight: boolean }) =>
  cn(
    'bg-white',
    'cursor-pointer',
    'h-full',
    'inline-flex',
    'items-center',
    'justify-center',
    'blocks',
    'min-h-[54px]',
    'w-[33px]',
    'hover:bg-b_dark',
    isRight ? 'border-l-[1px]' : 'border-r-[1px]',
    'border-b_light',
  );
