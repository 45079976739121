import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRouteList } from '@app/utils/routes';
import {
  EmergencyInformationOutlet,
  InformationSettingsPage,
  ListOfMessagesPage,
} from '@app/pages/emergency-information';
import RequiredUser from '@app/hocs/require-user.hoc';
import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs';

export const emergencyInformationRoute = (
  <Route
    path={navigationRouteList.emergencyInformation}
    element={
      <RequiredUser>
        <EmergencyInformationOutlet />
      </RequiredUser>
    }
  >
    <Route
      index
      element={<ListOfMessagesPage />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRouteList.emergencyInformation} crumb="messages" />
        ),
      }}
    />
    <Route
      path={navigationRouteList.informingSettings}
      element={<InformationSettingsPage />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRouteList.informingSettings} crumb="information" />
        ),
      }}
    />
  </Route>
);
