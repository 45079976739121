import React from 'react';

import { Route } from 'react-router-dom';
import { navigationRouteList } from '@app/utils/routes';
import { AccessForm, AuthPage, LoginForm, RemindForm, NewPassowrdForm } from '@app/pages/auth';

export const authRoute = (
  <Route path={navigationRouteList.login} element={<AuthPage />}>
    <Route index element={<LoginForm />} />
    <Route path={navigationRouteList.remindPassword} element={<RemindForm />} />
    <Route path={navigationRouteList.newPassword} element={<NewPassowrdForm />} />
    <Route path={navigationRouteList.loginAccess} element={<AccessForm />} />
  </Route>
);
