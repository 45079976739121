import React from 'react';
import { navigationRouteList } from '@app/utils/routes';
import { Button } from '@ui';
import { useTranslation } from 'react-i18next';

const AccessForm = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t('pages.accessForm.header')}</h1>
      <Button
        className="underline underline-offset-[3px] !border-0 !px-0 !font-[500]"
        fill="linked"
        label="Назад"
        link={`${navigationRouteList.login}`}
      />
    </div>
  );
};

export default AccessForm;
