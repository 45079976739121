type EndpointUrl =
  | 'authToken'
  | 'forgetPassword'
  | 'refreshToken'
  | 'validateToken'
  | 'updatePassword'
  | 'me'
  | 'organization'
  | 'organizations'
  | 'fullOrganizations'
  | 'operatorOrganizations'
  | 'vendorsOrganizations'
  | 'roles'
  | 'userList'
  | 'shortUserList'
  | 'adminUserCRUD'
  | 'adminUserSettings'
  | 'vehicleEquipment'
  | 'vehicle'
  | 'vehicles'
  | 'messageHistory'
  | 'messageSettings'
  | 'messageSubscribe'
  | 'equipments'
  | 'equipment'
  | 'equipmentsType'
  | 'equipmentByVehicle'
  | 'equipmentByModel'
  | 'searchData'
  | 'searchDataCheck'
  | 'searchDataCsv'
  | 'searchFile'
  | 'searchDataPlot'
  | 'searchDataReset'
  | 'userPermission'
  | 'organizationPermission'
  | 'currentVehicle'
  | 'currentVehicles'
  | 'vehicleModels'
  | 'vehicleModel'
  | 'imageVehicles'
  | 'vehicleEquipmentModelBulk'
  | 'imageEquipment'
  | 'vehicleEquipmentModel';

const prefix = 'front';
export const endpointUrl: Record<EndpointUrl, string> = {
  /* user auth and login */
  authToken: 'auth/login',
  refreshToken: 'auth/refresh',
  forgetPassword: 'auth/forget_password',
  validateToken: '/auth/validate_token',
  updatePassword: 'auth/update_token',
  me: `${prefix}/user/me`,
  /* user administration */
  roles: `${prefix}/adm/role/bulk`,
  userList: `${prefix}/user/bulk`,
  shortUserList: `${prefix}/adm/user/bulk/short`,
  adminUserCRUD: `${prefix}/user`,
  adminUserSettings: `${prefix}/adm/user`,
  /* organization */
  organization: `${prefix}/organization`,
  organizations: `${prefix}/organization/bulk`,
  fullOrganizations: `${prefix}/organization/full`,
  operatorOrganizations: `${prefix}/organization/operator`,
  vendorsOrganizations: `${prefix}/organization/vendor`,
  /* vehicles */
  vehicle: `${prefix}/vehicle`,
  vehicles: `${prefix}/vehicle/bulk`,
  vehicleEquipment: `${prefix}/model/vehicle/equipment`,
  equipmentByVehicle: `${prefix}/vehicle/equipment/bulk`,
  currentVehicle: `${prefix}/current/vehicle`,
  currentVehicles: `${prefix}/current/vehicle/bulk`,
  imageVehicles: `${prefix}/model/vehicle/image`,
  imageEquipment: `${prefix}/model/vehicle/equipment/image`,
  equipment: `${prefix}/vehicle/equipment`,
  equipments: `${prefix}/vehicle/equipment/bulk`,
  equipmentsType: `${prefix}/vehicle/equipment/types`,
  equipmentByModel: `${prefix}/vehicle/equipment/model`,
  /* messages */
  messageHistory: `${prefix}/messages/history`,
  messageSettings: `${prefix}/messages/settings`,
  messageSubscribe: `${prefix}/messages/emails`,
  /* searching data */
  searchData: `${prefix}/data`,
  searchFile: `${prefix}/data/file`,
  searchDataCheck: `${prefix}/data/check`,
  searchDataCsv: `${prefix}/data/csv`,
  searchDataPlot: `${prefix}/data/plot`,
  searchDataReset: `${prefix}/data/reset`,
  /* permissions */
  userPermission: `${prefix}/user/permission`,
  organizationPermission: `${prefix}/organization/permission`,
  //equipmentPermission: `${prefix}/equipment/permission`, // Удалено из конфлюинса
  /* models */
  vehicleModels: `${prefix}/model/vehicle/bulk`,
  vehicleModel: `${prefix}/model/vehicle`,
  vehicleEquipmentModel: `${prefix}/model/vehicle/equipment`,
  vehicleEquipmentModelBulk: `${prefix}/model/vehicle/equipment/bulk`,
};
