import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const TextSquare: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 17"
      >
        <path
          fill="currentColor"
          d="M3.674 16.872h10.643c1.899 0 2.883-.984 2.883-2.856V3.319c0-1.88-.984-2.856-2.883-2.856H3.674C1.775.463.79 1.438.79 3.319v10.697c0 1.88.984 2.856 2.883 2.856zm.105-1.723c-.817 0-1.265-.43-1.265-1.283V3.46c0-.853.448-1.274 1.265-1.274h10.433c.809 0 1.265.421 1.265 1.274v10.406c0 .853-.457 1.283-1.265 1.283H3.779zM4.21 4.532v2.04c0 .57.334.896.896.896h2.04c.57 0 .905-.325.905-.897V4.532c0-.571-.334-.896-.905-.896h-2.04c-.562 0-.896.325-.896.896zm.545 5.863h8.42a.548.548 0 00.553-.563.539.539 0 00-.553-.545h-8.42a.54.54 0 00-.563.545.55.55 0 00.563.563zm0 2.9h6.222a.546.546 0 00.554-.554.548.548 0 00-.554-.562H4.755a.55.55 0 00-.563.562c0 .308.238.554.563.554z"
        ></path>
      </svg>
    </div>
  );
};

export default TextSquare;
