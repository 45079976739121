import React from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { NotFoundPage } from '@app/pages';
import { useGetVehicleQuery, useGetCurrentVehiclesQuery } from '@app/store/api/vehicle.api';
import { Vehicle } from '@appTypes/vehicle.type';
import vehicleMock from '@app/mock/vehicleList.json';
import { Button, NavigationTabs, PageHeader } from '@ui';
import { navigationRouteList } from '@app/utils/routes';
import { useTranslation } from 'react-i18next';

export type VehicleContext = { vehicle: Vehicle | null };

export function useVehicleContext() {
  return useOutletContext<VehicleContext>();
}

const VehicleItemOutlet = () => {
  const { t } = useTranslation();
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  useGetVehicleQuery({ vehicle_id: Number(vehicleId) }, { skip: !vehicleId });

  const { data: vehicleList } = useGetCurrentVehiclesQuery({
    sortDesc: true,
  });
  const selectedVehicle = vehicleList?.data_list?.find(
    (vehicle) => vehicle.vehicle_id === Number(vehicleId),
  );
  if (!vehicleId) return <NotFoundPage />;

  return (
    <div>
      <PageHeader label={selectedVehicle?.name ?? ' '} />
      <div className="flex items-center mb-[8px]">
        <div className="flex-1 mr-[20px]">
          <NavigationTabs
            items={[
              {
                icon: 'ChartIcon',
                index: 'graphPage',
                label: t('pages.vehicleDataPage.vehicle_item_outlet.navigationTabs.graph_label'),
                link: `${navigationRouteList.vehicle}/${vehicleId}`,
              },
              {
                icon: 'DocIcon',
                index: 'infoPage',
                label: t('pages.vehicleDataPage.vehicle_item_outlet.navigationTabs.data_label'),
                link: `${navigationRouteList.vehicle}/${vehicleId}/info`,
              },
            ]}
          />
        </div>
        <div>
          <Button
            label="Скачать CSV"
            onClick={() => navigate(navigationRouteList.dataSearch)}
            fill="outlined"
          />
        </div>
      </div>
      <Outlet context={{ vehicle: vehicleMock.vehicle[0] }} />
    </div>
  );
};

export default VehicleItemOutlet;
