import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const RollbackIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 20 19">
        <path
          fill="currentColor"
          d="M.117 15.562c0 1.818.98 2.78 2.814 2.78h14.103c1.834 0 2.814-.962 2.814-2.78V5.477c0-1.818-.98-2.79-2.814-2.79H11.87a.934.934 0 00-.93.93c0 .507.423.922.93.922h5.005c.722 0 1.12.365 1.12 1.128v9.696c0 .764-.398 1.137-1.12 1.137H3.088c-.73 0-1.12-.373-1.12-1.137V5.667c0-.763.39-1.128 1.12-1.128h1.835v1.27c0 .655.514.846 1.07.456l2.89-2.075c.448-.324.448-.83 0-1.154L6.001.97c-.556-.406-1.08-.224-1.08.457v1.262H2.932c-1.835 0-2.814.97-2.814 2.789v10.085z"
        ></path>
      </svg>
    </div>
  );
};

export default RollbackIcon;
