import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const UserIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 16">
        <path
          fill="currentColor"
          d="M7.421 8.181c2 0 3.628-1.768 3.628-3.943C11.049 2.105 9.43.395 7.42.395c-2 0-3.644 1.727-3.635 3.86C3.794 6.42 5.413 8.18 7.42 8.18zm0-1.436c-1.153 0-2.125-1.07-2.133-2.49 0-1.378.971-2.424 2.133-2.424 1.163 0 2.125 1.03 2.125 2.407 0 1.42-.97 2.507-2.125 2.507zm-5.18 9.156h10.352c1.428 0 2.117-.448 2.117-1.42 0-2.274-2.84-5.329-7.297-5.329S.117 12.207.117 14.482c0 .97.689 1.419 2.125 1.419zm-.282-1.436c-.19 0-.265-.058-.265-.216 0-1.27 2.05-3.66 5.72-3.66 3.668 0 5.727 2.39 5.727 3.66 0 .158-.083.216-.274.216H1.959z"
        ></path>
      </svg>
    </div>
  );
};

export default UserIcon;
