import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const ChevronLeftV2Icon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 6 10">
        <path
          fill="currentColor"
          d="M5.354 1.354a.5.5 0 10-.708-.708l.708.708zM1 5l-.354-.354L.293 5l.353.354L1 5zm3.646 4.354a.5.5 0 10.708-.708l-.708.708zm0-8.708l-4 4 .708.708 4-4-.708-.708zm-4 4.708l4 4 .708-.708-4-4-.708.708z"
        ></path>
      </svg>
    </div>
  );
};

export default ChevronLeftV2Icon;
