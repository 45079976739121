import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const Interruptions: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 58 57">
        <circle cx="29" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <g clipPath="url(#clip0_1252_12250)">
          <path
            fill="#5AA8E0"
            d="M21.542 38.877c-.694.203-2.168.55-3.353.868a3.304 3.304 0 00-2.341 2.225L14.316 47h19.656l-1.359-5.03a3.264 3.264 0 00-2.254-2.283l-3.151-.925-5.666.115z"
          ></path>
          <path
            fill="#5AA8E0"
            d="M21.947 35.842l-.405 3.036s3.498 3.526 5.695-.116c-1.07-2.92-.203-4.712-.203-4.712l-.144-1.734-3.093.376-1.85 3.15zm3.209-3.757s1.445.52 1.445-.723c-.03-1.243-1.677-2.63-2.168-.694m-5.781-2.052a2.059 2.059 0 01-.839-2.804 2.059 2.059 0 012.804-.838"
          ></path>
          <path
            fill="#5AA8E0"
            d="M19.721 25.899c-.491-.52-.462-1.3.029-1.792.52-.492 1.3-.463 1.792.029m12.083 16.563s-.347-9.395-.55-11.707c-.202-2.313-9.567.029-9.567.029l-1.966 11.215"
          ></path>
          <path
            fill="#5AA8E0"
            d="M28.133 25.407c-2.862-3.584-7.371-2.052-9.135.954-.867 1.474-.809 4.596-.26 6.909.405 0 1.532.028 2.4-.145 0 .752-1.244 1.416-1.908 1.734.173.463.404.81.607 1.041 1.33 1.388 3.208.896 3.873.492.983-.579 2.92-1.244 3.902-2.862 1.822-2.949 2.11-6.128.52-8.123z"
          ></path>
          <path
            fill="#2987CC"
            d="M31.92 38.53c-.723.203-2.255.579-3.498.897a3.382 3.382 0 00-2.428 2.312L24.404 47h20.523l-1.416-5.26c-.318-1.157-1.185-2.053-2.342-2.371l-3.295-.954-5.955.116z"
          ></path>
          <path
            fill="#2987CC"
            d="M32.353 35.35l-.405 3.18s3.672 3.672 5.926-.115c-1.127-3.064-.202-4.914-.202-4.914l-.174-1.821-3.237.376-1.908 3.295zm3.324-3.901s1.532.549 1.504-.752c-.03-1.3-1.735-2.746-2.255-.723"
          ></path>
          <path
            fill="#2987CC"
            d="M38.828 24.454c-3.006-3.73-7.689-2.14-9.539.982-.954 1.62-.81 5.117-.173 7.603.404-.03 1.56-.174 2.428-.434-.087 1.012-1.272 1.648-2.024 1.648.145.318.492.983.665 1.156 1.388 1.445 3.353.925 4.047.52 1.012-.607 3.064-1.3 4.076-2.977 1.85-3.093 2.168-6.417.52-8.498z"
          ></path>
          <path
            stroke="#5AA8E0"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="0.858"
            d="M17.062 35.871a2.059 2.059 0 01-.463-2.833m-.578 4.279c-1.705-1.215-2.081-3.585-.838-5.29"
          ></path>
          <path
            stroke="#fff"
            strokeLinecap="square"
            strokeMiterlimit="10"
            strokeWidth="0.858"
            d="M27.006 35.698a2.059 2.059 0 01-.463-2.833m-.55 4.25c-1.705-1.215-2.08-3.585-.837-5.29"
          ></path>
          <path
            stroke="#2987CC"
            strokeMiterlimit="10"
            strokeWidth="1.715"
            d="M29 20.118a4.336 4.336 0 100-8.672 4.336 4.336 0 000 8.672zM26.225 18.556l5.55-5.55"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Interruptions;
