import React, { useState } from 'react';

import { Button, PageHeader } from '@ui';
import { useTranslation } from 'react-i18next';

import { SelectedOrganizationPermission } from '@app/pages/settings/settings-permissions/types';

import { useEditOrganizationPermissionsMutation } from '@app/store/api/permission.api';

import { OrganizationPermissionsEditRequest } from '@appTypes/permissions.type';

import OrganizationDropdownBlock from './blocks/organization-dropdown.block';
import UserPermissionBlock from './blocks/user-permission.block';

const SettingsPermissionsPage = () => {
  const { t } = useTranslation();
  const [editOrganizationPermissions] = useEditOrganizationPermissionsMutation();
  const [activeOrganizationToggleId, changeActiveOrganizationToggleId] = useState<number | null>(
    null,
  );
  const [updatedPermissionSelectedList, changeSelectedPermissionList] =
    useState<SelectedOrganizationPermission>({});

  function onSaveButtonClick() {
    const usersRequestData: OrganizationPermissionsEditRequest = { users: [] };
    Object.keys(updatedPermissionSelectedList).map((item) => {
      usersRequestData.users.push({ id: item, organizations: updatedPermissionSelectedList[item] });
    });
    activeOrganizationToggleId &&
      editOrganizationPermissions({
        body: usersRequestData,
        params: { organization_id: activeOrganizationToggleId },
      });
  }
  return (
    <div>
      <PageHeader
        label={t('pages.settingsPermissions.title')}
        className="sticky top-0 bg-basic_app_bg"
      >
        <Button onClick={onSaveButtonClick} label={t('pages.settingsPermissions.buttons.save')} />
      </PageHeader>
      <div className="flex gap-[10px] mt-[5px] w-full">
        <div className="flex flex-col gap-[10px] basis-1/2">
          <OrganizationDropdownBlock
            activeOrganizationId={activeOrganizationToggleId}
            onChangeActiveOrganization={changeActiveOrganizationToggleId}
          />
        </div>
        <div className="basis-1/2 h-[calc(100%-80px)] overflow-auto sticky top-[90px]">
          <UserPermissionBlock
            updatedPermissionSelectedList={updatedPermissionSelectedList}
            changeSelectedPermissionList={changeSelectedPermissionList}
            organizationId={activeOrganizationToggleId}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsPermissionsPage;
