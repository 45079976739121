import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const Oscilogram: FC<IconType> = (props) => {
  const { size, className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 28 28"
      >
        <path
          fill="currentColor"
          d="M12.48 23.023c.439 0 .782-.351.782-.773V5.067a.777.777 0 00-.783-.773.769.769 0 00-.773.773V22.25c0 .422.334.773.774.773zm5.282-8.56a4.417 4.417 0 011.547-1.354v-6.1a.778.778 0 00-.774-.79c-.448 0-.773.36-.773.79v7.454zm-8.306 5.142c.44 0 .782-.352.782-.774V8.486a.778.778 0 00-.782-.782.77.77 0 00-.773.782v10.345c0 .422.334.774.773.774zm6.056-1.143c.43 0 .773-.343.773-.765V9.62a.777.777 0 00-.773-.782.773.773 0 00-.783.782v8.077c0 .422.335.765.783.765zm5.273-5.818c.51-.08 1.037-.08 1.556.008v-1.31a.786.786 0 00-.782-.79c-.44 0-.774.36-.774.79v1.302zM6.424 16.062a.78.78 0 00.79-.782v-3.243a.788.788 0 00-.79-.79c-.43 0-.765.36-.765.79v3.243c0 .43.334.783.765.783zm15.073 4.87c.668 0 1.292-.185 1.828-.501l2.04 2.03a.919.919 0 00.658.281c.466 0 .8-.351.8-.826 0-.22-.096-.43-.255-.589L24.52 19.27a3.58 3.58 0 00.563-1.924 3.593 3.593 0 00-3.586-3.577c-1.96 0-3.577 1.617-3.577 3.577 0 1.968 1.617 3.586 3.577 3.586zm0-1.292a2.305 2.305 0 01-2.285-2.294c0-1.248 1.037-2.277 2.285-2.277a2.29 2.29 0 012.285 2.277 2.3 2.3 0 01-2.285 2.294z"
        ></path>
      </svg>
    </div>
  );
};

export default Oscilogram;
