import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CircleButton,
  HelpModal,
  Icon,
  Loading,
  Modal,
  PageHeader,
  Paginator,
  Table,
} from '@ui';
import { SettingsVehicleForm } from '@app/pages/settings/settings-vehicle/blocks/settings-vehicle-form';
import { useGetVehicleModelsQuery } from '@app/store/api/models.api';
import { VehicleCreateResponse, VehicleItem } from '@appTypes/vehicle.type';
import {
  useAddVehicleMutation,
  useEditVehicleMutation,
  useGetVehiclesQuery,
  useLazyGetVehicleQuery,
  useRemoveVehicleMutation,
} from '@app/store/api/vehicle.api';

import { PaginationResponse } from '@app/components/ui/paginator/paginator';

import moment from 'moment';

import { useGetOperatorOrganizationsQuery } from '@app/store/api/organizations.api';

import AllowComponentPermission from '@app/hocs/allow-component-permission';

import { titlesData } from './data';

const SettingsVehiclePage = () => {
  const LOCALE_TABLE_TITLE = 'pages.settingsVehicle.table.title';
  const { t } = useTranslation();
  const submitRef = useRef<HTMLInputElement>(null);
  // useState
  const [vehiclePagination, changeVehiclePagination] = useState({
    sortDesc: false,
    limit: 10,
    offset: 0,
  });
  const [vehicleIdForDeleteAndEdit, changeVehicleIdForDeleteAndEdit] = useState<number>();
  const [vehicleData, changeVehicleData] = useState<VehicleItem>();
  const [removalModalState, changeRemovalModalState] = useState<boolean>(false);
  const [modalOpenState, changeModalState] = useState<boolean>(false);
  const [editMode, changeEditMode] = useState<boolean>(false);
  // api
  const { data: vehicleModels } = useGetVehicleModelsQuery();
  const [addVehicle] = useAddVehicleMutation();
  const { data: vehiclesData, isLoading } = useGetVehiclesQuery(vehiclePagination);
  const [getVehicleInfo] = useLazyGetVehicleQuery();
  const [removeVehicle] = useRemoveVehicleMutation();
  const [editVehicle] = useEditVehicleMutation();
  const { data: operatorsList, isLoading: operatorsLoading } = useGetOperatorOrganizationsQuery();
  // handlers
  function handleRemoveVehicle() {
    if (!vehicleIdForDeleteAndEdit) return;
    removeVehicle({ vehicle_id: vehicleIdForDeleteAndEdit });
  }
  function handleSubmitForm(data: VehicleCreateResponse) {
    editMode
      ? vehicleIdForDeleteAndEdit &&
        editVehicle({ body: data, params: { vehicle_id: vehicleIdForDeleteAndEdit } })
      : addVehicle(data);
    changeModalState((prev) => !prev);
  }
  function changeVehiclePaginationByClick(paginationData: PaginationResponse) {
    changeVehiclePagination({ ...paginationData, sortDesc: false });
  }
  function onUtilClick(vehicle_id: number, action: 'delete' | 'edit') {
    changeVehicleIdForDeleteAndEdit(vehicle_id);
    if (action === 'delete') {
      changeRemovalModalState(true);
    } else {
      changeEditMode(true);
      getVehicleInfo({ vehicle_id: vehicle_id }).then((data) => {
        changeVehicleData(data.data);
        changeModalState(true);
      });
    }
  }
  const modalLabel = (
    <div className="flex w-full gap-[20px] flex-col items-center justify-center mb-[25px]">
      <div
        className={
          'w-[57px] h-[57px] rounded-full bg-action/[0.1] flex items-center justify-center'
        }
      >
        <Icon className={'text-action'} size={29} name={'CableCar'} />
      </div>
      <div className="w-full text-center">
        {t('pages.settingsVehicle.form.title')}
        <HelpModal helperText={'help'} />
      </div>
    </div>
  );
  const vehiclesTableData = vehiclesData
    ? vehiclesData.vehicles.map((vehicle) => ({
        ...vehicle,
        utils: (
          <div className="flex w-full items-center gap-[10px]">
            <div>
              <CircleButton
                icon="SettingIcon"
                onClick={() => onUtilClick(vehicle.vehicle_id, 'edit')}
              />
            </div>
            <div>
              <AllowComponentPermission permissionKey={'NoChanges06'} type={'disabled'}>
                <CircleButton
                  icon="TrashIcon"
                  onClick={() => onUtilClick(vehicle.vehicle_id, 'delete')}
                />
              </AllowComponentPermission>
            </div>
          </div>
        ),
        name: `${vehicle.vehicle_id} (${vehicle.licence_plate})`,
        install_time: moment(vehicle.install_time).format('yyyy.MM.DD HH:MM:SS'),
        vehicle_model:
          vehicleModels?.find((item) => item.vehicle_model_id === vehicle.vehicle_model)
            ?.model_name || 'N/A',
      }))
    : [];
  if (isLoading || operatorsLoading) return <Loading />;
  return (
    <div>
      <PageHeader icon={'CableCar'} label={t('pages.settingsVehicle.pageHeader.title')}>
        <AllowComponentPermission permissionKey={'NoChanges06'} type={'disabled'}>
          <Button
            onClick={() => {
              changeModalState(true);
              changeEditMode(false);
            }}
            icon="CirclePlusIcon"
            label={t('pages.settingsVehicle.pageHeader.buttonLabel')}
          />
        </AllowComponentPermission>
      </PageHeader>
      <Table
        dataSource={vehiclesTableData}
        columns={titlesData.map((item) => ({
          ...item,
          title: item.title && t(LOCALE_TABLE_TITLE + item.title),
        }))}
      />
      {!!vehiclesTableData.length && (
        <Paginator
          containerClassName={'mt-[20px]'}
          page={vehiclePagination.offset + 1}
          count={vehiclesData?.total || 20}
          limit={vehiclePagination.limit}
          onChange={changeVehiclePaginationByClick}
        />
      )}
      <AllowComponentPermission permissionKey={'NoChanges06'} type={'disabled'}>
        <Modal
          label={modalLabel}
          isAutoClosable={false}
          onSuccess={() => submitRef.current?.click()}
          variant={'mediumW650'}
          show={modalOpenState}
          onClose={changeModalState.bind(null, false)}
        >
          {modalOpenState && (
            <SettingsVehicleForm
              vehicleData={vehicleData}
              vehicleModels={vehicleModels}
              handleSubmitForm={handleSubmitForm}
              submitRef={submitRef}
              editMode={editMode}
              operatorsList={operatorsList}
            />
          )}
        </Modal>
      </AllowComponentPermission>
      <Modal
        onClose={changeRemovalModalState.bind(null, false)}
        show={removalModalState}
        variant={'removal'}
        label={t('pages.settingsVehicle.table.delete.label')}
        onRemove={handleRemoveVehicle}
      >
        <div>{t('pages.settingsVehicle.table.delete.delete_TC')} ?</div>
      </Modal>
    </div>
  );
};

export default SettingsVehiclePage;
