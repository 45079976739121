import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRouteList } from '@app/utils/routes';
import {
  SettingsOrganizationsPage,
  SettingsUserPage,
  SettingsVehiclePage,
  SettingsVehicleEquipmentPage,
  SettingsOutlet,
  SettingsPermissionsPage,
} from '@app/pages/settings';
import RequiredUser from '@app/hocs/require-user.hoc';
import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs';

export const settingsRoute = (
  <Route
    path={navigationRouteList.settings}
    element={
      <RequiredUser>
        <SettingsOutlet />
      </RequiredUser>
    }
  >
    <Route
      path={navigationRouteList.settings}
      element={
        <RequiredUser allowedPermission="MenuVisible08">
          <SettingsOrganizationsPage />
        </RequiredUser>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRouteList.settings} crumb="settings_organizations" />
        ),
      }}
    />
    <Route
      path={navigationRouteList.settingsUsers}
      element={
        <RequiredUser allowedPermission="MenuVisible05">
          <SettingsUserPage />
        </RequiredUser>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRouteList.settingsUsers} crumb="settings_users" />
        ),
      }}
    />
    <Route
      path={navigationRouteList.settingsVehicles}
      element={<SettingsVehiclePage />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={navigationRouteList.settingsVehicles} crumb="settings_vehicles" />
        ),
      }}
    />
    <Route
      path={navigationRouteList.settingsEquipment}
      element={<SettingsVehicleEquipmentPage />}
      handle={{
        crumb: () => (
          <BreadcrumbsItem
            path={navigationRouteList.settingsEquipment}
            crumb="settings_equipment"
          />
        ),
      }}
    />
    <Route
      path={navigationRouteList.settingsPermission}
      element={
        <RequiredUser allowedPermission="MenuVisible06">
          <SettingsPermissionsPage />
        </RequiredUser>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem
            path={navigationRouteList.settingsPermission}
            crumb="settings_permissions"
          />
        ),
      }}
    />
  </Route>
);
