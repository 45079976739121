import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const SearchTextSquare: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 18 22"
      >
        <path
          fill="currentColor"
          d="M3.78 19.848H8.77a4.49 4.49 0 01-1.186-1.75H3.938c-.844 0-1.266-.43-1.266-1.239V3.816c0-.8.422-1.256 1.265-1.256H13c.817 0 1.266.448 1.266 1.256v7.594c.668.246 1.265.66 1.749 1.187V3.702c0-1.907-.976-2.891-2.866-2.891H3.78C1.9.81.914 1.804.914 3.702v13.254c0 1.907.984 2.892 2.865 2.892zM5.264 6.137h6.416a.548.548 0 00.553-.563.546.546 0 00-.553-.553H5.265a.548.548 0 00-.563.553.55.55 0 00.563.563zm0 2.997h6.416a.548.548 0 00.553-.563.546.546 0 00-.553-.553H5.265a.548.548 0 00-.563.553.55.55 0 00.563.563zm6.89 10.714c.747 0 1.442-.211 2.03-.572l1.873 1.872c.21.211.422.29.676.29.449 0 .791-.351.791-.834 0-.211-.096-.431-.263-.598l-1.881-1.89a3.838 3.838 0 00.642-2.127 3.867 3.867 0 00-3.868-3.867 3.879 3.879 0 00-3.867 3.867 3.871 3.871 0 003.867 3.859zm0-1.336a2.53 2.53 0 01-2.531-2.523 2.536 2.536 0 012.531-2.522c1.38 0 2.514 1.142 2.514 2.522a2.52 2.52 0 01-2.514 2.523z"
        ></path>
      </svg>
    </div>
  );
};

export default SearchTextSquare;
