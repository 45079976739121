import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const SpeechRateDuration: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#5AA8E0"
          fillRule="evenodd"
          d="M27.487 41.125c0 .586-.476 1.074-1.099 1.074-.61 0-1.062-.488-1.062-1.074V17.26c0-.586.452-1.074 1.062-1.074.623 0 1.099.488 1.099 1.074v23.865zm8.398-9.253a2.408 2.408 0 00-2.16.134V19.958c0-.598.463-1.099 1.074-1.099.61 0 1.086.5 1.086 1.099v11.914zm-12.61 4.505c0 .585-.476 1.074-1.086 1.074-.61 0-1.074-.489-1.074-1.075V22.01a1.07 1.07 0 011.074-1.087c.61 0 1.086.489 1.086 1.087v14.367zm6.25-1.373c.443-1.1 1.625-1.966 2.161-2.16v-9.26c0-.599-.476-1.087-1.086-1.087a1.07 1.07 0 00-1.075 1.087v11.42zm-10.449-3.56c0 .599-.476 1.087-1.086 1.087a1.07 1.07 0 01-1.074-1.086V26.94c0-.598.463-1.098 1.074-1.098.61 0 1.086.5 1.086 1.098v4.505z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#EEF9FD"
          stroke="#EEF9FD"
          d="M40.3 38.944a8.65 8.65 0 01-2.435 6.036A8.188 8.188 0 0132 47.474a8.188 8.188 0 01-5.864-2.494 8.65 8.65 0 01-2.436-6.036 8.65 8.65 0 012.436-6.036A8.188 8.188 0 0132 30.414c2.197 0 4.307.896 5.864 2.494a8.65 8.65 0 012.436 6.036z"
        ></path>
        <g clipPath="url(#clip0_1252_12069)">
          <path
            fill="#2987CC"
            d="M33.833 27.917h-3.666a.92.92 0 00-.917.916.92.92 0 00.917.917h3.666a.92.92 0 00.917-.917.92.92 0 00-.917-.916zM32 39.833a.92.92 0 00.917-.916V35.25a.92.92 0 00-.917-.917.92.92 0 00-.917.917v3.667a.92.92 0 00.917.916zm6.444-6.059l.688-.687a.91.91 0 000-1.284l-.01-.009a.91.91 0 00-1.283 0l-.687.688A8.215 8.215 0 0032 30.667c-4.4 0-8.14 3.63-8.25 8.03a8.248 8.248 0 008.25 8.47 8.245 8.245 0 006.444-13.393zM32 45.334a6.412 6.412 0 01-6.417-6.417A6.412 6.412 0 0132 32.5a6.412 6.412 0 016.417 6.417A6.412 6.412 0 0132 45.333z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default SpeechRateDuration;
