import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const HorizontalDotsIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 16 5">
        <path
          fill="currentColor"
          d="M2.094 4.172c.984 0 1.82-.836 1.82-1.805 0-1.023-.789-1.804-1.82-1.804-.992 0-1.805.804-1.805 1.804 0 .969.813 1.805 1.805 1.805zm5.898 0c.977 0 1.805-.836 1.805-1.805A1.772 1.772 0 007.992.563c-.992 0-1.797.804-1.797 1.804 0 .969.805 1.805 1.797 1.805zm5.899 0c.976 0 1.812-.836 1.812-1.805 0-1.023-.781-1.804-1.812-1.804-1 0-1.813.804-1.813 1.804 0 .969.813 1.805 1.813 1.805z"
        ></path>
      </svg>
    </div>
  );
};

export default HorizontalDotsIcon;
