import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useAddEquipmentMutation,
  useEditEquipmentMutation,
  useGetEquipmentsQuery,
  useLazyGetEquipmentQuery,
  useRemoveEquipmentMutation,
} from '@app/store/api/equipment.api';
import {
  Button,
  CircleButton,
  HelpModal,
  Icon,
  Loading,
  Modal,
  PageHeader,
  Paginator,
  Table,
} from '@ui';

import { useGetVehiclesQuery } from '@app/store/api/vehicle.api';

import { PaginationResponse } from '@app/components/ui/paginator/paginator';

import { useGetFullOrganizationsQuery } from '@app/store/api/organizations.api';

import moment from 'moment';

import { Equipment } from '@appTypes/equipment.type';

import AllowComponentPermission from '@app/hocs/allow-component-permission';

import { titlesData } from './data';

import { VehicleEquipmentForm } from './blocks/vehicle-equipment-form';

const SettingsVehicleEquipmentPage = () => {
  const LOCALE_TABLE_TITLE = 'pages.settingsEquipment.table.title';
  const { t } = useTranslation();
  const submitRef = useRef<HTMLInputElement>(null);
  // useState
  const [equipmentPagination, changeEquipmentPagination] = useState({
    sortDesc: false,
    limit: 10,
    offset: 0,
  });
  const [removalModal, changeRemovalModalState] = useState(false);
  const [editMode, changeEditMode] = useState(false);
  const [removeEditItemId, changeRemoveEditItemId] = useState<number>();
  const [equipmentData, changeEquipmentData] = useState<Equipment>();
  // api
  const [modalOpenState, changeModalState] = useState<boolean>(false);
  const { data: equipmentsList, isLoading } = useGetEquipmentsQuery(equipmentPagination);
  const { data: vehicleList } = useGetVehiclesQuery({});
  const { data: organizationsList } = useGetFullOrganizationsQuery(null);
  const [addEquipment] = useAddEquipmentMutation();
  const [editEquipment] = useEditEquipmentMutation();
  const [getEquipment] = useLazyGetEquipmentQuery();
  const [removeEquipment] = useRemoveEquipmentMutation();
  // handlers
  function handleSubmitForm(data) {
    editMode
      ? removeEditItemId &&
        editEquipment({ body: data, params: { vehicle_equipment_id: removeEditItemId } })
      : addEquipment(data);
    changeModalState(false);
  }
  function changeEquipmentPaginationByClick(paginationData: PaginationResponse) {
    changeEquipmentPagination({ ...paginationData, sortDesc: false });
  }
  function onUtilClick({ id, action }: { id: number; action: 'delete' | 'edit' }) {
    changeRemoveEditItemId(id);
    if (action === 'delete') {
      changeRemovalModalState(true);
    } else {
      changeEditMode(true);
      getEquipment({ vehicle_equipment_id: id }).then((data) => {
        changeEquipmentData(data.data);
        changeModalState(true);
      });
    }
  }
  function removeEquipmentHandler() {
    if (!removeEditItemId) return;
    removeEquipment({ vehicle_equipment_id: removeEditItemId });
  }
  const modalLabel = (
    <div className="flex w-full gap-[20px] flex-col items-center justify-center mb-[25px]">
      <div
        className={
          'w-[57px] h-[57px] rounded-full bg-action/[0.1] flex items-center justify-center'
        }
      >
        <Icon className={'text-action'} size={29} name={'WrenchAndScrewdriver'} />
      </div>
      <div className="w-full text-center">
        {t('pages.settingsEquipment.form.title')}
        <HelpModal helperText={'help'} />
      </div>
    </div>
  );
  const equipmentsTableData = equipmentsList
    ? equipmentsList.equipments.map((equipment) => ({
        ...equipment,
        utils: (
          <div className="flex w-full items-center gap-[10px]">
            <div>
              <CircleButton
                icon="SettingIcon"
                onClick={() => onUtilClick({ id: equipment.vehicle_equipment_id, action: 'edit' })}
              />
            </div>
            <div>
              <AllowComponentPermission permissionKey={'NoChanges07'} type={'disabled'}>
                <CircleButton
                  icon="TrashIcon"
                  onClick={() =>
                    onUtilClick({ id: equipment.vehicle_equipment_id, action: 'delete' })
                  }
                />
              </AllowComponentPermission>
            </div>
          </div>
        ),
        install_time: moment(equipment.install_time).format('yyyy.DD.MM hh:mm:ss'),
      }))
    : [];
  if (isLoading) return <Loading />;
  return (
    <div>
      <PageHeader icon={'WrenchAndScrewdriver'} label={t('pages.settingsEquipment.title')}>
        <AllowComponentPermission permissionKey={'NoChanges07'} type={'disabled'}>
          <Button
            onClick={() => {
              changeModalState(true);
              changeEditMode(false);
            }}
            icon="CirclePlusIcon"
            label={t('pages.settingsEquipment.button.add_equipment')}
          />
        </AllowComponentPermission>
      </PageHeader>
      <Table
        dataSource={equipmentsTableData}
        columns={titlesData.map((item) => ({
          ...item,
          title: item.title && t(LOCALE_TABLE_TITLE + item.title),
        }))}
      />
      {!!equipmentsTableData.length && (
        <Paginator
          containerClassName={'mt-[20px]'}
          page={equipmentPagination.offset + 1}
          count={equipmentsList?.total || 20}
          limit={equipmentPagination.limit}
          onChange={changeEquipmentPaginationByClick}
        />
      )}
      <AllowComponentPermission permissionKey={'NoChanges07'} type={'disabled'}>
        <Modal
          isAutoClosable={false}
          onSuccess={() => submitRef.current?.click()}
          variant={'mediumW650'}
          show={modalOpenState}
          onClose={changeModalState.bind(null, false)}
          label={modalLabel}
        >
          {modalOpenState && (
            <VehicleEquipmentForm
              editMode={editMode}
              equipmentData={equipmentData}
              vehicleList={vehicleList?.vehicles}
              organizationsList={organizationsList?.organizations}
              handleSubmitForm={handleSubmitForm}
              submitRef={submitRef}
            />
          )}
        </Modal>
      </AllowComponentPermission>
      <Modal
        onClose={changeRemovalModalState.bind(null, false)}
        show={removalModal}
        variant={'removal'}
        label={t('pages.settingsEquipment.table.delete.label')}
        onRemove={() => removeEquipmentHandler()}
      >
        <div>{t('pages.settingsEquipment.table.delete.delete_TC')}</div>
      </Modal>
    </div>
  );
};

export default SettingsVehicleEquipmentPage;
