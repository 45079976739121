import React, { useEffect } from 'react';
import { Button, Input } from '@ui';
import { useForm } from 'react-hook-form';
import { navigationRouteList } from '@app/utils/routes';
import { useForgetPasswordMutation } from '@app/store/api/auth.api';
import { ForgetPasswordRequest } from '@app/store/interfaces/auth.type';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const RemindForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<ForgetPasswordRequest>();
  const [forgetPassword] = useForgetPasswordMutation();
  function onSubmitForm(data: ForgetPasswordRequest) {
    const { email } = data;
    const callback = `${window.location.href}`;
    forgetPassword({ email, callback })
      .unwrap()
      .then((response) => {
        toast.info(`${t('pages.login.remind_form.toast_info')} ${email}`);
        console.log('Response from server:', response);
      });
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) return;
    navigate(`${navigationRouteList.newPassword}?token=${token}`);
  }, [navigate]);
  return (
    <form
      className="flex flex-col items-center p-[50px_57px_40px_58px] bg-white shadow-hard rounded-defaultR"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="w-[294px]">
        <div className="flex w-full justify-center items-center text-black text-[24px] font-[700] mb-[35px] leading-[29px]">
          <h1>Забыли пароль?</h1>
        </div>
        <div className={'text-[13px] font-[500] leading-[130%] text-2color text-center'}>
          Введите адрес электронной почты, который вы использовали для регистрации. Вам будет
          отправлено письмо с инструкциями по сбросу пароля.
        </div>
        <div className="flex w-full flex-col gap-[27px] mt-[30px]">
          <Input label={'E-mail'} {...register('email')} name={'email'} />
        </div>
        <div className="mt-[35px]">
          <Button type={'submit'} label="Войти" />
        </div>
        <div className="flex w-full justify-start mt-[15px]">
          <div>
            <Button
              className="underline underline-offset-[3px] !border-0 !px-0 !font-[500]"
              fill="linked"
              label="Назад"
              link={`${navigationRouteList.login}`}
            />
          </div>
        </div>
        <div className={'text-[13px] font-[500] leading-[130%] text-2color text-center mt-[30px]'}>
          Система мониторинга электрического общественного транспорта СТМ
        </div>
      </div>
    </form>
  );
};

export default RemindForm;
