import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const CableCar: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 23"
      >
        <path
          fill="currentColor"
          d="M.552 18.334a.84.84 0 00.835.835h3.392l-1.652 2.645c-.246.396-.035.862.44.862h.553c.273 0 .43-.097.58-.334l1.969-3.173h6.39l1.968 3.173c.15.237.308.334.58.334h.51c.475 0 .73-.466.466-.888l-1.635-2.62h3.648a.848.848 0 00.843-.834.85.85 0 00-.843-.844h-.229V5.528c0-1.142-.677-1.837-1.916-1.977a25.53 25.53 0 00-1.652-.167v-.967l.193.026c.44.053.818-.351.818-.8 0-.43-.352-.755-.818-.808-1.327-.158-3.173-.237-5-.237-1.811 0-3.675.079-5.02.237-.456.061-.808.378-.808.809 0 .448.378.852.826.8.062-.01.132-.018.202-.027v.967c-.606.044-1.169.096-1.67.167-1.239.14-1.907.835-1.907 1.977V17.49h-.228a.842.842 0 00-.835.844zm9.44-15.117c-1.179 0-2.391.026-3.516.088V2.303a54.643 54.643 0 013.515-.097c1.24 0 2.461.026 3.525.097v1.002a66.183 66.183 0 00-3.525-.088zM2.994 5.889c0-.58.281-.906.87-.985 1.45-.202 3.7-.29 6.126-.29 2.408 0 4.658.088 6.117.29.616.07.87.352.87.985V17.49H2.995V5.89zm5.15 6.073c0 .281.176.457.466.457h2.742c.29 0 .475-.176.475-.457V7.497c0-1.107-.738-1.846-1.837-1.846-1.116 0-1.845.739-1.845 1.846v4.465zm-4.227 0c0 .281.185.457.466.457h2.408c.29 0 .475-.176.475-.457V7.708c0-.94-.721-1.661-1.67-1.661-.958 0-1.679.72-1.679 1.661v4.254zm8.789 0c0 .281.185.457.466.457h2.408c.29 0 .475-.176.475-.457V7.708c0-.94-.73-1.661-1.67-1.661-.958 0-1.679.712-1.679 1.661v4.254zm-2.716 4.561c.809 0 1.45-.615 1.45-1.423 0-.835-.641-1.486-1.45-1.486-.835 0-1.45.642-1.45 1.486 0 .808.615 1.423 1.45 1.423z"
        ></path>
      </svg>
    </div>
  );
};

export default CableCar;
