import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const DownloadCloudIcon: FC<IconType> = (props) => {
  const { size } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 19 17">
      <path
        fill="currentColor"
        d="M11.428 10.956v1.524h3.377c2.167 0 3.52-1.278 3.52-3.055 0-1.49-.834-2.687-2.201-3.227 0-3.117-2.249-5.366-5.154-5.366-1.805 0-3.193.91-4.074 2.174-1.675-.397-3.59.854-3.671 2.789-1.58.294-2.55 1.613-2.55 3.288 0 1.88 1.442 3.397 3.74 3.397h3.082v-1.524H4.414c-1.408 0-2.194-.861-2.194-1.914 0-1.271.854-2.12 2.221-2.12.096 0 .144-.054.137-.156C4.51 4.77 5.932 4.072 7.442 4.51c.09.02.15.007.192-.075.65-1.197 1.66-2.079 3.322-2.079 2.099 0 3.603 1.655 3.705 3.623.02.39-.007.807-.04 1.149-.015.096.027.15.122.164 1.258.219 2.03.916 2.03 2.03 0 .964-.656 1.634-2.016 1.634h-3.33zm-1.962 5.776c.184 0 .355-.061.54-.239l2.283-2.146a.566.566 0 00.191-.438c0-.349-.287-.595-.615-.595a.648.648 0 00-.465.206l-.902.909-.417.512.082-1.011V8.078a.698.698 0 00-.697-.683.699.699 0 00-.704.683v5.852l.089 1.011-.424-.512-.903-.91a.648.648 0 00-.464-.204c-.335 0-.616.245-.616.594 0 .178.062.315.192.438l2.283 2.146c.191.178.355.24.547.24z"
      ></path>
    </svg>
  );
};

export default DownloadCloudIcon;
