import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const SortIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 15 15"
      >
        <path
          fill="currentColor"
          d="M7 14.126c3.862 0 7.062-3.192 7.062-7.062 0-3.862-3.2-7.061-7.069-7.061-3.862 0-7.055 3.2-7.055 7.061 0 3.87 3.2 7.062 7.062 7.062zm0-1.388a5.654 5.654 0 01-5.667-5.674 5.642 5.642 0 015.66-5.667 5.66 5.66 0 015.68 5.667A5.66 5.66 0 017 12.738zm-3.582-6.98h7.17c.343 0 .582-.211.582-.532 0-.322-.24-.54-.581-.54H3.418c-.342 0-.574.218-.574.54 0 .32.232.533.574.533zm.95 2.263h5.278c.334 0 .574-.218.574-.54 0-.32-.24-.54-.574-.54H4.368c-.342 0-.58.22-.58.54 0 .322.238.54.58.54zm.985 2.256h3.301c.342 0 .581-.212.581-.533 0-.321-.239-.54-.58-.54H5.352c-.342 0-.582.219-.582.54 0 .321.24.533.582.533z"
        ></path>
      </svg>
    </div>
  );
};

export default SortIcon;
