import React, { FC } from 'react';

import { IconType } from '../icon.type';

const TrashIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 15 17"
      >
        <path
          fill="currentColor"
          d="M4.124 16.866h6.745c1.18 0 1.926-.689 1.978-1.875l.49-10.474h.755c.41 0 .725-.308.725-.718a.705.705 0 00-.725-.71h-3.194V2.012c0-1.164-.74-1.845-2.014-1.845h-2.79c-1.275 0-2.007.68-2.007 1.845V3.09H.9a.71.71 0 00-.725.71c0 .41.322.718.725.718h.754l.491 10.481c.051 1.18.791 1.868 1.978 1.868zM5.58 2.07c0-.352.242-.571.623-.571h2.57c.389 0 .63.22.63.57V3.09H5.581V2.07zM4.307 15.423c-.403 0-.674-.286-.696-.718L3.127 4.517h8.716l-.469 10.188c-.014.44-.285.718-.695.718H4.307zm1.04-1.128c.33 0 .542-.22.534-.527l-.22-7.552c-.007-.315-.226-.52-.541-.52-.33 0-.542.213-.535.52l.22 7.559c.007.315.227.52.542.52zm2.153 0c.322 0 .542-.213.542-.52V6.216c0-.315-.22-.52-.542-.52-.33 0-.55.205-.55.52v7.559c0 .307.22.52.55.52zm2.146 0c.315 0 .535-.205.542-.52l.22-7.559c.007-.307-.205-.52-.535-.52-.315 0-.535.205-.542.52l-.22 7.552c-.007.314.205.527.535.527z"
        ></path>
      </svg>
    </div>
  );
};

export default TrashIcon;
