import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useGetVehicleQuery } from '@app/store/api/vehicle.api';
import { useVehicleImageQuery } from '@app/store/api/vehicle.api';
import { useGetEquipmentByVehicleQuery } from '@app/store/api/equipment.api';
import { useLazyEquipmentImageQuery } from '@app/store/api/equipment.api';
import { useGetVehicleModelQuery } from '@app/store/api/models.api';

const getFirstThreeChars = (str: string) => str.substr(0, 3);

interface PakItemProps {
  title: any;
  equipmentItem: any;
  model_id: number;
  imageList: any;
}

const VehicleItem = ({ vehicle, vehicleImage }) => {
  const { t } = useTranslation();

  return (
    <div className="min-h-[535px] min-w-[410px] w-full mt-[15px]">
      <div className="min-h-[305px]">
        <div>
          <h1 className="pt-[3px] h-[41px] text-[18px] font-semibold leading-normal">
            {t('pages.vehicleDataPage.title1')}
          </h1>
          <div className="mt-[5px] h-[1px] bg-b_dark"></div>
          <InfoItem
            title={t('pages.vehicleDataPage.idts')}
            value={vehicle?.vehicle_id}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.operator')}
            value={vehicle?.org_name}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.licence_plate')}
            value={vehicle?.licence_plate}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.Model_TS')}
            value={vehicle?.type}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.vehicle_ID_in_operator_database')}
            value={vehicle?.id_in_operator_db}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.ID_in_the_data_transmission_system')}
            value={vehicle?.can_id}
            isFlexWrap={undefined}
          />
          <InfoItem
            title={t('pages.vehicleDataPage.technical_characteristics_of_the_vehicle')}
            value={vehicle?.technical_specifications}
            isFlexWrap={true}
          />
        </div>
        <div className="w-[410px] h-[172px] rounded-[10px] bg-white mt-[37px] ml-[-1px] shadow-medium">
          <img className="mx-[41px] pt-[29px] pb-[28px]" src={vehicleImage.data} alt="Bus" />
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ title, value, isFlexWrap }) => {
  const marginLeftClass = isFlexWrap ? 'ml-0' : 'ml-[22px]';

  return (
    <div className={`flex ${isFlexWrap ? 'flex-wrap' : ''} mt-[13px]`}>
      <h1>{title}</h1>
      <h1 className={`${marginLeftClass} text-0color font-medium`}>{value}</h1>
    </div>
  );
};

const InfoEquipmentItem = ({ title, value }) => (
  <div className="flex mt-[7px] flex-wrap">
    <h1>{title}</h1>
    <h1 className="ml-[10px] text-0color font-medium">{value}</h1>
  </div>
);

const EquipmentItem = ({ equipment, vehicle, imageList }) => {
  const { t } = useTranslation();

  const filteredEquipment = equipment?.equipments?.filter(
    (eq) => eq.vehicle_id === vehicle?.vehicle_id,
  );
  const keys = Object.keys(imageList).map(Number);
  const lastKey = keys.length > 0 ? Math.max(...keys) : undefined;
  const firstKey = Object.keys(imageList).sort()[0];
  const secondKey = Object.keys(imageList).sort()[1];

  return (
    <div className="min-h-[634px] min-w-[606px] w-full rounded-[10px] bg-white ml-[20px] pb-[20px] shadow-medium">
      <h1 className="text-[18px] text-1color font-semibold leading-normal mt-[18px] ml-[22px]">
        {t('pages.vehicleDataPage.energy_storage_system')}
      </h1>
      <div className="mt-[16px] ml-[22px] mr-[22px] h-[1px] max-w-[95%] bg-b_dark "></div>
      <div className="w-[95%] flex">
        <div className="w-[100%] ml-[23px] pt-[3px] ml-[-1px]">
          {filteredEquipment?.some((eq) => eq.serial_number?.startsWith('SNE')) ? (
            <>
              <InfoItem
                title={t('pages.vehicleDataPage.model_name')}
                value={
                  filteredEquipment?.find((eq) => eq.serial_number?.startsWith('SNE'))
                    ?.serial_number
                }
                isFlexWrap={undefined}
              />
              <InfoItem
                title={t('pages.vehicleDataPage.id_can')}
                value={filteredEquipment?.find((eq) => eq.serial_number?.startsWith('SNE'))?.can_id}
                isFlexWrap={undefined}
              />
              <InfoItem
                title={t('pages.vehicleDataPage.description')}
                value={
                  filteredEquipment?.find((eq) => eq.serial_number?.startsWith('SNE'))?.description
                }
                isFlexWrap={undefined}
              />
            </>
          ) : null}
        </div>
        <div className="h-[128px] w-[133px] mt-[21px] ml-[7px] mr-[35px]">
          <img src={lastKey !== undefined ? imageList[lastKey] : null} alt="Equipment" />
        </div>
      </div>

      {filteredEquipment
        ?.filter((eq) => !eq.serial_number?.startsWith('SNE'))
        .map((eq, index) => {
          let imageKey;
          if (index === 0) {
            imageKey = firstKey;
          } else if (index === 1) {
            imageKey = secondKey;
          } else {
            imageKey = eq.vehicle_equipment_id;
          }

          return (
            <PakItem
              key={index}
              title={eq?.serial_number ? getFirstThreeChars(eq.serial_number) : ''}
              imageList={imageList}
              equipmentItem={eq}
              model_id={imageKey}
            />
          );
        })}
    </div>
  );
};

const PakItem: React.FC<PakItemProps> = ({ title, equipmentItem, model_id, imageList }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between h-[185px] max-w-[95%] mt-[15px] ml-[22px] mr-[22px] rounded-[10px] bg-basic_app_bg medium">
      <div>
        <h1 className="font-medium leading-[140%] pt-[15px] ml-[15px] ">{title}</h1>
        <div className="ml-[15px]">
          <InfoEquipmentItem
            title={t('pages.vehicleDataPage.serial_number')}
            value={equipmentItem?.serial_number}
          />
          <InfoEquipmentItem
            title={t('pages.vehicleDataPage.id_in_operator_db')}
            value={equipmentItem?.id_in_operator_db}
          />
          <InfoEquipmentItem
            title={t('pages.vehicleDataPage.id_in_vendor_db')}
            value={equipmentItem?.id_in_vendor_db}
          />
          <InfoEquipmentItem
            title={t('pages.vehicleDataPage.can_id')}
            value={equipmentItem?.can_id}
          />
          <InfoEquipmentItem
            title={t('pages.vehicleDataPage.text_description')}
            value={equipmentItem?.description}
          />
        </div>
      </div>
      <div className="mt-[43px] ml-[18px] mr-[36px] h-[55px] w-[80px] ">
        <img src={imageList[model_id]} alt={title} />
      </div>
    </div>
  );
};

const VehicleDataPage = () => {
  const { vehicleId } = useParams();
  const { data: vehicle } = useGetVehicleQuery({ vehicle_id: Number(vehicleId) });
  const { data: equipmentsList } = useGetEquipmentByVehicleQuery({ vehicle_id: Number(vehicleId) });
  const { data: vehicleModel } = useGetVehicleModelQuery(Number(vehicle?.vehicle_model));
  const [getEquipmentImage] = useLazyEquipmentImageQuery();

  const [imageList, setImageList] = useState<{ [modelId: string]: string }>({});

  useEffect(() => {
    if (vehicleModel?.equipments) {
      const fetchImages = async () => {
        const newImageList: { [modelId: string]: string } = {};
        for (const modelItem of vehicleModel.equipments) {
          const response = await getEquipmentImage({ model_id: modelItem.model });
          if (response.data) {
            newImageList[modelItem.model] = response.data as unknown as string;
          }
        }
        setImageList(newImageList);
      };
      fetchImages();
    }
  }, [vehicleModel, getEquipmentImage]);

  const vehicleImageData = useVehicleImageQuery({ model_id: Number(vehicleModel?.model) });

  return (
    <div className="flex font-sf-pro-display ml-[-13px] text-3color text-[14px] font-normal">
      <VehicleItem vehicle={vehicle} vehicleImage={vehicleImageData} />
      <EquipmentItem equipment={equipmentsList} vehicle={vehicle} imageList={imageList} />
    </div>
  );
};
export default VehicleDataPage;
