import React, { FC } from 'react';
import cn from 'classnames';

const GraphLegend: FC<{ color: string }> = (props) => {
  const { color } = props;
  const legendLineClassName = cn('w-[11px]', 'rounded-full', 'mt-[2px]', 'bg-action', 'h-[11px]');
  return <div className={legendLineClassName} style={{ backgroundColor: color }}></div>;
};

export default GraphLegend;
