import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const PlusIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 15 15">
        <path
          fill="currentColor"
          d="M16.934-1.813c-1.196-1.195-2.883-1.382-4.887-1.382H2.918c-1.969 0-3.656.187-4.852 1.382-1.195 1.196-1.37 2.872-1.37 4.829v9.046c0 2.004.175 3.668 1.37 4.864C-.738 18.12.95 18.309 2.941 18.309h9.106c2.004 0 3.691-.188 4.887-1.383 1.195-1.195 1.37-2.86 1.37-4.863V3.05c0-2.004-.175-3.68-1.37-4.864zm-.516 4.536v9.656c0 1.219-.152 2.46-.867 3.176-.703.703-1.969.867-3.176.867h-9.75c-1.207 0-2.473-.164-3.188-.867-.703-.715-.855-1.957-.855-3.176V2.758c0-1.23.152-2.496.855-3.2.715-.714 1.993-.867 3.223-.867h9.715c1.207 0 2.473.164 3.176.868.715.714.867 1.957.867 3.164zM2.074 7.539c0 .574.41.961 1.008.961h3.445v3.457c0 .586.387.996.961.996.586 0 .996-.398.996-.996V8.5h3.457c.586 0 .996-.387.996-.96 0-.587-.41-.997-.996-.997H8.484V3.098c0-.598-.41-1.008-.996-1.008-.574 0-.96.41-.96 1.008v3.445H3.081c-.61 0-1.008.41-1.008.996z"
        ></path>
      </svg>
    </div>
  );
};

export default PlusIcon;
