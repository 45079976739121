import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const SplitedIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M9.89 14.099h3.325c1.834 0 2.785-.957 2.785-2.8V4.32c0-1.836-.951-2.8-2.785-2.8H9.89v1.673h3.308c.754 0 1.122.392 1.122 1.11v7.003c0 .717-.368 1.118-1.122 1.118H9.89V14.1zM8.004 0a.632.632 0 00-.634.632v13.166a1.201 1.201 0 00-.557 1.007c0 .657.549 1.195 1.2 1.195.66 0 1.2-.538 1.2-1.195 0-.427-.232-.811-.575-1.024V.63A.626.626 0 008.004 0zM6.12 12.425H2.802c-.745 0-1.122-.4-1.122-1.118V4.303c0-.717.377-1.11 1.122-1.11H6.12V1.522H2.785C.951 1.52 0 2.485 0 4.32v6.978c0 1.844.96 2.8 2.785 2.8H6.12v-1.674z"
        ></path>
      </svg>
    </div>
  );
};

export default SplitedIcon;
