import React, { FC, ReactNode } from 'react';
import { useAppSelector } from '@app/store';
import { PermissionsList } from '@appTypes/user.type';

type AllowComponentPermissionProps = {
  permissionKey?: PermissionsList;
  children: ReactNode;
  type?: 'hide' | 'disabled';
};
const AllowComponentPermission: FC<AllowComponentPermissionProps> = (props) => {
  const { permissionKey, type = 'hide', children } = props;
  const userPermissions = useAppSelector((state) => state.userState.user?.permissions);
  if (!permissionKey) return <>{children}</>;
  if (userPermissions && userPermissions.includes(permissionKey)) {
    const ChildrenObj = Object(children);
    return type === 'disabled' ? (
      children ? (
        <ChildrenObj.type {...ChildrenObj.props} {...{ disabled: true }} />
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  }
  return <>{children}</>;
};

export default AllowComponentPermission;
