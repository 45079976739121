import React from 'react';
import { Outlet } from 'react-router-dom';
import LayoutContainerBlock from '@app/pages/layout/blocks/layout-container.block';
import HeaderBlock from '@app/pages/layout/blocks/header.block';
import FooterBlock from '@app/pages/layout/blocks/footer.block';
import MenuBar from '@app/pages/layout/blocks/menu-bar/menu-bar';
import { useAppSelector } from '@app/store';

const Layout = () => {
  const user = useAppSelector((store) => store.userState.user);
  if (!user)
    return (
      <div className="bg-basic_app_bg">
        <Outlet />
      </div>
    );

  return (
    <div className="bg-basic_app_bg  border-l-[3px] border-l-action">
      <HeaderBlock />
      <div className="flex flex-row">
        <MenuBar />
        <div className="flex flex-col flex-1 relative overflow-hidden">
          <LayoutContainerBlock>
            <Outlet />
          </LayoutContainerBlock>
          <FooterBlock />
        </div>
      </div>
    </div>
  );
};

export default Layout;
