import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const XIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 12 12"
      >
        <path
          fill="currentColor"
          d="M.97 11.344a.652.652 0 00.315.176.64.64 0 00.359 0 .698.698 0 00.322-.176l4.336-4.343 4.35 4.343a.666.666 0 00.492.198.653.653 0 00.498-.198.678.678 0 00.205-.498c0-.2-.066-.366-.198-.498l-4.35-4.343 4.35-4.336a.675.675 0 00.198-.498.69.69 0 00-.703-.696.666.666 0 00-.491.198l-4.35 4.343L1.965.673a.698.698 0 00-.322-.176.688.688 0 00-.359-.007.627.627 0 00-.315.183.744.744 0 00-.176.681c.03.122.088.227.176.315l4.336 4.336L.97 10.348a.707.707 0 00-.183.315.774.774 0 000 .366c.03.122.09.227.183.315z"
          opacity="0.5"
        ></path>
      </svg>
    </div>
  );
};

export default XIcon;
