import React, { FC } from 'react';

import { IconType } from '../icon.type';

const CopyIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 19 18">
        <path
          fill="currentColor"
          d="M3.54 14.102h1.312v1.164c0 1.718.921 2.625 2.648 2.625h7.96c1.727 0 2.65-.914 2.65-2.625V7.258c0-1.711-.922-2.625-2.65-2.625h-1.312V3.469c0-1.711-.921-2.617-2.648-2.617H3.54C1.804.852.89 1.758.89 3.469v8.008c0 1.718.915 2.625 2.65 2.625zm.147-1.735c-.687 0-1.054-.351-1.054-1.07v-7.64c0-.72.367-1.071 1.054-1.071h7.665c.68 0 1.054.352 1.054 1.07v.977H7.5c-1.727 0-2.648.914-2.648 2.625v5.11H3.687zm3.961 3.781c-.687 0-1.054-.343-1.054-1.062V7.437c0-.718.367-1.062 1.054-1.062h7.665c.68 0 1.054.344 1.054 1.063v7.648c0 .719-.375 1.062-1.055 1.062H7.649z"
        ></path>
      </svg>
    </div>
  );
};

export default CopyIcon;
