import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const PlayCircleIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 21 20"
      >
        <path
          fill="currentColor"
          d="M10.144 18.665c4.748 0 8.657-3.91 8.657-8.658 0-4.74-3.918-8.657-8.666-8.657-4.74 0-8.65 3.918-8.65 8.657 0 4.748 3.91 8.658 8.659 8.658zm0-1.9a6.735 6.735 0 01-6.749-6.758 6.727 6.727 0 016.74-6.757 6.743 6.743 0 016.765 6.757 6.742 6.742 0 01-6.756 6.757zm-1.57-3.47l4.807-2.815a.54.54 0 000-.93L8.575 6.73c-.374-.216-.855-.034-.855.381v5.803c0 .406.456.614.855.381z"
        ></path>
      </svg>
    </div>
  );
};

export default PlayCircleIcon;
