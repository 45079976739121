import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
const Box: FC<{ children: ReactNode; className?: string }> = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('bg-white', 'shadow-medium', 'rounded-[10px]', className)}>{children}</div>
  );
};

export default Box;
