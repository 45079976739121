import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const ResizeIcon: FC<IconType> = (props) => {
  const { size, className, hintTitle } = props;
  return (
    <div title={hintTitle} className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 14 15">
        <path
          fill="currentColor"
          d="M2.562 14.56h8.87c1.581 0 2.402-.82 2.402-2.38V3.266c0-1.567-.82-2.38-2.403-2.38h-8.87C.98.886.16 1.699.16 3.266v8.914c0 1.567.82 2.38 2.403 2.38zm.087-1.436c-.68 0-1.054-.358-1.054-1.069V3.383c0-.71.373-1.062 1.054-1.062h8.694c.674 0 1.055.352 1.055 1.062v8.672c0 .71-.38 1.07-1.055 1.07H2.65zm8.848-5.412c0-.183-.08-.337-.234-.49L9.227 5.2a.61.61 0 00-.425-.169.55.55 0 00-.557.572c0 .175.073.33.198.432l.461.454.835.674-1.494-.059H5.74l-1.487.059.835-.674.462-.454a.55.55 0 00.198-.432.557.557 0 00-.564-.572c-.162 0-.3.059-.418.169L2.73 7.22c-.154.154-.234.308-.234.49 0 .191.08.33.234.492l2.036 2.028c.117.11.256.162.418.162a.55.55 0 00.564-.564c0-.176-.081-.33-.198-.433l-.462-.454-.827-.673 1.48.058h2.504l1.487-.058-.828.673-.461.454a.575.575 0 00-.198.433c0 .322.234.564.557.564a.604.604 0 00.425-.162l2.036-2.028c.153-.162.234-.3.234-.491z"
        ></path>
      </svg>
    </div>
  );
};

export default ResizeIcon;
