import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const FindWords: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 57 57">
        <circle cx="28.5" cy="28.5" r="28.5" fill="#EEF9FD"></circle>
        <path
          fill="#2987CC"
          d="M33.133 22.828a1.15 1.15 0 01-.773-.333l-1.77-2.06a.939.939 0 01-.069-1.317.849.849 0 011.205 0l2.226 1.774a1.164 1.164 0 01-.773 1.975l-.046-.039z"
        ></path>
        <path
          stroke="#2987CC"
          strokeMiterlimit="10"
          strokeWidth="1.715"
          d="M27.336 20.118a4.336 4.336 0 100-8.672 4.336 4.336 0 000 8.672z"
        ></path>
        <path
          fill="#2987CC"
          fillRule="evenodd"
          d="M18.242 25.987c0-1.125.928-2.054 2.054-2.054a2.057 2.057 0 011.933 1.397h12.55a2.058 2.058 0 011.925-1.37c1.126 0 2.054.928 2.054 2.055 0 .884-.576 1.64-1.369 1.924v12.577a2.06 2.06 0 011.37 1.928 2.065 2.065 0 01-2.055 2.054c-.885 0-1.638-.578-1.926-1.37H22.222a2.062 2.062 0 01-1.926 1.37 2.065 2.065 0 01-2.054-2.054 2.063 2.063 0 011.348-1.922V27.908a2.06 2.06 0 01-1.348-1.92zm2.738 0a.675.675 0 00-.684-.684.675.675 0 00-.685.684.674.674 0 00.685.685.675.675 0 00.684-.685zm16.409.028a.675.675 0 00-.685-.685.675.675 0 00-.684.685.676.676 0 00.684.684.675.675 0 00.685-.684zm-2.61.684H22.213a2.07 2.07 0 01-1.254 1.221v12.588a2.066 2.066 0 011.262 1.251h12.556a2.066 2.066 0 011.242-1.243V27.94a2.076 2.076 0 01-1.242-1.24zM20.98 42.444c0-.386-.3-.685-.684-.685a.674.674 0 00-.685.685.675.675 0 00.685.684.675.675 0 00.684-.684zm16.408 0a.675.675 0 00-.685-.685.675.675 0 00-.684.685.675.675 0 00.684.684.675.675 0 00.685-.684z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#2987CC"
          d="M23.706 29.448v.006l-.01 3.37h1.37l.005-2.004h2.748l-.014 8.193-1.372-.006-.005 1.37 4.124.013.004-1.369-1.383-.005.016-8.196h2.722l-.003 2.003h1.37l.008-3.375h-9.58z"
        ></path>
      </svg>
    </div>
  );
};

export default FindWords;
